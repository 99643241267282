import { Component, OnInit } from '@angular/core';
import { ListingOptions } from 'src/app/components/listing-form/listing-form.component';
import { APIDatasService } from 'src/app/services/apidatas.service';


@Component({
  selector: 'app-abstract-list-view',
  templateUrl: './abstract-list-view.component.html',
  styleUrls: ['./abstract-list-view.component.css']
})
export class AbstractListViewComponent implements OnInit{

  finishedLoading : boolean = false;
  datasToShow : any[] = [];
  listingOptions : ListingOptions;
  datas = [];

  constructor( protected api : APIDatasService) {
    this.listingOptions  = {"table_options" :[], "type_options" : {}};
  }

  ngOnInit() {
    this.fetchDatas();
  }

  fetchedDatas(args?:any){

  }

  fetchDatas(args? : any/*softReload?:boolean, withoutPagination?:boolean*/){
    args = args==undefined? {} : args
    let withoutPagination :boolean = (args.withoutPagination == undefined ? false : args.withoutPagination)

    let softReload :boolean = (args.softReload == undefined ? false : args.softReload)

    this.finishedLoading = false;
    var tmpRes = [];
    if(this.listingOptions.type_options.base_route){
      let route = this.listingOptions.type_options.base_route
      let params : any = {}
      if(this.listingOptions.type_options.param_routes){
        params.params = {...this.listingOptions.type_options.param_routes}

        if(params.params.paginate !=undefined && withoutPagination){
          delete params.params.paginate;
        }

        if(this.listingOptions.type_options.param_routes.paginate && !withoutPagination){
          params.params.page = this.listingOptions.page_options.current_page+1
          params.params.per_page = this.listingOptions.page_options.per_page
        }
      }
    this.api.getDatas(this.listingOptions.type_options.base_route, params).toPromise().then(res => {
        let promises = [];
        let results =  res;
        if(!withoutPagination && this.listingOptions.type_options.param_routes && this.listingOptions.type_options.param_routes.paginate){
          this.listingOptions.page_options.item_count = res.meta.total
          results = res.data;
        }

        for(var i in results){
          var tmp = results[i];
          promises.push(this.createOneDataRecord(i, results));
        }
        // When all promises have finished
        return Promise.all(promises).then((res)=>{
          this.datas = res;
          this.datasToShow = res;
          this.finishedLoading = true;

          this.fetchedDatas({'softReload' : softReload});
        })
      });
    }
  }

  createOneDataRecord(index, array){
    return array[index];
  }

}
