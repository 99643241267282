export interface ButtonAction {
    action: (x: any) => void;
    icon: string;
}


export class CustomAction implements ButtonAction {
    action: (x: any) => void;
    icon: string;
    type: 'single' | 'multiple';
    children?: CustomAction[];

    constructor(elem: any) {
        const action = elem ?? {};

        this.action = action.action ?? (() => { });
        this.icon = action.icon ?? '';
        this.type = action.type ?? 'single';
        this.children = (this.type === 'multiple' ? action.children ?? [] : undefined);
    }

}
