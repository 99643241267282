import { Injectable } from '@angular/core';
import { APIDatasService } from './apidatas.service';
import { map, tap } from 'rxjs/operators';
import { Setting, SettingsInterface } from '../models/settings';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  update(name: any, model: any) {
    return this.http.patch(`${environment.BASE_URL}/settings/${name}`, model)
    .pipe(tap( (res : SettingsInterface) => {
      this.settings = this.settings.filter(s => s.key != res.key)
      this.settings.push(res)


    }));
  }
  settings : SettingsInterface[] = [];
  loaded$ : Subject<any> = new Subject();
  constructor(private http: HttpClient) {
    this.loaded$.next(false)
   }
   getSettings(){
     return this.settings;
   }
   getSetting(name){
     let setting = this.settings.filter( s => s.key == name)
     if(setting.length)
      return setting[0];
    return null;
   }
   loadSettings(){
    return this.http.get(environment.BASE_URL + "/settings")
    .pipe(tap( (res: any[]) => {
      res.map(s => this.settings.push(Setting.build(s)))
      this.loaded$.next(true)
    }))
  }
}
