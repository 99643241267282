<div class="lds-roller" [ngClass]="elem" *ngIf="!this.isring">
    <div ></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>
<div class="lds-ring" [ngClass]="elem" *ngIf="this.isring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>