import { ChangeDetectorRef, Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { AuthService } from './services/auth.service';
import { NavItem } from './models/nav-item';
import { NavService } from './components/main-sidebar/nav.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { RouterModule, Routes } from '@angular/router';
import { NotificationService } from './services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaqueriesService } from './services/mediaqueries.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  events: string[] = [];
  opened: boolean;
  loading : boolean;
  isAuthed: boolean;
  @ViewChild('sidenav') sidenav: ElementRef;

  

  constructor(private router: Router, private authsvc: AuthService, private navService : NavService,
    private notificationService: NotificationService, private snackBar: MatSnackBar, public mq : MediaqueriesService, private maticonsvc : MatIconRegistry) {
    this.loading = true;
    this.notificationService.notification$.subscribe(message => {

    });
  }

  toggleSideNav(){
    if(this.opened)
      this.navService.closeNav();
    else
      this.navService.openNav();
  }

  ngOnInit() {
    this.authsvc.isLoggedIn$.subscribe(res => {
      this.isAuthed = res;
    });
    this.router.events
    .subscribe((event) => {
        if(event instanceof NavigationStart) {
            this.loading = true;
        }
        else if (
            event instanceof NavigationEnd || 
            event instanceof NavigationCancel
            ) {
            this.loading = false;
        }
    });
    if(this.isAuthed && !this.mq.isNotPC())
      this.opened = true;
  }

  ngAfterViewInit() {
    this.navService.sidenav = this.sidenav;
  }

  title = 'app';
}
