import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { Intervention } from 'src/app/models/DB';
import { InterventionsFormComponent } from 'src/app/components/interventions-form/interventions-form.component';

@Component({
  selector: 'app-intervention-edit-view',
  templateUrl: './intervention-edit-view.component.html',
  styleUrls: ['./intervention-edit-view.component.css']
})
export class InterventionEditViewComponent implements OnInit {

  orderNo : string;
  constructor(private route: ActivatedRoute, private api : APIDatasService) {}
  id: string;
  private sub: any;
  inter : Intervention;

  @ViewChild('form', { static: true}) form: InterventionsFormComponent ;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number

      this.api.getDatas("interventions/" + this.id+"?include[]=order").subscribe(
        res => {
          console.log("RECEIVED FROM API : ")
          console.log(res);
          this.inter = new Intervention();
          this.inter.loadFromObject(res);
          this.form.loadIntervention(this.inter)
        },
        err => {
          console.error("Error finding intervention id : "+ this.id);
        }
      );
   });
  }

  onOrderChange($event){
    this.orderNo = $event;
  }

}
