import {HourLogInterface} from './HourLog';
import {InterventionPartInterface} from './part';
import {Remark, RemarkContract} from './remarks';
import * as moment from 'moment';
import {OrderContract} from './order';
import {Report, ReportContract} from './Report';

export enum InterventionStatus {
  finished = 'finished',
  waiting_validation = 'waiting_validation',
  draft = 'draft'
}

export interface DistanceContract {
    'nb_trips': number;
    'single_dist': number;
    'single_traveltime'?: number;
}

export interface SignatureContract {
  'employee': string;
  'client': string;
}

export interface InterventionContract {
    '_id'?: string;
    'employee_id': string;
    'order_id': string;
    'order'?: OrderContract;
    'spare_parts'?: InterventionPartInterface[];
    'ordered_parts'?: InterventionPartInterface[];
    'tasks': InterventionTaskContract[];
    'hour_log': HourLogInterface[];
    'signatures': SignatureContract;
    'distance': DistanceContract;
    'before_images': string[];
    'after_images': string[];
    'date_start'?: string;
    'date_end': any;
    'remarks': RemarkContract;
    auto_eval: string;
    total_hours: number;
    invoice_id: string;
    invoice?: ReportContract;
    status: string;
}
export class Intervention implements InterventionContract {
    _id: string;
    employee_id: string;
    order_id: string;
    order?: OrderContract;
    spare_parts: InterventionPartInterface[];
    ordered_parts: InterventionPartInterface[];
    tasks: InterventionTaskContract[];
    hour_log: HourLogInterface[];
    distance: DistanceContract;
    signatures: SignatureContract;
    before_images: string[];
    after_images: string[];
    date_start: string;
    date_end: any;
    remarks: RemarkContract;
    auto_eval: string;
    total_hours: number;
    invoice_id: string;
    invoice?: ReportContract;
    status: string;

    static build(object) {
      const m = new Intervention();
      m._id            = object._id;
      m.employee_id    = object.employee_id;
      m.order_id       = object.order_id;
      m.order          = object.order;
      m.spare_parts    = object.spare_parts;
      m.ordered_parts  = object.ordered_parts;
      m.status         = object.status;
      m.tasks          = object.tasks;
      m.remarks        = Remark.build(object.remarks);
      m.hour_log       = object.hour_log;
      m.distance       = object.distance;
      m.signatures     = object.signatures;
      m.before_images  = object.before_images;
      m.after_images   = object.after_images;
      m.date_start     = object.date_start;
      m.date_end       = moment(object.date_end);
      m.total_hours    = object.total_hours;
      m.invoice_id     = object.invoice_id;
      m.invoice        = object.invoice ?  Report.build(object.invoice) : Report.buildEmpty();
      m.auto_eval      = object.auto_eval || '';
      return m;
    }

    static buildEmpty() {
      const m = new Intervention();
      m.spare_parts    = [];
      m._id            = '';
      m.before_images         = [];
      m.after_images = [];
      m.distance = {
        nb_trips : 1,
        single_dist : 0,
        single_traveltime : 0
      };
      m.tasks          = [];
      m.hour_log       = [];
      m.ordered_parts  = [];
      m.signatures = {
        employee : '',
        client   : ''
      };
      m.date_start = '';
      m.remarks = {
        cause: {
          selected: [],
          additional: ''
        },
        misc: {
          selected: [],
          additional: ''
        },
        work: {
          selected: [],
          additional: ''
        }
      };
      m.date_end =  moment();
      m.total_hours = 0;
      m.invoice_id = '';
      m.status = 'draft';
      m.invoice = Report.buildEmpty();
      m.auto_eval = '';
      return m;
    }

    isValid(): boolean {

        return  (this.order_id      && this.order_id !== ''   ) &&
                (this.tasks         && this.tasks.length >= (this.status === 'impossible' ? 0 : 1) ) &&
                (this.signatures    && this.signatures.client  && this.signatures.employee &&
                 this.signatures.client !== '' && this.signatures.employee !== '' ) &&
                (this.hour_log      && this.hour_log.length > 0) &&
                (this.remarks && this.remarks.cause && this.remarks.cause.selected && this.remarks.cause.selected.length > 0) &&
                (
                  (this.status === 'impossible' && this.auto_eval && this.auto_eval !== '') ||
                  (this.status === 'draft' || ((this.status === 'waiting_validation' || this.status === 'finished') &&
                  this.auto_eval && this.auto_eval !== ''))
                );

    }
}


export interface InterventionTaskContract {
    'target_id': string[];
    'location_id': string[];
    'type_id': string ;
    'description'?: string;
}
