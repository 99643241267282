<div class="page-header" style="background: linear-gradient(90deg, #8E44AD, #9B59B6);">
    <h1>
        Nouveau Bon de commande
    </h1>
</div>
<order-form>
    <div headerCard>
        <mat-card class="presentation-block" style="background: #8E44AD;">
            <div class="icon">
                <mat-icon>payment</mat-icon>
            </div>
            <div class="content">
                <h4>Ajout de nouvelle commande</h4>
                <p>Cette vue sert à ajouter les confirmations de commande de manière à pouvoir en tirer un formulaire d'intervention</p>
            </div>
        </mat-card>
    </div>
</order-form>