<div class="report">
  <h2 class="report-title">Menuiserie Suarato Sàrl</h2>

  <mat-card class="client-address">
    <ckeditor class="content" [editor]="Editor" [(ngModel)]="model.address"></ckeditor>
  </mat-card>

  <mat-card class="report-infos">
    <ckeditor class="content" [editor]="Editor" [(ngModel)]="model.header"></ckeditor>
  </mat-card>


  <h3>Opérations effectuées</h3>
  <div *ngIf="inter && inter.status == 'impossible'">
    <p>
      Pas d'opérations effectuées car il s'agit d'un constat uniquement.
    </p>
  </div>
  <div [hidden]="inter && inter.status == 'impossible'" class="task-list" cdkDropList (cdkDropListDropped)="dropOp($event)">
    <div class="example-box" *ngFor="let l of model.operations; let i = index" cdkDrag>
      <div>
        <div class="example-handle" cdkDragHandle>
          <mat-icon>more_vert</mat-icon>
        </div>
        <ckeditor class="content" [editor]="Editor" (change)="onOpChange($event, i)" [ngModel]="l"></ckeditor>
      </div>
    </div>
  </div>

  <h3>Remarques</h3>
  <mat-card>
    <remarks-form #remarks (remarksChanged)="onRemarksChange($event)"></remarks-form>
  </mat-card>


  <h3>Pièces commandées / fabriquées</h3>
  <div class="task-list" *ngIf="this.inter" cdkDropList (cdkDropListDropped)="dropOp($event)">
    <div class="mat-table">
      <div class="mat-header-row">
        <div class="mat-header-cell">Pièces</div>
        <div class="mat-header-cell">Quantité</div>
        <div class="mat-header-cell">Prix</div>
        <div class="mat-header-cell">Total</div>
      </div>
      <div class="mat-row example-box parts-line" *ngFor="let part of model.costs" cdkDrag>
        <div class="mat-cell">
          <input class="content" matInput [(ngModel)]="part.description" />
        </div>
        <div class="mat-cell">
          <mat-form-field style="width: 80%"><input type="number" matInput [(ngModel)]="part.quantity" />
          </mat-form-field>
        </div>
        <div class="mat-cell">
          <mat-form-field style="width: 80%"><input type="number" matInput [(ngModel)]="part.price" /></mat-form-field>
        </div>
        <div class="mat-cell"><span>{{part.total.toFixed(2)}}</span></div>
      </div>
    </div>
  </div>
  <div class="total">
    <span class="title" style="font-size: 12px">Total:</span><span>{{getTotalList(model.costs).toFixed(2)}}</span>
  </div>
  <h3>Main d'oeuvre</h3>
  <div class="task-list" *ngIf="this.inter">
    <div class="mat-table">
      <div class="mat-header-row">
        <div class="mat-header-cell">&nbsp;</div>
        <div class="mat-header-cell">Nbr d'heures</div>
        <div class="mat-header-cell">Prix à heure</div>
        <div class="mat-header-cell">Total</div>
      </div>
      <div class="mat-row" *ngFor="let item of model.hourly_cost">
        <div class="mat-cell">
          <input class="content" matInput [(ngModel)]="item.text" />
        </div>
        <div class="mat-cell">
          <mat-form-field style="width: 80%">
            <input matInput type="number" class="content" [ngModel]="item.hours | number"
              (ngModelChange)="item.hours=$event" />
          </mat-form-field>
        </div>
        <div class="mat-cell">
          <mat-form-field style="width: 80%"><input matInput type="number" class="content" [(ngModel)]="item.price" />
          </mat-form-field>
        </div>
        <div class="mat-cell"><span>{{item.total.toFixed(2)}}</span></div>
      </div>
    </div>
  </div>
  <h3>Distance et trajet</h3>
  <div class="task-list" *ngIf="this.inter">
    <div class="mat-table">
      <div class="mat-header-row">
        <div class="mat-header-cell">Nbr de km</div>
        <div class="mat-header-cell">Prix du km</div>
        <div class="mat-header-cell">Total</div>
      </div>
      <div class="mat-row">
        <div class="mat-cell">
          <mat-form-field style="width: 80%"><input matInput type="number" class="content"
              [(ngModel)]="model.distance_cost.distance" /></mat-form-field>
        </div>
        <div class="mat-cell">
          <mat-form-field style="width: 80%"><input matInput type="number" class="content"
              [(ngModel)]="model.distance_cost.price" /></mat-form-field>
        </div>
        <div class="mat-cell"><span>{{(model.distance_cost.distance * model.distance_cost.price).toFixed(2)}}</span>
        </div>
      </div>
      <div class="mat-header-row" *ngIf="this.inter.distance.single_traveltime && this.inter.invoice.distance_cost.time.price && this.inter.invoice.distance_cost.time.value ">
        <div class="mat-header-cell">Temps estimé</div>
        <div class="mat-header-cell">Prix de l'heure</div>
        <div class="mat-header-cell">Total</div>
      </div>
      <div class="mat-row" *ngIf="this.inter.distance.single_traveltime && this.inter.invoice.distance_cost.time.price && this.inter.invoice.distance_cost.time.value">
        <div class="mat-cell">
          <mat-form-field style="width: 80%"><input matInput type="number" class="content"
              [(ngModel)]="model.distance_cost.time.value" /></mat-form-field>
        </div>
        <div class="mat-cell">
          <mat-form-field style="width: 80%"><input matInput type="number" class="content"
              [(ngModel)]="model.distance_cost.time.price" /></mat-form-field>
        </div>
        <div class="mat-cell">
          <span>{{(model.distance_cost.time.value * model.distance_cost.time.price).toFixed(2)}}</span></div>
      </div>
      <div class="mat-header-row">
        <div class="mat-header-cell">Total distances et trajet</div>
        <div class="mat-header-cell"></div>
        <div class="mat-header-cell"></div>
      </div>
      <div class="mat-row">
        <div class="mat-cell"></div>
        <div class="mat-cell"></div>
        <div class="mat-cell"><span>{{model.distance_cost.total.toFixed(2)}}</span></div>
      </div>
    </div>
  </div>
  <div class="administrative_cost" *ngIf="this.inter">
    <h3 style="display:block; padding-top: 20px; margin: auto">Frais Administratif</h3>
    <div class="mat-table">
      <div class="mat-header-row">
        <div class="mat-header-cell"></div>
        <div class="mat-header-cell">Prix</div>
      </div>
      <div class="mat-row">
        <div class="mat-cell" style="width: 60%">
          <mat-form-field style="width: 80%"><input matInput type="text" class="content"
              [(ngModel)]="model.administrative_cost_text" /></mat-form-field>
        </div>
        <div class="mat-cell" style="width: 40%">
          <mat-form-field style="width: 80%">
            <input matInput type="number" class="content" [ngModel]="model.administrative_cost | number"
              (ngModelChange)="model.administrative_cost=$event" />
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>
  <div class="total">
    <span class="title">Total:</span><span>{{getReportTotal().toFixed(2)}}</span>
  </div>
  <div class="controls" *ngIf="showControls">
    <button mat-button (click)="cancel()">
      Annuler
    </button>
    <button mat-button color="primary" [disabled]="saving" (click)="submit()">
      Sauvegarder
    </button>
    <button mat-raised-button color="primary" [disabled]="saving" (click)="download()">
      Télécharger PDF
    </button>
    <button mat-raised-button color="accent" [disabled]="saving" (click)="closeAndDl()">
      Clôturer et télécharger
    </button>
  </div>
</div>
<trs-spinner *ngIf="!this.inter" [elem]="'report'"></trs-spinner>