<div>
    <form>
        <table style="width : 100%;">
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Nom d'utilisateur" required [(ngModel)]="model.username" name="username">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Nom" required [(ngModel)]="model.last_name" name="lastname">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Prénom" required [(ngModel)]="model.first_name" name="firstname">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Adresse e-mail" [(ngModel)]="model.email" name="email"
                            class="fullwidth">
                    </mat-form-field>
                </td>

            </tr>
            <div [formGroup]="formGroup">
                <tr style="width: 100%;display: block;">
                    <td style="width: 100%;display: block;">
                        <mat-form-field style="width: 100%" [color]="passwordComponent.color" class="fullwidth">
                            <input matInput #pwdField [placeholder]="user ? 'Nouveau mot de passe' : 'Mot de passe'" [(ngModel)]="model.password"
                                name="pass" type="password" formControlName="password" (input)="onPasswordInput()">
                            <mat-hint align="end" aria-live="polite">
                                {{pwdField.value.length}} / {{passwordComponent.min}}
                            </mat-hint>
                        </mat-form-field>
                    </td>
                </tr>
                <mat-password-strength [hidden]="!passwordChanged" #passwordComponent [min]="8" [password]="model.password" class="green fullwidth"
                    style="width: 90%; margin-left: auto; margin-right: auto; position: relative; display: block;">
                </mat-password-strength>
                <tr style="width: 100%;display: block;" [hidden]="!passwordChanged">
                    <td style="width: 100%;display: block;">
                        <mat-form-field class="fullwidth">
                            <input matInput placeholder="Confirmation" name="pwd_confirmation" type="password"
                                formControlName="password2" (input)="onPasswordInput()">
                            <mat-error *ngIf="password2.hasError('required')">Merci de confirmer le mot de passe
                            </mat-error>
                            <mat-error *ngIf="password2.invalid && !password2.hasError('required')">Les mots de passes
                                ne sont pas identiques</mat-error>
                        </mat-form-field>
                    </td>
                </tr>
            </div>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Tarif horaire" [(ngModel)]="model.hourly_rate" name="hourly_rate" type="number" min=0
                            class="fullwidth">
                    </mat-form-field>
                </td>

            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <mat-select placeholder="Rôle" required [(ngModel)]="model.role" name="role">
                            <mat-option value="employee">Employé</mat-option>
                            <mat-option value="admin">Administrateur</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </table>


        <div style="text-align : right; width: 95%;">
            <button [disabled]="!isValid()" (click)="createUser()" mat-raised-button color="primary"
                class="trs-button">{{user != null ? "Mettre a jour l'utilisateur" : 'Créer Utilisateur'}}</button>
            <button [routerLink]="['/users']" mat-button color="primary" class="trs-button">Annuler</button>
        </div>
    </form>
</div>
