import { Component, OnInit, ChangeDetectorRef, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Intervention, _intervention } from 'src/app/models/DB';
import { APIDatasService } from 'src/app/services/apidatas.service';
import {downloadUrl} from 'src/app/utils/link'
import { ListingOptions, ConfirmDeleteDialog } from 'src/app/components/listing-form/listing-form.component';
import { AbstractListViewComponent } from '../../abstract-list-view/abstract-list-view.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ReportDialog } from '../report/report.component';
import { Router } from '@angular/router';
import { TableConfigInterface } from './components/base-table/base-table.component';
import { BehaviorSubject, empty } from 'rxjs';
import { Notification, NotificationService } from 'src/app/services/notification.service';
import {shareReplay, tap, switchMap, catchError, map, finalize} from 'rxjs/operators';
import * as moment from 'moment';
import { InterventionContract } from 'src/app/models/Intervention';
import { PaginatedRequest } from 'src/app/utils/http/PaginateRequest';
import { CustomAction } from 'src/app/models/ui';


@Component({
  selector: 'app-intervention-list-view',
  templateUrl: './intervention-list-view.component.html',
  styleUrls: ['./intervention-list-view.component.css']
})
export class InterventionListViewComponent implements OnInit, OnDestroy {

  tblConfig: TableConfigInterface = {
    paginationEnabled: true,
    fields: {
      created_at: 'Crée le',
      code: 'n° CC',
      order_code: 'n° Bon',
      address: 'Adresse',
      client: 'Client'
    },
    controls: [
      new CustomAction({
        icon: 'picture_as_pdf',
        type: 'multiple',
        children : [
          new CustomAction({
            icon: 'all-docs',
            action: (inter: InterventionContract) => this.gotoAllDocs(inter)
          }),
          new CustomAction({
            icon: 'all-ext-docs',
            action: (inter: InterventionContract) => this.gotoAllExtDocs(inter)
          }),
          new CustomAction({
            icon: 'rapport-externe',
            action: (inter: InterventionContract) => this.gotoReport(inter)
          }),
          new CustomAction({
            icon: 'rapport-interne',
            action: (inter: InterventionContract) =>  this.gotoInvoice(inter)
          }),
          new CustomAction({
            icon: 'fact_check',
            action: (inter: InterventionContract) =>  this.gotoReportEdit(inter)
          }),
        ]
      }),
      new CustomAction({
        icon: 'edit',
        action: (inter: InterventionContract) => this.router.navigate(['interventions', 'edit', inter._id])
      }),
      new CustomAction({
        icon: 'delete',
        action: (inter: InterventionContract) => this.openDelete(inter)
      })
    ]
  };
  filters = [
    {
      columnName: 'status',
      name: 'Pas Réalisable',
      className: 'status-impossible',
      filterFunction: (dataForColumn, dataForRow, dataArray) => dataForColumn === 'impossible'
    },
    {
      columnName: 'status',
      name: 'En cours',
      className: 'status-draft',
      filterFunction: (dataForColumn, dataForRow, dataArray) => dataForColumn === 'draft'
    },
    {
      columnName: 'status',
      name: 'À valider',
      className: 'status-waiting_validation',
      filterFunction: (dataForColumn, dataForRow, dataArray) => dataForColumn === 'waiting_validation'
    },
    {
      columnName: 'status',
      name: 'Terminé',
      className: 'status-finished',
      filterFunction: (dataForColumn, dataForRow, dataArray) => dataForColumn === 'finished'
    },
  ];

  data$ = null;
  refresh = new BehaviorSubject(true);

  constructor(private api: APIDatasService, private router: Router, private dialog: MatDialog, private notifs: NotificationService) { }

  ngOnInit() {
    //TODO put the refresh logic in the table maybe?
    this.data$ = this.refresh.asObservable()
      .pipe(switchMap( x => this.getLatestInterventionsPaginated()))
      .pipe(shareReplay(1))
      //.pipe(tap(x => console.log("NEW DATA IN INTERVENTION DATA STREAM", x)));
  }
  openDelete(inter: InterventionContract) {
    const ref = this.dialog.open(ConfirmDeleteDialog, {
      data: {
        typename: 'l\'interventions'
      }
    });
    ref.afterClosed().pipe(switchMap( (result) => {
      if (!result) {
        return empty();
      }
      return this.api.delete(`/interventions`, inter._id)
        .pipe(
          tap( x => this.notifs.notify(new Notification('Intervention supprimé avec succès'))),
          catchError( x => {
            this.notifs.error(new Notification('Impossible de supprimer l\'intervention, veuillez réessayer plus tard'));
            return null;
          }),
          finalize( () => this.refreshTableData())
        );
    })).subscribe();
  }
  refreshTableData() {
    this.refresh.next(true);
    this.router.navigate(['interventions']);
  }
  buildRow(inter) {
    const {address, city, zip} = inter.order.intervention_building;
    return {
      created_at: moment(inter.created_at).format('DD/MM/YYYY'),
      address: `${address}, ${zip}-${city}`,
      status: inter.status,
      code: inter.order.code,
      order_code: inter.order.code_confirmation,
      client: inter.order.client ? inter.order.client.name ? inter.order.client.name : "" : "",
      _id: inter._id
    };
  }

  getLatestInterventionsPaginated(params = null) {
    const req = new PaginatedRequest('interventions', {include: 'order.client'}, this.buildRow);
    return req.fetch(this.api);
  }
  gotoReport(intervention: InterventionContract) {
    downloadUrl(this.api.getUrl(`interventions/${intervention._id}/report`));
  }

  gotoInvoice(intervention: InterventionContract) {
    downloadUrl(this.api.getUrl(`interventions/${intervention._id}/invoice`));
  }

  gotoAllDocs(intervention: InterventionContract) {
    downloadUrl(this.api.getUrl(`interventions/${intervention._id}/all_pdfs`));
  }
  gotoAllExtDocs(intervention: InterventionContract) {
    downloadUrl(this.api.getUrl(`interventions/${intervention._id}/external_pdfs`));
  }

  gotoReportEdit(intervention: InterventionContract) {
    this.router.navigate(['interventions', intervention._id , 'report']);
  }

  ngOnDestroy(): void {
    this.refresh.complete();
  }
}
