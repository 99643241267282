import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { _itemType, _interventionType, _location, _action, _target } from 'src/app/models/DB';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { from } from 'rxjs';
import { flatMap, map, reduce } from 'rxjs/operators';

export interface _interReportTask {
  values : {
    "cat" : string[],
    "loc" : string[],
    "type": string,
    "description": string
  },
  fields : {
    "cats" : _interventionType[],
    "locs" : _location[],
    "types": _itemType[]
  }
}

@Component({
  selector: 'app-inter-form-item',
  templateUrl: './inter-form-item.component.html',
  styleUrls: ['./inter-form-item.component.css'],
  exportAs: 'ngForm'
})

export class InterFormItemComponent implements OnInit {

  _item : _interReportTask = null

  @Input() set item(val : _interReportTask){
    this._item = val;
  }
    get item(){return this._item}
  @Output() itemChange = new EventEmitter<_interReportTask>();

  @Output() itemDeletion = new EventEmitter<any>();

  actions       : _action[];
  targets       : _target[];

  name  : string;
  descr : string;

  constructor(private api : APIDatasService) { }

  ngOnInit() {

    this.name="";
    this.descr="";
    this.getItemTName();
    this.getLocationName();
    this.getInterventionTypes(this.item.values.type);
  }

  onItemTypeSelection(){
    this.getPlaces(this.item.values.type);
    this.item.fields.cats = [];
  }
  onItemLocationSelect(){
    this.getInterventionTypes(this.item.values.type);
  }

  private getItemTName(){
    var tmp = "";
    this.api.getDatas('interventions/item_types/'+this.item.values.type).subscribe(
      it => { tmp = this.name = it.name},
      e  => console.error(e),
      () => {this.name = tmp;}
    );

  }
  private getLocationName(){
    var tmp = "";


    from(this.item.values.loc).pipe(flatMap( (loc : any)=>{
      return this.api.getDatas('interventions/item_types/'+this.item.values.type+'/locations/'+(loc._id ? loc._id : loc))
    }))
    .pipe(reduce( (acc, loc : _location) => acc + (acc.length < 1 ? "" : ", ") + loc.name, "" ))
    .subscribe(
      it => { tmp = it},
      e  => console.error(e),
      () => {this.descr = tmp;}
    );
  }

  private getItemTypes(){
    let tmpData = [];
    this.api.getDatas('interventions/item_types').subscribe(
      it => tmpData = it,
      e  => console.error(e),
      () => {
        this.item.fields.types = tmpData;
      }
    );
  }

  private getPlaces(id_itemtype : string){
    let tmpData = [];
    this.api.getDatas('interventions/item_types/'+id_itemtype+'/locations').subscribe(
      it => tmpData = it,
      e  => console.error(e),
      () => {
        this.item.fields.locs = tmpData;
      }
    );
  }

  private getInterventionTypes(id_itemtype : string){
    let tmpData = [];
    this.api.getDatas('interventions/item_types/'+id_itemtype+'/types').subscribe(
      it => tmpData = it,
      e  => console.error(e),
      () => {
        this.item.fields.cats = tmpData;
      }
    );
  }
  checkUpdate(toggled){
    if(!toggled){
      this.updateItem();
    }
  }
  updateItem(){
    this.getItemTName();
    this.getLocationName();
    this.getInterventionTypes(this.item.values.type);
    if(this._item.values.cat.length > 0 && this._item.values.loc.length > 0)
      this.itemChange.emit(this._item);
  }
  onItemDelete(){
    this.itemDeletion.emit(
      {
        'values' : this.item.values
      }
    );
  }

}
