<div class="page-header" style="background: linear-gradient(90deg, #D35400, #dc7e60);">
    <h1>
        Ajouter un client
    </h1>
</div>
<div class="main clients-theme" fxLayout="row">
    <div fxFlex="40" style="padding: 0px 10px">
        <mat-card class="presentation-block" style="background: #D35400;">
            <div class="icon" >
                <mat-icon>perm_identity</mat-icon>
            </div>
            <div class="content">
                <h4>Ajout de nouveau client</h4>
                <p>Cette vue sert à ajouter un nouveau client à l'application</p>
            </div>
        </mat-card>
    </div>
    <div fxFlex="60" style="padding: 0px 10px">
        <mat-card class="client-address">
            <client-form></client-form>
        </mat-card>
    </div>
</div>


 