import { HourLogInterface } from '../components/interventions-form/hour-log-form/services/hour_log';
import { PartListInterface, InterventionPartInterface } from '../components/interventions-form/parts-form/services/part';
import { RemarkContract } from './remarks';
import * as _ from 'lodash';
import { ClientContract } from './clients';
import { Moment } from 'moment';
import * as moment from 'moment';
import { OrderContract } from './order';
import { ReportContract, Report } from './Report';

export interface _itemType {
    "_id"?           : string,
    "code"?          : string,
    "name"          : string,
    "locations"      : _location[]
    "types"          : _interventionType[]
    "description"?  : string
    "updated_at"?   : string,
    "created_at"?   : string
}

export interface _interventionType {
    "_id"?           : string,
    "code"           : string,
    "name"           : string,
    "item_type_id"?  : string,
    "targets"?       : _target[],
    "updated_at"?    : string,
    "created_at"?    : string
}

export interface _location {
    "_id"?           : string,
    "code"?         : string,
    "name"          : string,
    "item_type_id"? : string,
    "parent_id"?     : string,
    "children"?     : _location[],
    "updated_at"?   : string,
    "created_at"?   : string
}
export class DBLocation implements _location{
    "_id": string;
    "name": string;
    "item_type_id"?: string;
    "parent_id": string;
    "children"?: _location[];
    "description"?   : string
    "updated_at": string;
    "created_at": string;
}

export interface _action {
    "_id"           : string,
    "name"          : string
}

export interface _target {
    "_id"           : string,
    "name"          : string,
    "item_type_id"  : string,
    "type_id"       : string
}

export interface _distance {
    "nb_trips"      : number,
    "single_dist"   : number,
    "single_traveltime"? : number
}

export interface _intervention{
    "_id"?          : string,
    "employee_id"   : string,
    "order_id"      : string,
    "order"?        : OrderContract,
    "spare_parts"?  : InterventionPartInterface[],
    "ordered_parts"?: InterventionPartInterface[],
    "tasks"         : _interventionTask[],
    "hour_log"      : HourLogInterface[],
    "signatures" : {
        "employee" : string,
        "client"   : string
    },
    "distance"     : _distance,
    "details"?       : {
        "inter_causes"      : string[],
        "cause_details"     : string,
        "misc_details"      : string,
        "misc_work_details" : string
        "auto_eval"         : number
    }
    "before_images"       : string[],
    "after_images"       : string[],
    "date_start"?   : string,
    "date_end"      : any,
    "remarks"?: RemarkContract
    auto_eval: string;
    total_hours: number;
    invoice_id: string;
    invoice? : ReportContract;
    status: string
}
export class Intervention implements _intervention{
    _id             : string;
    employee_id     : string
    order_id        : string
    order?          : OrderContract
    spare_parts     : InterventionPartInterface[]
    ordered_parts   : InterventionPartInterface[]
    tasks           : _interventionTask[]
    hour_log        : HourLogInterface[]
    distance        : {
        "nb_trips"      : number,
        "single_dist"   : number,
        "single_traveltime"?: number
    }
    signatures : {
                    "employee" : string
                    "client"   : string
                 }
    before_images : string[]
    after_images : string[]
    date_start : string
    date_end   : any
    remarks: RemarkContract
    auto_eval: string
    total_hours: number;
    invoice_id: string;
    invoice? : ReportContract;
    status: string;
    constructor() {
        this.spare_parts    = [];
        this._id            = ""
        this.before_images         = [];
        this.after_images = [];
        this.distance = {
            nb_trips : 1,
            single_dist : 0,
            single_traveltime : 0
        }
        this.tasks          = [];
        this.hour_log       = [];
        this.ordered_parts  = [];
        this.signatures = {
            "employee" : "",
            "client"   : ""
        };
        this.date_start="";
        this.remarks = {
            cause: {
                selected: [],
                additional: ""
            },
            misc: {
                selected: [],
                additional: ""
            },
            work: {
                selected: [],
                additional: ""
            }
        }
        this.date_end =  moment();
        this.total_hours = 0;
        this.invoice_id = "";
        this.status = "draft"
        this.invoice = Report.buildEmpty();
        this.auto_eval = '';
    }
    static build(d){
      let m = new Intervention;
      m.loadFromObject(d);
      return m
    }
    loadFromObject(object : any){
        if(!object)
          return;
        this._id            = object._id;
        this.employee_id    = object.employee_id
        this.order_id       = object.order_id
        this.order          = object.order
        this.spare_parts    = object.spare_parts
        this.ordered_parts  = object.ordered_parts
        this.status         = object.status;
        this.tasks          = object.tasks.map( t => ({
                                    type_id: t.item_type_id,
                                    location_id: t.locations,
                                    target_id: t.targets,
                                    description: t.description,
                                }))
        this.remarks        = object.remarks;
        this.hour_log       = object.hour_log
        this.distance       = object.distance
        this.signatures     = object.signatures
        this.before_images  = object.before_images
        this.after_images   = object.after_images
        this.date_start     = object.date_start;
        this.date_end       = moment(object.date_end);
        this.total_hours    = object.total_hours;
        this.invoice_id     = object.invoice_id;
        this.invoice        = object.invoice ?  Report.build(object.invoice) : Report.buildEmpty()
        this.auto_eval      = object.auto_eval || '';
    }

    isValid() :boolean {
        return  (this.order_id      && this.order_id != ""   ) &&
                (this.tasks         && this.tasks.length >= (this.status == "impossible" ? 0 : 1 )) &&
                (this.signatures    && this.signatures.client  && this.signatures.employee &&
                 this.signatures.client != "" && this.signatures.employee !="" ) &&
                (this.hour_log      && this.hour_log.length > 0) &&
                (this.remarks && this.remarks.cause && this.remarks.cause.selected && this.remarks.cause.selected.length > 0) &&
                ((this.status == "impossible" && this.auto_eval && this.auto_eval !== '') || (this.status === 'draft' || ((this.status === 'waiting_validation' || this.status === 'finished') && this.auto_eval && this.auto_eval !== '')));

    }
    toData(){
        let data =  {...this, tasks: this.tasks.map( (t) => {
            return {
                item_type_id: t.type_id,
                locations: t.location_id,
                targets: t.target_id,
                description: t.description,
            }
        })}
        return data;
    }

}


export interface _interventionTask{
    "target_id"     : string[],
    "location_id"   : string[],
    "type_id"       : string ,
    "description"?  : string
}
