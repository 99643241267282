import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { SettingsService } from '../services/settings.service';
import { Observable } from 'rxjs';

@Injectable()
export class AppLoadService {
  constructor(private httpClient: HttpClient, public settings: SettingsService) {

   }

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {

            resolve();
        });
  }

  loadSettings() {



    return this.settings.loadSettings().subscribe({
      next: _ => {},
      error: (err) => console.error("Error fetching settings")
    })

  }
}
