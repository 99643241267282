import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrderContract, Order } from 'src/app/models/order';
import { APIDatasService } from 'src/app/services/apidatas.service';
import * as moment from 'moment';

@Component({
  selector: 'app-order-picker',
  templateUrl: './order-picker.component.html',
  styleUrls: ['./order-picker.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class OrderPickerComponent implements OnInit {

  orders: OrderContract[];
  responsiveBp;
  finishedLoading = false;
  ordered = [];

  constructor(private api: APIDatasService) { }
  @Output() selectedOrder = new EventEmitter<any>();

  ngOnInit() {
    this.updateResponsive();
    this.orders = []

    this.api.getDatas('orders').subscribe(
      res => {
        this.orders = res //only show open orders
        return res;
      },
      error => {
        console.error('Error posting inter', error);
        return false;
      },
      () => {
        this.setOrdersGrouped()
        this.finishedLoading = true;
      }
    );
  }
  getOrders() {
    return this.orders.filter((order: OrderContract) => (order.status == "open" && Order.hasValidPlanification(order)));
  }

  sortByPlannedDate(arr: OrderContract[]) {
    return arr.sort((x1, x2) => {
      if (!x1.planned_date && !x2.planned_date) { return 0; }
      if (!x1.planned_date && x2.planned_date) { return 1; }
      if (x1.planned_date && !x2.planned_date) { return -1; }

      if (x1.planned_date < x2.planned_date) { return -1; }
      if (x1.planned_date > x2.planned_date) { return 1; }
      return 0;
    });
  }

  setOrdersGrouped() {

    this.ordered = [];
    this.ordered.push({
      "label": "En priorité (aujourd'hui ou avant)",
      "orders": this.sortByPlannedDate(
        this.getOrders().filter((order: OrderContract) => moment(order.planned_date).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD'))
      )
    })

    this.ordered.push({
      "label": "Demain ou plus tard",
      "orders": this.sortByPlannedDate(
        this.getOrders().filter((order: OrderContract) => moment(order.planned_date).format('YYYY-MM-DD') >= moment(new Date()).add(1, 'days').format('YYYY-MM-DD'))
      )
    })
  }

  getFormattedDate(date) {
    return moment(date).format('DD.MM.YYYY')
  }

  onResize(event) {

    this.updateResponsive();
  }

  updateResponsive() {
    var nbColonnes = 5

    if (window.innerWidth <= 450) {
      nbColonnes = 1;
    } else if (window.innerWidth <= 1069) {
      nbColonnes = 2;
    } else if (window.innerWidth <= 1325) {
      nbColonnes = 3;
    } else if (window.innerWidth <= 1550) {
      nbColonnes = 4;
    }

    this.responsiveBp = nbColonnes;
  }

  onClickOrder(order) {

    var selected = this.orders.filter((v, index_) => {
      return v._id == order
    })[0];
    this.selectedOrder.emit(
      selected
    );
  }

  openVoucher(event, order : OrderContract) {
    event.stopPropagation();
    window.open(this.api.getUrl(`/orders/${order._id}/management_voucher`), '_blank');
  }

}
