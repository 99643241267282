<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{this.name}}
        </mat-panel-title>
        <mat-panel-description>
            Dans : {{this.descr}}
        </mat-panel-description>
    </mat-expansion-panel-header>
    <form #editItemForm="ngForm">
        <div fxLayout="row">
            <mat-form-field>
                <mat-label>Type objet</mat-label>
                <mat-select name="itemType" [(ngModel)]="item.values.type" (selectionChange)="this.onItemTypeSelection()">
                    <mat-option value="{{it._id}}" *ngFor="let it of item.fields.types; let i = index;">
                        {{it.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field *ngIf="this.item.fields.locs.length && item.values.type != '620ba2b91a6cba66a64aa3b5'">
                <mat-label>Lieu</mat-label>
                <!-- <mat-select name="location" [(ngModel)]="item.values.loc" (selectionChange)="this.onItemLocationSelect()">
                    <ng-container *ngFor="let loc of item.fields.locs; let i = index;" >
                        <app-mat-optgroup label="{{loc.name}}" class="mat-optgroup" *ngIf="loc.children && loc.children.length">
                            <mat-option value="{{childLoc._id}}" *ngFor="let childLoc of loc.children; let ci = index;" >
                                {{childLoc.name}}
                            </mat-option>
                        </app-mat-optgroup>
                        <mat-option value="{{loc._id}}" *ngIf="!(loc.children && loc.children.length)">
                                {{loc.name}}
                        </mat-option>
                    </ng-container>
                </mat-select> -->

                <mat-select [multiple]="true" name="location" [(ngModel)]="item.values.loc" *ngIf="item.values" (openedChange)="this.checkUpdate($event)">
                    <ng-container *ngFor="let loc of item.fields.locs; let i = index;" >
                        <app-mat-optgroup label="{{loc.name}}" class="mat-optgroup" *ngIf="loc.children && loc.children.length">
                            <mat-option value="{{childloc._id}}" *ngFor="let childloc of loc.children; let ci = index;" >
                                {{childloc.name}}
                            </mat-option>
                        </app-mat-optgroup>
                        <mat-option value="{{loc._id}}" *ngIf="!(loc.children && loc.children.length)">
                                {{loc.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field *ngIf="this.item.fields.cats.length && item.values.type != '620ba2b91a6cba66a64aa3b5'"  >
                <mat-label>Type d'intervention</mat-label>
                <mat-select multiple name="category" [(ngModel)]="item.values.cat" (openedChange)="this.checkUpdate($event)">
                    <ng-container *ngFor="let cat of item.fields.cats; let i = index;" >
                        <app-mat-optgroup label="{{cat.name}}" class="mat-optgroup" *ngIf="cat.targets && cat.targets.length">
                            <mat-option value="{{childCat._id}}" *ngFor="let childCat of cat.targets; let ci = index;" >
                                {{childCat.name}}
                            </mat-option>
                        </app-mat-optgroup>
                        <mat-option value="{{cat._id}}" *ngIf="!(cat.targets && cat.targets.length)">
                                {{cat.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" *ngIf="item.values.type == '620ba2b91a6cba66a64aa3b5'">
            <div fxFlex="100" *ngIf="item.values.type == '620ba2b91a6cba66a64aa3b5'">
                <mat-form-field fxFlex="100" *ngIf="item.values.type == '620ba2b91a6cba66a64aa3b5'" (input)="updateItem()" class="fullwidth" appearance="fill">
                    <mat-label>Explication</mat-label>
                    <textarea matInput [(ngModel)]="item.values.description" name="description" placeholder="Description de l'intervention..."></textarea>
                </mat-form-field>
            </div>
        </div>
        <button mat-icon-button	color="primary" (click)="this.onItemDelete()" >
            <mat-icon>clear</mat-icon>
        </button>
        <!--<button mat-icon-button	color="primary" (click)="this.updateItem()" > Mettre à jour</button> -->
    </form>
</mat-expansion-panel>

