import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, tap, scan, distinctUntilChanged } from 'rxjs/operators';

export interface TableFilter {
  name: string;
  columnName: string;
  className?: string;
  filterFunction: (dataForColumn, dataForRow, dataArray) => boolean;
}

@Component({
  selector: 'filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.css']
})
export class FilterContainerComponent implements OnInit, OnDestroy {
  @Input() dataStream: Observable<any> = null;
  @Input() filters: Array<TableFilter> = [];
  @Output() filteredDataChange = new EventEmitter();

  activeFilters: Array<TableFilter> = [];
  filters$: Subject<Array<TableFilter>> = new BehaviorSubject([]);

  dataStream$: Subscription = null;
  data$: Observable<any> = null;
  dataRequest: Subscription = null;
  constructor() { }

  ngOnInit() {
    this.data$ = this.getFilteredData();
    this.dataRequest = this.data$.subscribe();
  }
  ngOnDestroy() {
    if (this.dataRequest !== null) {
      this.dataRequest.unsubscribe();
    }
  }
  filterFn(row, data, filters) {
    if (filters.length <= 0) {
      return true;
    }
    return filters.some( f => f.filterFunction(row[f.columnName], row, data));
  }
  getFilteredData() {
    const ds = this.dataStream; //.pipe(scan( (acc, v) => acc.concat(v), [] ), distinctUntilChanged());

    return combineLatest(ds, this.filters$).pipe(
      map(([ data, filters]) => data.filter( row => this.filterFn(row, data, filters))),
      //tap( x => console.log("Filtered table data", x)),
      tap( filteredData => this.filteredDataChange.emit(filteredData) )
    );
  }
  isFilterActive(filter: TableFilter): boolean {
    return this.activeFilters.filter( f => f.name === filter.name).length === 1;
  }

  toggleFilter(filter: TableFilter) {
    if (this.isFilterActive(filter)) {
      this.activeFilters = this.activeFilters.filter(f => f.name !== filter.name);
    } else {
      this.activeFilters.push(filter);
    }
    this.filters$.next(this.activeFilters);
  }

  getClassNameForFilter(filter: TableFilter) {
    return `${ this.isFilterActive(filter) ? 'mat-stroked-button' : 'mat-raised-button'} ${filter.className ? filter.className : ''}`;
  }
}
