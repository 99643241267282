<div class="page-header" style="background: linear-gradient(90deg, #8E44AD, #9B59B6);">
    <h1>
        Modification de Bon de commande
    </h1>
</div>
<ng-container *ngIf="this.order && this.order!=undefined else spinner">
    <order-form [(order)]="this.order">
        <div headerCard>
            <mat-card class="presentation-block" style="background: #8E44AD;">
                <div class="icon">
                    <mat-icon>payment</mat-icon>
                </div>
                <div class="content">
                    <h4>Modifier la commande {{order.code}}</h4>
                    <p>Cette vue sert à modifier la confirmation de commande désignée</p>
                </div>
            </mat-card>
        </div>
    </order-form>
</ng-container>
<ng-template #spinner>
    <div class="main orders-theme" fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="100" *ngIf="!this.order || this.order==undefined" style="text-align: center">
            <trs-spinner [elem]="'order'"></trs-spinner>
        </div>
    </div>
</ng-template>


