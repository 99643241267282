import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { MatDialog } from '@angular/material/dialog';
import { NewPartModalComponent } from '../new-part-modal/new-part-modal.component';
import { PartDataService } from '../services/part_data.service';
import { PartListItem } from '../services/part';
@Component({
  selector: 'parts-table',
  templateUrl: './parts-table.component.html',
  styleUrls: ['./parts-table.component.css']
})
export class PartsTableComponent implements OnInit {

  @Input() service = new PartDataService();
  @Input() parts : any;
  @Output() partsChange = new EventEmitter<any>();

  dataSource = null;
  quantities = []; //array to keep track of updating quantities in table
  columnsToDisplay = ['part', 'quantity','type', 'controls'];
  model = PartListItem.build({part:'',quantity:1})
  itemTypes = [];

  constructor(private api: APIDatasService, private dialog:MatDialog) {

  }

  ngOnInit() {
    this.dataSource = this.service.list$;
    
    let tmpData = [];
    this.api.getDatas('interventions/item_types').subscribe(
      it => tmpData = it,
      e  => console.error(e),
      () => {
        this.itemTypes = tmpData;
      }
    );
  }

  /*
  * Use this accessor to actually get the data
  */
  get form(){
    return this.service.list
  }

  getPart(partId){
    return this.parts[partId] || {type:"",images:[]};
  }
  getParts(){
    let sel = Object.keys(this.parts).map(r => this.parts[r]);
    // Filter
    if (this.model.category != '') {
      sel = sel.filter(part => part.hasOwnProperty('categories') 
          && part.categories.includes(this.model.category));
      console.log('filtered parts:', sel);
    }
    return sel;
  }
  insertPart($event){
    this.service.add(this.model)
    this.model = PartListItem.build({part:'',quantity:1});
    this.partsChange.emit();
  }

  updateQuantity(index, newQuantity){
    this.service.update(index, 'quantity', newQuantity)
    this.partsChange.emit()
  }
  removePart(index){
    this.service.delete(index)
    this.partsChange.emit()
  }

}
