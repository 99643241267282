<label class="mat-optgroup-label" [id]="_labelId" (click)="onItemSelected()" >
    <span>
        <span></span>
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
            expand_more 
        </mat-icon>
    </span>
    {{ label }}
    <ng-content></ng-content>
</label>
<ng-content *ngIf="expanded" select="mat-option, ng-container" [ngClass]="{'expanded': expanded}"></ng-content>