import { Component, OnInit, AfterContentInit, AfterViewInit, ViewChild, ContentChild, ElementRef } from '@angular/core';
import { Intervention } from 'src/app/models/DB';
import { InterventionsFormComponent } from 'src/app/components/interventions-form/interventions-form.component';

@Component({
  selector: 'app-intervention-add-view',
  templateUrl: './intervention-add-view.component.html',
  styleUrls: ['./intervention-add-view.component.css']
})
export class InterventionAddViewComponent implements OnInit, AfterViewInit {

  tmp : Intervention;
  orderNo : string;

  @ViewChild('form', { static: true}) form: InterventionsFormComponent ;
  constructor() {
    this.tmp = new Intervention();
  }

  ngOnInit(){ }
  ngAfterViewInit(){

    this.form.resetForm();
  }

  onOrderChange($event){
    this.orderNo = $event;
  }

}
