import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import {Location} from 'src/app/models/Location'
import { FormArray, FormControl, Validators, FormGroup } from '@angular/forms';
import { APIDatasService } from 'src/app/services/apidatas.service';

@Component({
  selector: 'location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.css'],
  exportAs: 'ngForm'
})
export class LocationFormComponent implements OnInit, AfterViewInit {

  private _locations : Location[];

  tEditings : boolean[] = [];
  
  @Output() locationsChange = new EventEmitter<Location[]>();
  isExpanded : boolean;
  expandedID : number;
  controls: FormArray;
  expandAddForm : boolean = false;


  @Input()
    get locations(){
      return this._locations;
    }

    set locations(t : Location[]){
      this._locations = t;
      this.locationsChange.emit(this._locations);
      this.updateControls();
    }

  constructor(private api : APIDatasService) {
    this.expandedID = 0;
   }

  ngOnInit() {
    if(!this._locations)
      this.locations = [];
  }

  ngAfterViewInit(){
    this.updateControls()
  }


  expandAction(index){
    if(this.expandedID != index){
      this.expandedID = index;
      this.isExpanded = true;
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  addLoc(pseudoLoc){
    var tmp = new Location();
    tmp.name = pseudoLoc.name;
    tmp.code = pseudoLoc.code;
    tmp.description = pseudoLoc.description;
    tmp.children = [];
    this.locations.push(tmp);
    this.updateControls();
  }

  removeLoc(i){
      if(this.locations[i] && this.locations[i]._id && this.locations[i]._id!=""){
        this.api.delete('interventions/locations', this.locations[i]._id).subscribe(
          res => {
            this.locations = new Array<Location>().concat(this.locations.filter((v, index) => {
              return index != i;
            }));
          },
          error =>{
            console.error("Error deleting locations"+this.locations[i].code, error);
          }
        );
      } else {
        this.locations = new Array<Location>().concat(this.locations.filter((v, index) => {
          return index != i;
        }));
      }



  }

  getControl(index, fieldName) {
    const a  = this.controls.at(index).get(fieldName) as FormControl;
    return this.controls.at(index).get(fieldName) as FormControl;
  }

  updateControls(){
    const toGroups = this.locations.map(entity => {
      return new FormGroup({
        code:  new FormControl(entity.code, Validators.required),
        name: new FormControl(entity.name, Validators.required),
        description: new FormControl(entity.description)
      },{updateOn: "blur"});
    });

    this.controls = new FormArray(toGroups);
  }

  checkControls(index) : boolean {
    const controls = [this.getControl(index, "code"),this.getControl(index, "name"),this.getControl(index, "description")];
    return (controls[0].valid && controls[1].valid && controls[2].valid)
  }

  updateLoc(index) {
    const controls = [this.getControl(index, "code"),this.getControl(index, "name"),this.getControl(index, "description")];
    if (controls[0].valid && controls[1].valid && controls[2].valid) {
      this.locations[index]['code'] = controls[0].value;
      this.locations[index]['name'] = controls[1].value;
      this.locations[index]['description'] = controls[2].value;
      this.locations = new Array<Location>().concat(this.locations);
    }
  }


}
