<user-guard [is]="item.access">
  <a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
  [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
  class="menu-list-item">
 <mat-icon class="routeIcon">{{item.iconName}}</mat-icon>
 <span style="padding-left : 12px;">{{item.displayName}}</span>
 <span fxFlex *ngIf="item.children && item.children.length">
   <span fxFlex></span>
   <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
     expand_more 
   </mat-icon>
 </span>
</a>
<div #children *ngIf="expanded">
 <app-sidebar-list-item  *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
 </app-sidebar-list-item >
</div>
</user-guard>
