import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainViewComponent } from './views/main-view/main-view.component';
import { AuthViewComponent } from './views/auth-view/auth-view.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ClientListViewComponent } from './views/clients/client-list-view/client-list-view.component';
import { ClientAddViewComponent } from './views/clients/client-add-view/client-add-view.component';
import { ClientEditViewComponent } from './views/clients/client-edit-view/client-edit-view.component';
import { UserAddViewComponent } from './views/users/user-add-view/user-add-view.component';
import { UserListViewComponent } from './views/users/user-list-view/user-list-view.component';
import { UserEditViewComponent } from './views/users/user-edit-view/user-edit-view.component';
import { OrderListViewComponent } from './views/orders/order-list-view/order-list-view.component';
import { OrderAddViewComponent } from './views/orders/order-add-view/order-add-view.component';
import { OrderEditViewComponent } from './views/orders/order-edit-view/order-edit-view.component';
import { InterventionListViewComponent } from './views/interventions/intervention-list-view/intervention-list-view.component';
import { InterventionEditViewComponent } from './views/interventions/intervention-edit-view/intervention-edit-view.component';
import { InterventionAddViewComponent } from './views/interventions/intervention-add-view/intervention-add-view.component';
import { ItemListViewComponent } from './views/items/item-list-view/item-list-view.component';
import { ItemAddViewComponent } from './views/items/item-add-view/item-add-view.component';
import { ItemEditViewComponent } from './views/items/item-edit-view/item-edit-view.component';
import { ReportComponent } from './views/interventions/report/report.component';
import { SettingsListViewComponent } from './views/settings/settings-list-view/settings-list-view.component';
import { PartsViewComponent } from './views/parts-view/parts-view.component';
import {ExitComponent} from './views/exit/exit.component';


const routes: Routes = [
  {path: 'settings', component: SettingsListViewComponent, canActivate: [AuthGuardService]},
  {path: 'interventions/:id/report', component: ReportComponent, canActivate: [AuthGuardService]},
  {path: 'users', component: UserListViewComponent, canActivate: [AuthGuardService]},
  {path: 'users/new', component: UserAddViewComponent, canActivate: [AuthGuardService]},
  {path: 'users/edit/:id', component: UserEditViewComponent, canActivate: [AuthGuardService]},
  {path: 'clients', component: ClientListViewComponent, canActivate: [AuthGuardService]},
  {path: 'clients/new', component: ClientAddViewComponent, canActivate: [AuthGuardService]},
  {path: 'clients/edit/:id', component: ClientEditViewComponent, canActivate: [AuthGuardService]},
  {path: 'orders', component: OrderListViewComponent, canActivate: [AuthGuardService]},
  {path: 'orders/new', component: OrderAddViewComponent, canActivate: [AuthGuardService]},
  {path: 'orders/edit/:id', component: OrderEditViewComponent, canActivate: [AuthGuardService]},
  {path: 'interventions', component: InterventionListViewComponent, canActivate: [AuthGuardService]},
  {path: 'interventions/new', component: InterventionAddViewComponent, canActivate: [AuthGuardService]},
  {path: 'interventions/edit/:id', component: InterventionEditViewComponent, canActivate: [AuthGuardService]},
  {path: 'interventions/item_types', component: ItemListViewComponent, canActivate: [AuthGuardService]},
  {path: 'interventions/item_types/new', component: ItemAddViewComponent, canActivate: [AuthGuardService]},
  {path: 'interventions/item_types/edit/:id', component: ItemEditViewComponent, canActivate: [AuthGuardService]},
  {path: 'interventions/parts', component: PartsViewComponent, canActivate: [AuthGuardService]},
  {path: '', component: MainViewComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  {path: 'login', component: AuthViewComponent},
  {path: 'exit', component: ExitComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
