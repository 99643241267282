export interface RemarkContract {
    cause: {
        selected: [string?];
        additional: string;
    };
    misc: {
        selected: [string?];
        additional: string;
    };
    work: {
        selected: [string?];
        additional: string;
    };
}

export class Remark implements RemarkContract {
    cause: { selected: [string?]; additional: string };
    misc: { selected: [string?]; additional: string };
    work: { selected: [string?]; additional: string };

    static build(d): RemarkContract {
        const m = new Remark();
        m.misc = d.misc;
        m.cause = d.cause;
        m.work = d.work;
        return m;
    }

    static buildEmpty(): RemarkContract {
        const m = new Remark();
        m.misc = m.cause = m.work = {
            selected : [],
            additional : ''
        };
        return m;
    }
}
