import { style } from '@angular/animations';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ButtonAction } from 'src/app/models/ui';

@Component({
  selector: 'stacked-buttons',
  templateUrl: './stacked-buttons.component.html',
  styleUrls: ['./stacked-buttons.component.scss']
})
export class StackedButtonsComponent {

  expanded : boolean = false;

  @Input() actions : ButtonAction[] = [];
  @Input() top : boolean = true;
  @Input() offset = 0;
  @Input() mainButtonHeight = 40;
  @Input() icon = "add"
  @Input() actionParameter : any = {}

  @ViewChild('floating', { static: true }) floating : ElementRef
  @ViewChild('container', { static: true }) container : ElementRef;


  constructor() { }


  onBlur(){
    setTimeout(() =>{
      this.expanded = false;
    }, 200)
  
  }

  toggleExpansion(){
    this.expanded = !this.expanded;
  }

  getTransitionStyle(index = 1){

    if(this.top){
      if(this.expanded){
        return {
          'transform': 'translate(0, '+ 0 +')',
        }
      } else {
        return {
          'transform': 'translate(0, -webkit-calc('+ ( (this.actions.length - index) * 100) +'% - '+this.offset+'px))',
        }
      }
    } else {
      index +=1;
      if(this.expanded){
        return {
          'transform': 'translate(0, '+ 0 +')',
        }
      } else {
        return {
          'transform': 'translate(0, -webkit-calc(-'+ (index * 100) +'% - '+this.offset+'px))',
        }
      }
    }
  }

  getFloatingHeightStyle(){

    return {
      ...(this.top ? {
            'padding-bottom' : this.mainButtonHeight-1+'px'
          } : {
            'padding-top' : this.mainButtonHeight+'px'
          }),
      'left' : '-webkit-calc(50% - '+(this.floating ? this.floating.nativeElement.offsetWidth : 0)/2+'px)',
      'max-height' : (this.expanded ? (this.floating ? this.floating.nativeElement.scrollHeight : 0)+'px' : 0)
    }
  }

  getCssClasses(){
    return {
      'top'     : this.top,
      'bot'     : !this.top,
      'opened'  : this.expanded 
    }
  }

}
