import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Observable, Subject, BehaviorSubject, isObservable, Subscription} from 'rxjs/index';
import {DataSource} from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { mergeMap, share, startWith, filter } from 'rxjs/operators';
import { isArray } from 'util';
import { CustomAction } from 'src/app/models/ui';

/*
Example of text filter with http call
const myFilteredElements = {
  this.data$ = http.get<State[]>('http://localhost:8000/states');
  this.filter = new FormControl('');
  this.filter$ = this.filter.valueChanges.pipe(startWith(''));
  this.filtereddata$ = combineLatest(this.data$, this.filter$).pipe(
    map(([data, filterString]) => data.filter(MY_FILTER_FUNCTION))
}

and with pagination:
(paginationParams) => {
  return myFilteredElements(paginationParams)
}
);
*/

export interface TableConfigInterface {
  paginationEnabled: boolean;
  fields: {[fieldKey: string]: string};
  controls: CustomAction[];
}

enum RequestStatus {
  SENDING = 'sending',
  IDLE = 'idle',
  ERROR = 'error',
  FINISHED = 'finished'
}

interface PaginationParams {
  current_page: number;
  per_page: number;
}

type cbObservable = (params: PaginationParams) => Observable<any>;

/**
 * Complete mat table compatible table generator
 * Comes with a mat paginator
 */
@Component({
  selector: 'base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.css']
})
export class BaseTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() config: TableConfigInterface = {
    paginationEnabled: false,
    fields: {},
    controls: []
  };
  // either emit an array of stuff, or if nothing is porvided (behavious subject used or whatever) you can emit null
  @Input() dataStream: Observable<Array<any> | null> /*| cbObservable*/ = null;
  @Input() name = '';
  @Input() label = '';

  /**
   * Toggles data pushing or just reassigning from dataStream
   * If the dataStream gives data in multiple waves, you might want to enable this
   * If not, and you only have a datastream that gives out data once and for all (or maybe from cache) the disable this
   *
   * This is usefull if you are using a filtered data stream, that changes everytime the data changes
   * @var pushData Boolean
   */
  @Input() pushData = false;

  dataStream$: Subscription = null; // TOD should' be any but try and find a way to make this better
  requestStatus: RequestStatus = RequestStatus.IDLE;
  dataSource = new MatTableDataSource<any>();

  page$: Subject<PaginationParams> = new BehaviorSubject({
    current_page: 0,
    per_page: 10,
  }); // observable for page change

  /**
   * Options for mat paginator
   */
  totalAmountOfData = 0;
  pageSizeOptions = [10, 20, 50, 100];

  dataStreamObserver = {
    next: (data) => {


      if (data === null) {
        return;
      }
      this.setRequestStatus(RequestStatus.FINISHED);

      this.assignPaginator();
      this.addDataToDatasource(data);

    },
    error: (err) => {
      this.dataSource.paginator = null;
      console.error(err);
      this.setRequestStatus(RequestStatus.ERROR);
    },
    complete: () => {

    }
  };

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;

  }

  onPageChange(page: PageEvent) {
    this.page$.next({
      current_page: page.pageIndex,
      per_page: page.pageSize,
    });
  }

  shouldDisplayErrorMessage() {
    return this.requestStatus === RequestStatus.ERROR;
  }
  shouldDisplayLoader() {
    return this.requestStatus === RequestStatus.SENDING;
  }
  shouldDisplayTable() {
    return this.dataStream$ !== null && this.requestStatus !== RequestStatus.ERROR;
  }
  shouldDisplayPaginator() {
    return this.dataSource.data.length > 0 && this.hasPagination();
  }

  getColumnNames() {
    return this.getFields().map( x => x.name).concat(['actions']);
  }

  getFields(): {name: string, label: string}[] {
    const { fields } = this.config;
    return Object.keys(fields).map( f => ({name: f, label: fields[f]}));
  }

  hasPagination() {
    return typeof this.config.paginationEnabled !== 'undefined' ? this.config.paginationEnabled : false;
  }

  assignPaginator() {
    if (this.shouldDisplayPaginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  addDataToDatasource(data) {
    // when assigning data, if the data comes from a paginated request
    // it will come in multiple waves
    // so to display data to the user while continuing to load data
    // we push data, instead of just assigning it
    // pagination is a weird thing

    if (this.pushData) {
      this.dataSource.data = this.dataSource.data.concat( data.filter(Boolean) );
    } else {
      this.dataSource.data = data.filter(Boolean);
    }
    this.totalAmountOfData = this.dataSource.data.length;

    this.cd.detectChanges();
  }

  setRequestStatus(s) {
    this.requestStatus = s;
  }

  /*buildRemoteDataSource(){
    this.page$.pipe(
      mergeMap( (params) => this.dataStream(params)),
      share()
    )
  }*/

  handleDataStreamChange(ds: Observable<any> ) {

    if (ds === null) {
      return;
    }

    this.dataStream = ds;
    if (this.dataStream$ !== null) {
      this.dataStream$.unsubscribe();
    }
    if (this.dataStream != null) {
      this.setRequestStatus(RequestStatus.SENDING);
      this.dataStream$ = this.dataStream
                            .pipe(filter(Boolean))
                            .subscribe(this.dataStreamObserver);
    }

  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.dataStream$ !== null) {
      this.dataStream$.unsubscribe();
    }
    this.page$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const change = changes[propName];
        switch (propName) {
          case 'dataStream': {
            this.handleDataStreamChange(change.currentValue);
            break;
          }
          case 'config': {
            this.config = change.currentValue;
            break;
          }
          case 'name': {
            this.name = change.currentValue;
            break;
          }
          case 'label': {
            this.label = change.currentValue;
            break;
          }
          case 'pushData': {
            this.pushData = change.currentValue;
            break;
          }
        }
      }
    }
  }

  tableIsEmpty() {
    return this.dataSource.data.length < 1;
  }
}
