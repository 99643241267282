import { Component, OnInit, Input } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { Subject, combineLatest, BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, finalize } from 'rxjs/operators';

export interface TableFilter {
  name: string
  columnName: string,
  filterFunction: (dataForColumn, dataForRow, dataArray) => boolean
}

/**
 * Table capable of creating a filter box and enabling user to activate them of not
 */
@Component({
  selector: 'filtered-table',
  templateUrl: './filtered-table.component.html',
  styleUrls: ['./filtered-table.component.css']
})
export class FilteredTableComponent extends BaseTableComponent implements OnInit {
  @Input() filters: Array<TableFilter> = []

  data$: Subject<Array<any>> = new BehaviorSubject(null);
  public shouldPushData = true;

  ngOnInit() {
    super.ngOnInit()
    this.config = {
      ...this.config,
      paginationEnabled: true
    }
  }

  getDataStream() {
    return this.dataStream.pipe(finalize( () => {
      this.shouldPushData = false;
    }));
  }

  filteredDataChanged($event){
    this.data$.next($event)
  }


}
