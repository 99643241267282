import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  public notification$: Subject<string> = new Subject();
  constructor(private router: Router, private toastr: ToastrService) { }
  notify(notif : Notification){
    this.toastr.success(notif.message, notif.title);
  }
  notifyandredirect(notif : Notification, route : string){
    //this.notification$.next(message);
    this.notify(notif);
    this.router.navigate([route]);
  }

  error(notif : Notification){
    this.toastr.error(notif.title, notif.message, {
      timeOut : 10000
    });
  }
}


export class Notification {
  title : string
  message : string

  constructor(t : string, m? : string){
    this.title = t;
    this.message = m || ""
  }
}
