import { Component, OnInit } from '@angular/core';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { from } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { SettingsInterface } from 'src/app/models/settings';
import { SettingsService } from 'src/app/services/settings.service';
import * as BalloonBlockEditor from '@ckeditor/ckeditor5-build-balloon-block';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-settings-list-view',
  templateUrl: './settings-list-view.component.html',
  styleUrls: ['./settings-list-view.component.css']
})
export class SettingsListViewComponent implements OnInit {
  finishedLoading = false;
  public Editor = BalloonBlockEditor;

  settings = [
    {key: 'address', name: 'Addresse', value: 'Chemin du Couchant 31, 1007 Lausanne'},
    {key: 'global-hourly-rate', name: 'Tarif horaire', value: 100}
  ]
  settingsMap = {};
  constructor(protected api : APIDatasService, private settingsService :SettingsService, private notifs : NotificationService) {

   }

   ngOnInit(){
      this.loadSettings()
   }

   loadSettings(){
      this.settings = this.settingsService.getSettings()
      this.settings.forEach( (s) => this.settingsMap[s.key] = s )
      this.finishedLoading = true
   }

   updateSetting(model){
      return this.settingsService.update(model.key, model)
   }

   flashMessage(){
      this.notifs.notification$.next("Les paramètres ont été mis à jour!");
   }
   shouldDisplaySetting(setting){
     return setting.type != 'other'
   }
   save(){

      from(this.settings).pipe(flatMap((m) => this.updateSetting(m))).subscribe({
        next: (d) => {},
        error: (err) => console.error(err),
        complete: () => this.flashMessage()
      })
   }




}
