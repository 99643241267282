import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'trs-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input() elem : string

  @Input() size : string

  @Input() isring : boolean

  constructor() {
    this.isring = false;
   }

  ngOnInit() {
      this.elem = (this.isring ?"lds-ring " : "lds-roller ") + this.elem + (this.size ? " size-" + this.size : "")
  }

}
