import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import * as CanvasJS from '../../../assets/canvasjs.min';
import {APIDatasService} from '../../services/apidatas.service';
import {ResizeService} from '../../services/resize.service';
import {Subscription} from 'rxjs';
import { NavService } from 'src/app/components/main-sidebar/nav.service';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.css']
})
export class MainViewComponent implements OnInit, AfterViewInit {

  dashboardData: any = null;
  chartNbrClosed = null;
  chartByStatus  = null;
  private resizeSubscription: Subscription;
  private changedMenu: Subscription;

  constructor(private notificationService: NotificationService, private router: Router, private api: APIDatasService, private resizeService: ResizeService,
              private nav : NavService) { }

  ngOnInit() {
    this.resizeSubscription = this.resizeService.onResize$
      .subscribe(size => {
        this.reRenderCharts()
      });
    this.changedMenu = this.nav.onChangedMenu$.subscribe(status=>{
      setTimeout(()=>{
        this.reRenderCharts()
      }, 200);

    })
  }
  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(){
    this.api.getDatas('dashboard').subscribe({
      next: res => this.dashboardData = res,
      error: err => console.error('Error fetching dashboard data', err),
      complete: () => this.displayCharts()
    });
  }

  reRenderCharts(){
    if(this.chartByStatus)
      this.chartByStatus.render();
    if(this.chartNbrClosed)
      this.chartNbrClosed.render();
  }

  protected showDefaultText(chart, text){

    var isEmpty = !(chart.options.data[0].dataPoints && chart.options.data[0].dataPoints.length > 0);

    if(!chart.options.subtitles)
      (chart.options.subtitles = []);

    if(isEmpty)
      chart.options.subtitles.push({
        text : text,
        verticalAlign : 'center',
      });
    else
      (chart.options.subtitles = []);
  }
  compareDataPoint(dataPoint1, dataPoint2) {
		return dataPoint1.sort - dataPoint2.sort;
  }
  protected displayCharts() {

    this.chartNbrClosed  = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      title: {
        text: "Nombre d'interventions terminées / mois"
      },
      data: [{
        type: "column",
        dataPoints: this.dashboardData.closedByMonth.map( (val) => ({y: val.count, label: `${val._id.month}/${val._id.year}`, sort: (Number(val._id.month) > 9) ? `${val._id.year}${val._id.month}` : `${val._id.year}0${val._id.month}`})).sort(this.compareDataPoint)
      }],
    });
    this.showDefaultText(this.chartNbrClosed, "Aucune intervention terminée");
    this.chartNbrClosed.render();

    this.chartByStatus = new CanvasJS.Chart("chartContainer2", {
      animationEnabled: true,
      type: 'doughnut',
      title: {
        text: "Interventions par statut"
      },
      data: [{
        type: "doughnut",
        startAngle: 60,
        indexLabelFontSize: 17,
        indexLabel: "{label} - #percent%",
        toolTipContent: "<b>{label}:</b> {y} (#percent%)",
        dataPoints: this.dashboardData.byStatus.map( (cnt) => ({y: cnt.count, label: cnt._id, color: cnt.color}))
      }],
    });
    this.showDefaultText(this.chartByStatus, "Aucune intervention disponible");
    this.chartByStatus.render();
  }



}
