import { Component, OnInit, Input } from '@angular/core';
import {distinct, distinctUntilChanged, filter, map, mergeMap, shareReplay, toArray, zipAll} from 'rxjs/operators';
import {merge, BehaviorSubject, Observable, zip, combineLatest} from 'rxjs';
import { BaseTableComponent } from '../base-table/base-table.component';
import { TableFilter } from '../filtered-table/filtered-table.component';
import * as _ from "lodash";
import { intersectionWith, isEqual } from 'lodash';

function arrayUnique(array, predicate = (a,b) => a === b) {
  var a = array.concat();
  for(var i=0; i<a.length; ++i) {
    for(var j=i+1; j<a.length; ++j) {
      if(predicate(a[i], a[j]))
        a.splice(j--, 1);
    }
  }

  return a;
}

@Component({
  selector: 'filtered-searchable-table',
  templateUrl: './filtered-searchable-table.component.html',
  styleUrls: ['./filtered-searchable-table.component.css']
})
export class FilteredSearchableTableComponent extends BaseTableComponent implements OnInit {
  filters$ = new BehaviorSubject([]);
  search$ = new BehaviorSubject([]);

  data$: Observable<Array<any>> = null
  ds: Observable<any> = null;
  @Input() filters: Array<TableFilter> = []
  @Input() searchColumns: Array<string> = [];

  ngOnInit() {
    this.data$ = this.getFilteredData();
    this.ds = this.getDataStream()
  }

  getFilteredData() {
    return combineLatest(this.search$.asObservable(), this.filters$.asObservable())
    .pipe(
      map(([search, filter]) => intersectionWith(search, filter, this.distinctCheck))
    );
  }
  hasId = ( x ) =>typeof x !== 'undefined' && typeof x._id !== 'undefined' && x._id.length > 0
  hasName = ( x ) => typeof x.name !== 'undefined' && x.name.length > 0
  createComparisonString = (obj) => JSON.stringify(obj, Object.keys(obj).sort());

  distinctCheck = (left, right) => {
    if(left === null && right === null){
      return true;
    }
    if (this.hasId(left) && this.hasId(right)){
      return left._id === right._id;
    }
    if (this.hasName(left) && this.hasName(right)){
      return left.name === right.name;
    }
    // if we have to resort to deep equal of object than let's do it
    // but ideally we would like there to be an id on the object
    return isEqual(left, right);
  }

  onFilteredDataChange($event) {
    this.filters$.next($event);
  }
  onSearchDataChange($event) {
    this.search$.next($event);
  }
  getDataStream() {
    return this.dataStream.pipe(shareReplay(1));
  }
}
