import { Component, OnInit, Input } from '@angular/core';
import { _user, User } from 'src/app/models/Users';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { NotificationService, Notification } from 'src/app/services/notification.service';
import {EMPTY, empty} from 'rxjs';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

  @Input() user = null;
  additionalFields = []
  passwordChanged = false;


  model: _user = null;
  constructor(private api: APIDatasService, private formBuilder: FormBuilder, private notifs: NotificationService) { }

  ngOnInit() {
    if (this.user != null) {
      this.buildModel(this.user)
    } else {
      this.buildModel(User.buildEmpty());
    }
    this.formGroup = this.formBuilder.group({
      password: ['', [Validators.required]],
      password2: ['', [Validators.required]]
    }, { validator: passwordMatchValidator });
  }
  buildModel(user) {
    this.model = user;
  }
  isValid(){
    return this.model.isValid()
  }
  getAdditionalFields(){
    return this.additionalFields
      .reduce((acc, f) => {
        acc[f.fieldName] = f.value
        return acc
      }, {})
  }
  removeAdditionalField(index) {
    this.additionalFields = this.additionalFields.filter((_, i) => index != i)
  }
  get form() {
    return {
      ...this.model,
      additional: this.getAdditionalFields()
    }
  }

  createUser() {
    let observable = null;
    if (this.user != null) {
      observable = this.api.update(`/users/${this.user._id}`, this.form);
    }
    else {
      observable = this.api.create("/users", this.form);
    }
    observable.subscribe({
      next: (d) => {
        if (this.user)
          this.notifs.notifyandredirect(new Notification("Utilisateur mis à jour!"), `/users`);
        else
          this.notifs.notifyandredirect(new Notification("Utilisateur créé!"), `/users/edit/${d._id}`);
        this.user = d;
      }, 
      error : (err) =>{
        this.notifs.error(new Notification(err.error.message));
      }
    })
  }
  clear() {
    this.additionalFields = [];
    if (this.user != null) {
      this.buildModel(this.user)
    } else {
      this.buildModel(User.buildEmpty())
    }
  }

  formGroup: FormGroup;
  /* Shorthands for form controls (used from within template) */
  get password() { return this.formGroup.get('password'); }
  get password2() { return this.formGroup.get('password2'); }

  /* Called on each input in either password field */
  onPasswordInput() {
    this.passwordChanged = true;
    if (this.formGroup.hasError('passwordMismatch'))
      this.password2.setErrors([{ 'passwordMismatch': true }]);
    else
      this.password2.setErrors(null);
  }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (formGroup.get('password').value === formGroup.get('password2').value)
    return null;
  else
    return { passwordMismatch: true };
};
