<div style="padding-bottom: 20px" (keydown.enter)="$event.preventDefault()">
    <mat-expansion-panel *ngFor="let loc of this.locations; let i = index;" [ngClass]="{single : !(loc.children && loc.children.length)}" 
        [expanded]="isExpanded && expandedID==i" [disabled]="false">
        <mat-expansion-panel-header (click)="$event.stopPropagation()">
            <mat-panel-title (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
                <editable (update)="updateLoc(i)" [toggled]="tEditings[i] == true" [disableClickEvent]="true">
                    <ng-template viewMode>
                        <span class="editable-value code">{{loc.code}}</span>
                        <span class="editable-value name">{{loc.name}}</span>
                        <span class="editable-value description" *ngIf="loc.description">Description : {{loc.description}}</span>
                    </ng-template>
                    <ng-template editMode>
                        <form #editForm="ngForm">
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Code" [formControl]="getControl(i, 'code')" enabled>
                                <mat-error *ngIf="getControl(i, 'code').hasError('required')">
                                    Field is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Nom" [formControl]="getControl(i, 'name')" enabled>
                                <mat-error *ngIf="getControl(i, 'name').hasError('required')">
                                    Field is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Description" [formControl]="getControl(i, 'description')" enabled>
                                <mat-error *ngIf="getControl(i, 'description').hasError('required')">
                                    Field is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </form>
                    </ng-template>
                </editable>
            </mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation()"> 
                <button mat-icon-button color="primary" (click)="this.expandAction(i)">
                    <mat-icon>list</mat-icon>
                </button>
                <button mat-icon-button color="primary" [disabled]="(!checkControls(i) && tEditings[i])" (click)="tEditings[i] = !tEditings[i]">
                    <mat-icon *ngIf="tEditings[i] != true" >edit</mat-icon>
                    <mat-icon *ngIf="tEditings[i]">done</mat-icon>
                </button>
                <button *ngIf="!(loc.children && loc.children.length) && tEditings[i] != true" mat-icon-button color="primary" (click)="removeLoc(i)">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <h3>Sous-Catégories / Sous-Emplacements</h3>
        <div class="loc-children" >
            <item-child [(arr)]="loc.children" [apiroute]="'interventions/locations'"></item-child>
        </div>
    </mat-expansion-panel>
</div>
<form #addLocForm="ngForm" class="add-new-main">
    <div><h4 >Ajouter une catégorie / un emplacement</h4></div>
    <mat-form-field color="primary">
        <mat-label>Code</mat-label>
        <input matInput placeholder="Code" name="code" required  ngModel>
    </mat-form-field>
    <mat-form-field  color="primary">
        <mat-label>Nom</mat-label>
        <input matInput placeholder="Nom"  name="name" required  ngModel>
    </mat-form-field>
    <mat-form-field  color="primary">
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" name="description" required  ngModel>
    </mat-form-field>

    <button mat-icon-button	color="primary"  (click)="addLocForm.form.reset()" >
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-icon-button	color="primary" (click)="this.addLoc(addLocForm.form.value)" [disabled]="!addLocForm.valid" >
        <mat-icon>done</mat-icon>
    </button>
</form>

