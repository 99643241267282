export interface PartInterface {
    name    : string;
    code    : string;
    _id     : string;
    type    : string;
    price   : number;
    images  : string[];
}
export interface PartListInterface {
    part_id: string;
    quantity: number;
}
export interface InterventionPartInterface{
  _id: string
  part_id: string;
  price: number
  quantity: number
  total: number
  part_name: string
}
export class PartListItem implements PartListInterface{
    part_id: string;
    quantity: number;
    category: string;
    static build(d){
        let m = new PartListItem;
        m.part_id = d.part_id;
        m.quantity = d.quantity;
        return m;
    }
}
export class Part implements PartInterface{
    static buildEmpty() {
        return Part.build({
          name: '',
          code: '',
          type: '',
          images:[],
          price: '',
          categories: []
        })
    }
    name    : string;
    code    : string;
    _id     : string;
    type    : string;
    price   : number;
    images  : string[];
    categories : string[];
    static build(d){
        let m = new Part;
        m._id = d._id;
        m.name = d.name;
        m.code = d.code;
        m.type = d.type || ""
        m.price =  d.price || 0
        m.images = d.images || []
        m.categories = d.categories || []
        return m;
    }
    constructor(){
        this.name = this.code =""
    }
}
