<div class="page-header" style="background: linear-gradient(90deg, #2C3E50, #34495E);">
        <h1>
            Ajouter un utilisateur
        </h1>
    </div>
    <div class="main users-theme" fxLayout="row">
        <div fxFlex="40" style="padding: 0px 10px">
            <mat-card class="presentation-block" style="background: #2C3E50;">
                <div class="icon" >
                    <mat-icon>perm_identity</mat-icon>
                </div>
                <div class="content">
                    <h4>Ajout de nouvel utilisateur</h4>
                </div>
            </mat-card>
        </div>
        <div fxFlex="60" style="padding: 0px 10px">
            <mat-card>
                <user-form></user-form>
            </mat-card>
        </div>
    </div>