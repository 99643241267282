<div class="image-uploader">
    <div class="preview-container">
        <div class="preview" *ngFor='let url of urls;index as i'>
            <img [src]="url" height="150">
             <button mat-button (click)="removeUpload(i)" class="delete"><mat-icon>delete</mat-icon></button>
        </div>

        <div *ngIf="this.uploadingImage" class="preview uploading">
            <trs-spinner [elem]="'img-upload'" [isring]="true" style="float: left; margin-bottom: -10px; margin-top: 5px;"></trs-spinner>
        </div>

    </div>
    <input  #file hidden id="image-input" name="image-input" type="file" accept="image/*" (change)="onSelectFile($event)" [multiple]="!(this.limit == 1)" />

    <button class="upload" color="primary" mat-raised-button (click)="file.click()" [disabled]="disabled()">
        <span style="margin: 15px;">Ajouter des images</span>
        <mat-icon>camera_alt</mat-icon>
    </button>
</div>
