export interface SettingsInterface{
  key: string;
  value: any;
  name: string;
  type: string;
}

export class Setting implements SettingsInterface{
  key: string;  value: any; name:string; type: string;
  static build(d){
    const m = new Setting();
    m.key = d.key;
    m.value = d.value;
    m.name = d.name || d.key;
    m.type = d.type;
    return m;
  }

}
