import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Remark, RemarkContract} from '../../../models/remarks'
import { CheckboxItem } from '../../checkbox-group/checkbox-group.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'remarks-form',
  templateUrl: './remarks-form.component.html',
  styleUrls: ['./remarks-form.component.css']
})
export class RemarksFormComponent implements OnInit {
  model = Remark.build({
    cause: { selected: [], additional: "" },
    misc: { additional: "" },
    work: { selected: [], additional: "" }
  });

  options = {
    cause: [],
    misc: [],
    work: []
  }

  sections = [
    { key: "cause", label: "Rapport sur cause"},
    { key: "misc", label: "Remarques importantes"},
    { key: "work", label: "Travail effectué"},
  ]
  @Input("remarks") remarks : RemarkContract = null;
  @Output() remarksChanged = new EventEmitter;
  debouncer: Subject<any> = new Subject();
  settedData : boolean = false;
  constructor() {
    this.options.cause = ["Usure","Entretien","Casse","Déprédation"].map( x => new CheckboxItem(x, x))
    this.options.misc = [].map( x => new CheckboxItem(x, x))
    this.options.work = ["Constat uniquement"].map( x => new CheckboxItem(x, x))
    this.debouncer
        .pipe(debounceTime(100))
        .subscribe((val) => this.remarksChanged.emit(val));
   }

  setData(d:RemarkContract){
    this.model = Remark.build(d);
    this.settedData = true;
  }

  onSelectionChange(vals, key){
    this.model[key].selected = new Array<string>().concat(vals);
    this.change(null)
  }

  ngOnInit() {
    if(this.remarks != null){
        this.setData(this.remarks)
    }
  }

  change(e){
    this.debouncer.next( this.model );
  }

}
