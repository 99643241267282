<div class="page-header" style="background: linear-gradient(90deg, #16A085, #1ABC9C);">

    <div class="main" style="padding-top: 0px;">
        <h1>
            Ajouter un Objet
        </h1>
    </div>
</div>
<div class="main items-theme" fxLayout="row">
    <item-form></item-form>
</div>