import { Component, OnInit } from '@angular/core';
import { AbstractListViewComponent } from '../../abstract-list-view/abstract-list-view.component';
import { APIDatasService } from 'src/app/services/apidatas.service';

@Component({
  selector: 'app-client-list-view',
  templateUrl: './client-list-view.component.html',
  styleUrls: ['./client-list-view.component.css']
})
export class ClientListViewComponent extends AbstractListViewComponent implements OnInit {

  constructor(protected api : APIDatasService) {
    super(api);
    this.listingOptions  =  {
      "type_options"  : {"base_route" : "clients", "type_name" : "Client"},
      "table_options" : [
        {"label" : "Nom", "property_name" : "name"},
        {"label" : "Adresse", "property_name" : "address"},
        {"label" : "Lieu", "property_name" : "location"},
      ]
    };
   }

   createOneDataRecord(index, array){
    var tmp = array[index];
    tmp["location"] = tmp['zip'] + " " + tmp["city"];
    return tmp;
  }
}
