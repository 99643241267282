// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // env_name: 'local',
   production: false,
   //appVersion: `${require('../../package.json').version}-dev`,
   BASE_URL: "https://suarato.quickreport.ch/api",
   //BASE_URL: "https://suaratoapi.trisdemo.ch",/* 'http://localhost:8080/',*/
   GAPS_KEY: 'AIzaSyAHBBYHtxcdKQj_F7-H5Fm9-RiJrXZxZWQ'
 };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

