import { ClientContract } from './clients';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MapMarker } from './mapMarker';
import { InterventionContract, Intervention } from './Intervention';

export interface PDFFile {
    name    : string;
    url     : string;
    size?   : number;
}

export interface OrderContract {
    _id?: string;
    code: string;
    code_confirmation: string;
    client_id: string;
    client?: ClientContract;
    owner?: string;
    order_date?: string;
    planned_date?: string;
    intervention_building: {
        address: string;
        zip: string;
        city: string;
        tenant: {
            name: string;
            phone: string;
        };
        concierge?: {
            name: string;
            phone: string;
        };
    };
    coords : MapMarker;
    additional: Record<string, unknown>;
    status: string;
    created_at: Date;
    intervention?: InterventionContract;
    management_voucher? : PDFFile[];
    attributed: boolean; 
}

export class Order implements OrderContract {
    _id?: string;
    code: string;
    code_confirmation: string;
    client_id: string;
    client?: ClientContract;
    created_at: Date;
    status: string;
    owner?: string;
    order_date?: string;
    planned_date?: string;
    coords : MapMarker;
    intervention_building: {
        address: string;
        zip: string;
        city: string;
        tenant: {
            name: string;
            phone: string;
        };
        concierge?: {
            name: string;
            phone: string;
        };
    };
    additional: Record<string, unknown>;
    intervention?: InterventionContract = null;
    management_voucher? : PDFFile[];
    attributed: boolean;

    static buildEmptyModel() : OrderContract {
        return Order.build({
            code: '',
            code_confirmation: '',
            client_id: '',
            owner: '',
            order_date: moment().format('YYYY-MM-DD'),
            planned_date: moment().add(7, 'days').format('YYYY-MM-DD'),
            intervention_building: {
                address: '',
                zip: '',
                city: '',
                tenant: {
                    name: '',
                    phone: '',
                },
                concierge: {
                    name: '',
                    phone: ''
                },
            },
            coords : MapMarker.buildEmptyModel(),
            additional: {},
            management_voucher : [],
            attributed: false,
        });
    }

    static hasValidPlanification(data : OrderContract){
        return moment(Order.transformDate(data.planned_date)).isValid();
    }

    static build(data): OrderContract {
        const m = new Order();
        m._id = data._id || '';
        m.code = data.code;

        m.order_date = moment(Order.transformDate(data.order_date)).format('YYYY-MM-DD');
        m.planned_date = (Order.hasValidPlanification(data))?moment(Order.transformDate(data.planned_date)).format('YYYY-MM-DD') : '';
        m.owner = data.owner;
        m.created_at = new Date(data.created_at);
        m.code_confirmation = data.code_confirmation;
        m.client = data.client;
        m.client_id = data.client_id;
        m.intervention_building = data.intervention_building;
        m.additional = data.additional;
        m.status = data.status;
        m.coords = data.coords || MapMarker.buildEmptyModel();
        m.intervention = data.intervention ? Intervention.build(data.intervention) : null;
        m.management_voucher = data.management_voucher;
        m.attributed = data.attributed;
        return m;
    }

    private static transformDate(strDate : string){
        if(strDate){
            return ((strDate[strDate.length-1]==='Z')?strDate.substring(0, strDate.length -1) : strDate);
        } else {
            return null;
        }
    }

}
