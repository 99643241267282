import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

import { HourLogInterface } from './hour_log';

interface HourLogLine extends HourLogInterface {
  editing : boolean
}

@Injectable({
  providedIn: 'root'
})
export class HourLogDataService {

  public list: HourLogLine[] = [];
  list$: BehaviorSubject<HourLogLine[]> = new BehaviorSubject(this.list);

  constructor() {}


  update(index, field, value) {
    this.list = this.list.map((e, i) => {
      if (index === i) {
        return {
          ...e,
          [field]: value,
          editing: false
        }
      }
      return e;
    });
    this.list$.next(this.list);
  }
  add(data){
    data.editing = false;
    this.list = this.list.concat([data])
    this.list$.next(this.list)
  }
  delete(index){
    this.list = this.list.filter( (v, index_) => {
      return index != index_
    })
    this.list$.next(this.list)
  }

  empty(){
    this.list = this.list.filter( (v, index_) => {
      return false
    })
    this.list$.next(this.list)
  }

}