import { Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges, Output, EventEmitter } from '@angular/core';
import { from } from 'rxjs';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { partition } from 'rxjs/operators';
import {Part, PartInterface} from '../interventions-form/parts-form/services/part';
import {NotificationService, Notification} from '../../services/notification.service';

@Component({
  selector: 'part-form-addedit',
  templateUrl: './part-form-addedit.component.html',
  styleUrls: ['./part-form-addedit.component.css']
})
export class PartFormAddeditComponent implements OnInit, OnChanges {
  itemTypes = [];

  @Input() part : PartInterface;
  @Output() close = new EventEmitter<any>();
  @Output() dataSent = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges){
    const newModel: SimpleChange = changes.part;


    if(newModel.currentValue){
      this.model = Part.build(newModel.currentValue);
    } else if(newModel.currentValue == null){
      this.model = this.createEmptyModel();
    }
  }

  model: any = false;

  constructor(private api: APIDatasService, private notifs : NotificationService) { }

  ngOnInit() {
    this.clear();
    let tmpData = [];
    this.api.getDatas('interventions/item_types').subscribe(
      it => tmpData = it,
      e  => console.error(e),
      () => {
        this.itemTypes = tmpData;
        this.buildModel();
      }
    );
  }

  buildModel(){
    if (this.part == null) {
      this.model = this.createEmptyModel()
    } else {
      this.model = Part.build(this.part)
    }
  }

  upsertPart(event) {
    let observable = from([]);

    if (this.part == null) {
      observable = this.api.create("/interventions/parts", this.model)
    } else {
      observable = this.api.update(`/interventions/parts/${this.part._id}`, this.model)
    }
    observable.subscribe({
      next: (d) => {
        this.model = Part.build(d);
        this.dataSent.next(this.model);
      },
      error: (err) => this.notifs.error(new Notification('Une erreur est survenue'))
    });
  }
  setImages(urls) {
    this.model.images = urls;
  }
  clear() {
    this.model = this.createEmptyModel()
  }

  deleteImage(filename) {
    if (this.part != null)
      this.api.delete(`/parts/${this.part}/images`, filename)
  }

  createEmptyModel() : PartInterface {
    return Part.buildEmpty();
  }
}
