import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { MatDialog } from '@angular/material/dialog';
import { NewPartModalComponent } from './new-part-modal/new-part-modal.component';
import { PartDataService } from './services/part_data.service';
import { PartListItem, PartInterface, Part, PartListInterface } from './services/part';
import { _intervention } from 'src/app/models/DB';

@Component({
  selector: 'parts-form',
  templateUrl: './parts-form.component.html',
  styleUrls: ['./parts-form.component.css']
})
export class PartsFormComponent implements OnInit {
  serviceOrdered = new PartDataService();
  serviceSpare = new PartDataService();
  parts = [];
  partMap = {};
  @Input() intervention: _intervention = null;

  @Output() partsChange = new EventEmitter<
  {
    spare_parts : PartListInterface[],
    ordered_parts : PartListInterface[]
  } >();


  constructor(private api: APIDatasService, private dialog:MatDialog) { }

  ngOnInit() {
    this.api.getDatas("/interventions/parts").subscribe({
      next: (data) => {
        this.parts = data
        this.parts.map( (part) => {
          this.partMap[part._id] = part
        })
      }
    })
  }

  openDialog(){
    let dialogRef = this.dialog.open(NewPartModalComponent, {
      height: '400px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe({
      next: (newPart : PartInterface) => {
        if(newPart){
          this.parts.push(newPart)
          this.partMap[newPart._id] = newPart
        }
      }
    })
  }

  setData(list : { spare_parts   : PartListInterface[], ordered_parts : PartListInterface[] }){
    list.spare_parts.forEach( (part) => this.serviceSpare.add(part));
    list.ordered_parts.forEach( (part) => this.serviceOrdered.add(part));
  }


  /*
  * Use this accessor to actually get the data
  */
  get form(){
    return {
      spare: this.serviceSpare.list,
      ordered: this.serviceOrdered.list
    }
  }

  //received event from child, push parts to parent.
  onPartChange(){
    this.partsChange.emit(
      {
        spare_parts   : this.serviceSpare.list,
        ordered_parts : this.serviceOrdered.list
      }
    );
  }

}
