import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { JwtHelperService } from "@auth0/angular-jwt";
@Component({
  selector: 'user-guard',
  templateUrl: './user-guard.component.html',
  styleUrls: ['./user-guard.component.css']
})
export class UserGuardComponent implements OnInit {
  @Input() is : string = null;
  token = null;
  constructor(protected auth : AuthService) {
    const helper = new JwtHelperService();

    this.token = helper.decodeToken(auth.getToken());
    auth.isLoggedIn$.subscribe(val => {
      if(val){
        this.token = helper.decodeToken(auth.getToken());
      } else {
        this.token = null;
      }
    })
  }

  ngOnInit() {

  }
  isAllowed(){
    if(! this.token)
      return false;

    return this.token.scopes.indexOf((this.is && this.is != "" )?this.is:'employee') != -1
  }

}
