import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> |
                                                      Promise<boolean | UrlTree> |
                                                      boolean | UrlTree {

    this.authService.checkConnectionWithAPI();

    if (this.authService.isAuth()) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
