import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { AuthService } from 'src/app/services/auth.service';
import { HourLogDataService } from './services/hour_log_data.service';
import { HourLogInterface, HourLog } from './services/hour_log';
import { formatDate } from '@angular/common';
import { Moment } from 'moment';
import * as moment from 'moment';
import { User } from 'src/app/models/Users';

@Component({
  selector: 'hour-log-form',
  templateUrl: './hour-log-form.component.html',
  styleUrls: ['./hour-log-form.component.css'],
  exportAs: 'ngForm'
})
export class HourLogFormComponent implements OnInit {
  @Output() hourlogsChange = new EventEmitter<HourLogInterface[]>();

  employees = [];
  employeeMap = {};
  loaded = false;
  currentUser;
  breakpoint;

  model:HourLogInterface = HourLogFormComponent.buildEmptyModel();

  displayedColumns: string[] = ['date', 'hour_start', 'hour_end', "employee", "controls"];
  dataSource = this.service.list$;
  controls: FormArray;
  constructor(private api: APIDatasService, private auth: AuthService, public service:HourLogDataService) {
    this.model = HourLogFormComponent.buildEmptyModel(this.auth.getUser()._id);
  }

  static buildEmptyModel(userid : string = '') : HourLogInterface{
    return HourLog.build({date: moment().format('YYYY-MM-DD'),hour_start:'',hour_end:moment().format('HH:mm'),employee_id: userid });
  }

  ngOnInit() {
    this.model = HourLogFormComponent.buildEmptyModel();
    this.api.getDatas("users").subscribe({
      next: d => {
        this.employees = d
        d.map( (employee) => {
          this.employeeMap[employee._id] = employee
        })
        this.currentUser = this.auth.getUser()._id;
        this.loaded = true
      }
    });

    this.breakpoint = (window.innerWidth <= 400) ? 1 : 5;

  }
  ngAfterViewInit(){
    this.updateControlers()
    //this.outputList();
  }
  updateControlers(){
    const toGroups = this.service.list$.value.map(entity => {
      return new FormGroup({
        date:  new FormControl(entity.date, Validators.required),
        hour_start: new FormControl(entity.hour_start, Validators.required),
        hour_end: new FormControl(entity.hour_end, Validators.required),
        employee_id: new FormControl(entity.employee_id, Validators.required)
      },{updateOn: "blur"});
    });
    this.controls = new FormArray(toGroups);
  }

  addHourLog(){
    this.service.add(this.model)
    this.model = HourLogFormComponent.buildEmptyModel();
    this.updateControlers()
    this.outputList();
  }

  private outputList(){
    this.hourlogsChange.emit( this.service.list );
  }

  setData(list){
    this.service.empty();
    for(var hl of list){
      this.service.add(hl);
    }
    this.updateControlers()
    this.outputList()
  }

  sendUpdate(i, element){
    const fields = ['date', 'hour_start', 'hour_end', 'employee_id'];
    fields.forEach(field =>{
      this.updateField(i, field)
    })

    element.editing = false;
    if(fields.every( field => this.getControl(i, field).valid))
      this.outputList();
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      this.service.update(index,field,control.value);
    }
  }

  getControl(index, fieldName) {
    const a  = this.controls.at(index).get(fieldName) as FormControl;
    return this.controls.at(index).get(fieldName) as FormControl;
  }
  getEmployee(employeeId): User{
      return this.employeeMap[employeeId]
  }
  deleteRow(element, index){
    this.service.delete(index)
    this.outputList();
    this.updateControlers()
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 5;
  }

  dateChanged($event){
    var date : Moment = $event.target._value;
    this.model.date = date.format('YYYY-MM-DD');
  }
}
