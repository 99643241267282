<div style="text-align:center" style="margin-top : 20px;">
  <div class="container">
    <img style="max-width: 100%;height: 150px;display : block;width: auto;
      image-rendering: -webkit-optimize-contrast;margin-left: auto;
      margin-right: auto;margin-top: -30px;" src="assets/images/suarato-logo-petit.png" />
    <h1>
      Tableau de bord
    </h1>

  </div>

  <div class="container">
    <mat-card *ngIf="dashboardData" class="full">
      <h3>Nombre d'interventions en cours: {{dashboardData.openToday}}</h3>
      <button mat-raised-button [routerLink]="['/interventions']">Consulter</button>
    </mat-card>
  </div>

  <div class="container" fxLayout="row wrap" fxLayout.lt-lg="column">
      <div style="padding : 20px;" fxFlex="50">
          <mat-card style="overflow: hidden;height: 300px">
              <div id="chartContainer" style="height: 100%;width : 100%" class="chart-container"></div>
            </mat-card>
      </div>
      <div style="padding : 20px;" fxFlex="50">
          <mat-card style="overflow: hidden;height: 300px">
              <div id="chartContainer2" style="height: 100%;width : 100%" class="chart-container"></div>
            </mat-card>
      </div>
    </div>


</div>