<div style="padding-bottom: 20px">
    <mat-expansion-panel *ngFor="let typ of this.types; let i = index;"
        [ngClass]="{single : !(typ.targets && typ.targets.length)}" [expanded]="isExpanded && expandedID==i"
        [disabled]="false"  >
        <mat-expansion-panel-header (click)="$event.stopPropagation()">
            <mat-panel-title (click)="$event.stopPropagation();" >
                <editable (update)="updateTyp(i)" [toggled]="tEditings[i] == true" [disableClickEvent]="true">
                    <ng-template viewMode>
                        <span class="editable-value code">{{typ.code}}</span>
                        <span class="editable-value name">{{typ.name}}</span>
                    </ng-template>
                    <ng-template editMode>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Code" [formControl]="getControl(i, 'code')">
                            <mat-error *ngIf="getControl(i, 'code').hasError('required')">
                                Field is <strong>required</strong>
                            </mat-error> 
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Nom" [formControl]="getControl(i, 'name')">
                            <mat-error *ngIf="getControl(i, 'name').hasError('required')">
                                Field is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </ng-template>
                </editable>
            </mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation()">
                <button mat-icon-button color="primary" (click)="this.expandAction(i)">
                    <mat-icon>list</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="tEditings[i] = !tEditings[i]" [disabled]="(!checkControls(i) && tEditings[i] == true)">
                    <mat-icon *ngIf="tEditings[i] != true" >edit</mat-icon>
                    <mat-icon *ngIf="tEditings[i]" >done</mat-icon>
                </button>
                <button *ngIf="!(typ.targets && typ.targets.length || (typ.code=='CC' || typ.code == 'RR'))"
                    mat-icon-button color="primary" (click)="removeType(i)" >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <h3>Travaux</h3>
        <div class="typ-targets">
            <item-child [(arr)]="typ.targets" [apiroute]="'interventions/targets'"></item-child>
        </div>
    </mat-expansion-panel>
</div>
<form #addTargetForm="ngForm" class="add-new-main">
    <div><h4 >Ajouter un type de travaux</h4></div>
    <mat-form-field color="primary">
        <mat-label>Code</mat-label>
        <input matInput placeholder="Code" name="code" required ngModel>
    </mat-form-field>
    <mat-form-field color="primary">
        <mat-label>Nom</mat-label>
        <input matInput placeholder="Nom"  name="name" required ngModel>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="addTargetForm.reset()">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-icon-button color="primary" (click)="this.addType(addTargetForm.form.value)">
        <mat-icon>done</mat-icon>
    </button>
</form>