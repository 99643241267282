<div class="page-header" style="background: linear-gradient(90deg, #86591B, #906830);">
    <h1>
        Pièces
    </h1>
</div>
<div class="main parts-theme" fxLayout="row">
    <div fxFlex="50" style="padding: 0px 10px">
        <mat-card class="presentation-block" style="background: #86591B;">
            <div class="icon" >
                <mat-icon>build</mat-icon>
            </div>
            <div class="content">
                <h4>Gestion des pièces</h4>
                <p>Cette vue sert à gérer les pièces pour les interventions (pièces de rechange / à commander)</p>
            </div>
            <button style="border: 1px solid white;position: absolute;top: 10px;right: 10px;" mat-button (click)="this.currentPart = null" >Nouvelle pièce</button>
        </mat-card>
        <app-listing-form [noSearch]="true" [(datas)]="this.datas" [(options)]="listingOptions" (needDataReload)="this.fetchDatas()"
        [hidden]="!this.finishedLoading" (deletedObject)="this.fetchDatas()"
        style="width : 100%"></app-listing-form>
    <div *ngIf="!this.finishedLoading">
        <trs-spinner [elem]="'parts'"></trs-spinner>
    </div>
    </div>
    <div fxFlex="50" style="padding: 0px 10px">
        <mat-card class="client-address">
            <part-form-addedit [part]="currentPart" (dataSent)="this.fetchDatas()"></part-form-addedit>
        </mat-card>
    </div>
</div>


 