import {Component, OnInit, Output, EventEmitter, Input, SimpleChanges, SimpleChange, OnChanges} from '@angular/core';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { _intervention } from 'src/app/models/DB';
import { Observable, from } from 'rxjs';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css']
})
export class ImageUploaderComponent implements OnInit, OnChanges {
  @Output() imageListChange = new EventEmitter<string[]>();
  @Output() imageDeleted = new EventEmitter<string>();
  @Input() urls = [];
  @Input() limit = -1;

  uploadingImage = false

  constructor(private api : APIDatasService) { }

  ngOnChanges(changes : SimpleChanges){
    const newModel: SimpleChange = changes.urls;
    const newLimit: SimpleChange = changes.limit;
    this.urls = newModel.currentValue;
    this.limit = newLimit.currentValue;
  }
  ngOnInit() {
  }
  disabled(){
    return this.limit < 0 ? false : this.urls.length >= this.limit
  }
  onSelectFile(event){
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      let limit = this.limit == -1 ? filesAmount : this.limit - this.urls.length;
      let promises = [];
      for (let i = 0; i < limit; i++) {
              var reader = new FileReader();
              this.uploadingImage = true;
              promises.push(new Promise(resolve =>{
                reader.onload = (event:any) => {
                    this.compressImage(event.target.result).then(compressed =>{
                    this.urls.push(compressed);
                    this.outputList();
                  }).then(()=>resolve())
                }
              }))
              Promise.all(promises).then(()=>{
                this.uploadingImage = false;
              })
              reader.readAsDataURL(event.target.files[i]);
      }

    }
  }
  removeUpload(index){
    let observable = from([true]);//create empty observable and replace him if needed
    if(this.urls[index].startsWith("http")){
      const filename = this.urls[index].split('/').pop()
      this.imageDeleted.emit(filename)
    }

    observable.subscribe({
      next: res => {
        this.urls = this.urls.filter( (u, i) => i != index)
        this.outputList();
      }
    });
  }

  setData(list){
    this.urls = list;
  }

  private outputList(){
    this.imageListChange.emit( this.urls );
  }

  compressImage(src) {
    return new Promise((res, rej) => {
      const img = new Image();
      const MAX_WIDTH = 1000, MAX_HEIGHT = 600;
      img.src = src;


      img.onload = () => {
        if(img.width > MAX_WIDTH || img.height > MAX_HEIGHT){
          const resImg = document.createElement('canvas');
          let resWidth = 0, resHeight = 0;

          if(img.height > img.width){
              resHeight = MAX_HEIGHT;
              resWidth = (img.width * MAX_HEIGHT) / img.height
          } else {
            resWidth = MAX_WIDTH;
            resHeight = (MAX_WIDTH * img.height) / img.width

            if(resHeight > MAX_HEIGHT){ // in case of near square picture
              let oldH = resHeight;
              resHeight = MAX_HEIGHT;
              resWidth = (resWidth * MAX_HEIGHT) / oldH
            }
          }

          resImg.width = resWidth;
          resImg.height = resHeight;
          const ctx = resImg.getContext('2d');
          ctx.drawImage(img, 0, 0, resWidth, resHeight);
          const data = ctx.canvas.toDataURL();
          res(data);
        } else {
          res(src)
        }
      }
      img.onerror = error => rej(error);
    })
  }
}
