import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.css']
})
export class ExitComponent implements OnInit {

  constructor(private auth : AuthService) { }

  ngOnInit() {
    setTimeout(function(){
      window.open('','_parent','');
      window.close();
      var ww = window.open(window.location.href, '_self');
      ww.close();
      this.auth.logOut()
    }, 100);
  }

}
