import { Component, OnInit, Input } from '@angular/core';
import { ClientContract, Client } from 'src/app/models/clients';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { NotificationService, Notification } from 'src/app/services/notification.service';


@Component({
  selector: 'client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.css'],
  exportAs: 'ngForm'
})
export class ClientFormComponent implements OnInit {

  @Input() client = null;
  additionalFields = []

  model:ClientContract = null;
  constructor(private api: APIDatasService, private notifs: NotificationService) { }

  ngOnInit() {
    if(this.client != null){
      this.buildModel(this.client)
      }else{
      this.buildModel(this.buildEmptyModel());
    }
  }
  buildModel(client){
    this.model = client;
  }

  getAdditionalFields(){
    return this.additionalFields
    .reduce( (acc, f) => {
      acc[f.fieldName] = f.value
      return acc
    }, {})
  }
  removeAdditionalField(index){
    this.additionalFields = this.additionalFields.filter( (_,i) => index != i)
  }
  buildEmptyModel(){
    return Client.build({
      images : [],
      name: '',
      person : '',
      address: '',
      zip: '',
      city: '',
      phone : '',
      mobile : ''
    })

  }
  get form(){
    return {
      ...this.model,
      additional: this.getAdditionalFields()
    }
  }

  createClient(){
    let observable = null;
    if(this.client!=null){
      observable =this.api.update(`/clients/${this.client._id}`, this.form);
    }
    else{
      observable =this.api.create("/clients", this.form);
    }
    observable.subscribe(
      res => {
        this.model = res
      },
      error => {
          this.notifs.error(new Notification(error.error.message));
      },
      () => {
        var message = "Client " + ((this.client && this.client._id) ?"mis à jour" : "enregistré") + " avec succès"

        if(this.client && this.client._id){
          this.notifs.notifyandredirect(new Notification(message), 'clients')
        } else {
          this.notifs.notifyandredirect(new Notification(message), 'clients/edit/'+ this.model._id)
        }
    });
  }
  clear(){
    this.additionalFields = [];
    if(this.client != null) {
      this.buildModel(this.client)
    } else {
      this.buildModel(this.buildEmptyModel())
    }
  }

}