<mat-card style="margin-top : 20px">
    <h4>Kilométrage</h4>
    <div class="distance">
        <div class="cards" fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="33">
                <mat-card class="km-card">
                    <mat-card-header>
                        <div mat-card-avatar class="tp-header-image"></div>
                        <mat-card-title>Nombre de trajets</mat-card-title>
                    </mat-card-header>
                    <mat-form-field class="form-field">
                        <input matInput placeholder="Nombre de trajets" type="number" min="0" [(ngModel)]="nbTrajets"
                            name="nbTrajets" (ngModelChange)="updatenbTrajets()" required >
                    </mat-form-field>
                </mat-card>
            </div>
            <div fxFlex="33">
                <mat-card class="km-card">
                    <mat-card-header>
                        <div mat-card-avatar class="tp-header-image"></div>
                        <mat-card-title>Distance simple</mat-card-title>
                    </mat-card-header>
                    <p>
                        <span class="trs-distance">{{(this.distance > 1000)? this.distance/1000 + " km" : this.distance + " m"}}</span>
                        <span class="trs-traveltime">({{(this.travelTime > 60)? (this.travelTime/60).toFixed(2) + " heures" : this.travelTime.toFixed(2) + " min."}})</span>
                    </p>
                </mat-card>
            </div>

            <div fxFlex="33">
                <mat-card class="km-card">
                    <mat-card-header>
                        <div mat-card-avatar class="tp-header-image"></div>
                        <mat-card-title>Distance totale</mat-card-title>
                    </mat-card-header>
                    <p>
                        <span class="trs-distance">{{((this.distance * this.nbTrajets *2) > 1000)?(this.distance * this.nbTrajets *2)/1000 + " km" : (this.distance * this.nbTrajets *2) + " m"}}</span>
                        <span class="trs-traveltime">({{((this.travelTime * this.nbTrajets * 2) > 60)? ((this.travelTime * this.nbTrajets * 2)/60).toFixed(2) + " heures" : (this.travelTime * this.nbTrajets * 2).toFixed(2) + " min."}})</span>
                    </p>
                </mat-card>
            </div>

        </div>
        <trs-pathmap *ngIf="_destination" [destinationCoords]="_destination" [imageMode]="true"></trs-pathmap>
    </div>
</mat-card>