import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import * as SignaturePad from 'signature_pad';
import { Observable, noop, Observer, Subscribable, Subscription, merge } from 'rxjs';
import { debounceTime, map, tap, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
@Component({
  selector: 'signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})

export class SignaturePadComponent implements OnInit {
  @ViewChild("signature", {static:true}) signCanvas;
  @Output() onUpdate: EventEmitter<any>;
  @Input() signature:string = null;
  signUpdate : Subscription;
  _signaturePad:any;

  constructor(private _el : ElementRef) {
    this.onUpdate = new EventEmitter()
   }

  ngOnInit() {
    this._signaturePad = new SignaturePad.default(this.signCanvas.nativeElement);
    this._signaturePad.w
    if(this.signature != null)
      this._signaturePad.fromDataURL(this.signature, {ratio : 1,width :  300,height :  100})
    const observer = {
      next: data => {console.log("Emitting");
            this.onUpdate.emit(data)},
      error: err => console.error(err),
      complete(){}
    }

    const move$ = merge(fromEvent(this._el.nativeElement, 'mousemove'), fromEvent(this._el.nativeElement, 'touchmove'))
    const down$ = merge(fromEvent(this._el.nativeElement, 'mousedown'), fromEvent(this._el.nativeElement, 'touchstart'))
    const up$   = merge(fromEvent(this._el.nativeElement, 'mouseup'),   fromEvent(this._el.nativeElement, 'touchend'))

    const paints$ = down$.pipe(
      mergeMap(down => move$.pipe(takeUntil(up$)))
    );

    this.signUpdate =
    paints$.pipe(debounceTime(50))
    .pipe(map(_=>{
      if(!this._signaturePad.isEmpty())
          return this._signaturePad.toDataURL()
      else
          return "";
    }))
    .subscribe(observer);
  }

  setData(image : string){
    this._signaturePad.fromDataURL(image);
  }

  clear(){
    this._signaturePad.clear();
    ;
    this.onUpdate.emit("")
    this.InputVar.nativeElement.value = "";
    this._signaturePad.on();
  }
  ngOnDestroy(){
    this.signUpdate.unsubscribe();
  }

  uploadingImage = false;
  @ViewChild('file', {static: false})
  InputVar: ElementRef;

  onSelectFile(event){
    if (event.target.files && event.target.files[0]) {
      let promises = [];
      var reader = new FileReader();
      this.uploadingImage = true;
      promises.push(new Promise(resolve =>{
        reader.onload = (event:any) => {
            this.compressImage(event.target.result).then(compressed =>{
            if(typeof(compressed) == "string"){
              this.setData(compressed);
              this.signature = compressed;
              this.onUpdate.emit(compressed);
            }
          }).then(()=>resolve())
        }
      }))
      Promise.all(promises).then(()=>{
        this.uploadingImage = false;
      })
      reader.readAsDataURL(event.target.files[0]);
      this._signaturePad.off();

    }
  }
  compressImage(src) {
    return new Promise((res, rej) => {
      const img = new Image();
      const MAX_WIDTH = 300, MAX_HEIGHT = 100;
      img.src = src;


      img.onload = () => {
        if(img.width > MAX_WIDTH || img.height > MAX_HEIGHT){
          const resImg = document.createElement('canvas');
          let resWidth = 0, resHeight = 0;

          if(img.height > img.width){
              resHeight = MAX_HEIGHT;
              resWidth = (img.width * MAX_HEIGHT) / img.height
          } else {
            resWidth = MAX_WIDTH;
            resHeight = (MAX_WIDTH * img.height) / img.width

            if(resHeight > MAX_HEIGHT){ // in case of near square picture
              let oldH = resHeight;
              resHeight = MAX_HEIGHT;
              resWidth = (resWidth * MAX_HEIGHT) / oldH
            }
          }

          resImg.width = resWidth;
          resImg.height = resHeight;
          const ctx = resImg.getContext('2d');
          ctx.drawImage(img, 0, 0, resWidth, resHeight);
          const data = ctx.canvas.toDataURL();
          res(data);
        } else {
          res(src)
        }
      }
      img.onerror = error => rej(error);
    })
  }

}
