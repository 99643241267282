<div>
    <div class="main orders-theme" >
        <form>
            <div fxLayout="row" fxLayout.lt-md="column">
                <div fxFlex="40"  style="padding: 0px 10px">
                    <ng-content select="[headerCard]"></ng-content>
                    <div style="margin-top: 20px;">
                        <mat-card >
                            <mat-card-title>Bon de gérance</mat-card-title>
                            <div class="content" style="font-size: 14px;">
                                <p>Vous pouvez ici insérer un bon de gérance au format PDF.</p>
                                <pdf-uploader #management_voucher [files]="model.management_voucher" [limit]="1" (deletedFile)="deletePDFFromOrder($event)" 
                                              (changedFileList)="onPDFChange($event)"></pdf-uploader>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <div fxFlex="60" style="padding: 0px 10px">
                    <mat-card class="order-infos" style="margin-bottom: 20px;">
                        <table style="width : 100%;">
                            <tr>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input matInput placeholder="N° Confirmation" [(ngModel)]="model.code_confirmation"
                                            name="code_confirmation">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input matInput placeholder="N° de Bon" [(ngModel)]="model.code" name="code">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <mat-select placeholder="Client" [(ngModel)]="model.client_id" name="client_id">
                                            <mat-option *ngFor="let client of clients" value="{{client._id}}">
                                                <div class="client-list-option">
                                                    <span>{{client.name}} </span><span
                                                        style="font-size: 12px; display : block">{{client.address}}, {{client.zip}}
                                                        {{client.city}}</span>
                                                </div>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input matInput placeholder="Propriétaire" [(ngModel)]="model.owner" name="owner">
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="form-field fullwidth">
                                        <input matInput [matDatepicker]="picker_date" placeholder="Date" [(ngModel)]="model.order_date"
                                            name="order_date" required (dateChange)="dateChanged($event, 'order_date')">
                                        <mat-datepicker-toggle matSuffix [for]="picker_date"></mat-datepicker-toggle>
                                        <mat-datepicker #picker_date startView="month"></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="form-field fullwidth">
                                        <input matInput [matDatepicker]="picker_planned_date" placeholder="Date prévue de réalisation"
                                            [(ngModel)]="model.planned_date" name="planned_date"
                                            (dateChange)="dateChanged($event, 'planned_date')">
                                        <mat-datepicker-toggle matSuffix [for]="picker_planned_date"></mat-datepicker-toggle>
                                        <mat-datepicker #picker_planned_date startView="month"></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                        <table style="position : relative; width : 100%;"
                            [ngClass]="{'invalid': (InterAddr.touched || this.order) && !hasValidAddress, 'valid': (InterAddr.touched || this.order) && hasValidAddress}">
                            <div class="addressValidity" *ngIf="(InterAddr.touched || this.order) && hasValidAddress"
                                matTooltip="Adresse validée par google !">
                                <mat-icon>done</mat-icon>
                            </div>
                            <div class="addressValidity" *ngIf="(InterAddr.touched || this.order) && !hasValidAddress"
                                matTooltip="Adresse invalide" >
                                <mat-icon (click)="checkAddress()">priority_high</mat-icon>
                            </div>
                            <tr>
                                <td colspan="2">
                                    <mat-form-field class="fullwidth" style="max-width: 95%;">
                                        <input matInput placeholder="Adresse" [(ngModel)]="model.intervention_building.address"
                                            name="address" (blur)="typingAddress()" required #InterAddr="ngModel" >
                                        <mat-error>
                                            <span>
                                                Merci de saisir une adresse
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
            
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="fullwidth" >
                                        <input matInput placeholder="Npa" [(ngModel)]="model.intervention_building.zip" mask="0*"
                                            minlength="4" maxlength="6" #NPAInter="ngModel" name="zip" (blur)="typingAddress()">
                                        <mat-error *ngIf="NPAInter.touched && NPAInter.invalid">
                                            <span>
                                                NPA invalide
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input matInput placeholder="Ville" [(ngModel)]="model.intervention_building.city"
                                            name="city" (blur)="typingAddress()" required>
                                        <mat-error>
                                            <span>
                                                Merci de saisir une ville
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                        <div *ngIf="model.coords && ((InterAddr.touched || this.order) && hasValidAddress)">
                            <trs-pathmap  [destinationCoords]="coordinates" [imageMode]="true"></trs-pathmap>
                        </div>
                    </mat-card>
                    <mat-card class="order-building-infos" style="margin-bottom: 20px;">
                        <table style="width : 100%;">
                            <tr>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input matInput placeholder="Locataire"
                                            [(ngModel)]="model.intervention_building.tenant.name" name="tenant_name">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input matInput placeholder="Numéro de téléphone"
                                            [(ngModel)]="model.intervention_building.tenant.phone" name="tenant_phone" type="tel"
                                            mask="000 000 00 00" [validation]="true" #tenantPhone="ngModel">
                                        <mat-error *ngIf="tenantPhone.touched && tenantPhone.invalid">
                                            <span>
                                                Numéro de téléphone invalide
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input matInput placeholder="Concierge"
                                            [(ngModel)]="model.intervention_building.concierge.name" name="concierge_name">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input matInput placeholder="Numéro de téléphone"
                                            [(ngModel)]="model.intervention_building.concierge.phone" name="concierge_phone"
                                            #conciergePhone="ngModel" mask="000 000 00 00" [validation]="true">
                                        <mat-error *ngIf="conciergePhone.touched && conciergePhone.invalid">
                                            <span>
                                                Numéro de téléphone invalide
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                    </mat-card>
                    <mat-card class="order-supp-infos" style="margin-bottom: 20px;">
                        <table style="width : 100%;">
                            <tr>
                                <td>
                                    <p style="width: fit-content;display: inline-block;"> Informations additionelles</p>
                                    <button type="button" (click)="addAdditionalField('','')" mat-button color="accent">Ajouter</button>
                                </td>
                            </tr>
                            <tr *ngFor="let additional of additionalFields; let i = index">
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input name="{{additional.fieldName}}{{i}}fieldname" required matInput
                                            placeholder="{{(additional.fieldName)?additional.fieldName:'Texte'}}"
                                            [(ngModel)]="additionalFields[i].fieldName">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="fullwidth">
                                        <input name="{{additional.fieldName}}{{i}}value" required matInput placeholder="Valeur"
                                            [(ngModel)]="additionalFields[i].value">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <button type="button" mat-raised-button color="warn" (click)="removeAdditionalField(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </tr>
            
                        </table>
                        <div style="text-align : right; width: 95%;">
                            <button type="button" (click)="createOrder()" mat-raised-button
                                color="accent">{{order != null ? 'Mettre à jour' : 'Enregistrer'}}</button>
                            <button type="button" [routerLink]="['/orders']" mat-button color="accent">Annuler</button>
                        </div>
                    </mat-card>
                </div>
            </div>
        </form>
    </div>
</div>