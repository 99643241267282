<mat-card class="login-form-card">
    <img style="max-width: 100%;height: 150px;display : block;width: auto;
                image-rendering: -webkit-optimize-contrast;margin-left: auto;
                margin-right: auto;margin-top: -30px;" src="assets/images/suarato-logo-petit.png"/>
    <mat-card-header>
        <mat-card-title>Login</mat-card-title>
    </mat-card-header>
    <form class="form-signin" #loginForm="ngForm" (ngSubmit)="submitLogin(loginForm.form);">
        <mat-card-content>
            <div *ngIf="this.msgToShow">
                <div class="alert alert-danger"> {{this.msgToShow}} </div>
            </div>
        
            <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
        </mat-card-content>

        <table class="form-container" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field class="form-field fullwidth">
                        <input matInput placeholder="Utilisateur"  name="username" required ngModel>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="form-field fullwidth">
                        <input matInput placeholder="Password" type="password"
                            name="password" required ngModel>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    
        <mat-card-actions style="text-align : right; width: 95%;">
                <button mat-raised-button color="primary" [disabled]="loginForm.invalid">Login</button>
        </mat-card-actions>
    </form>
</mat-card>