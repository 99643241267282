<div class="page-header" style="background: linear-gradient(90deg, #D35400, #dc7e60);">
    <h1>
        Tous les clients
    </h1>
</div>
<div class="main clients-theme" fxLayout="row">
    <app-listing-form [(datas)]="this.datas" [(options)]="listingOptions"
        [hidden]="!this.finishedLoading || !this.datas.length" (deletedObject)="this.fetchDatas()"
        style="width : 100%" (needDataReload)="this.fetchDatas()"></app-listing-form>
    <div *ngIf="this.finishedLoading && !this.datas.length">
        <h1>Il n'y a pas de clients à afficher</h1>
    </div>
    <div *ngIf="!this.finishedLoading">
        <trs-spinner [elem]="'client'"></trs-spinner>
    </div>
</div>