<div class="parts-container">
    <table >
        <tr >
            <td>
                <mat-form-field class="fullwidth">
                    <mat-label>Catégories</mat-label>
                    <mat-select name="categories" [(ngModel)]="model.category">
                        <mat-option value="">
                            Toutes
                        </mat-option>
                        <mat-option value="{{it._id}}" *ngFor="let it of this.itemTypes; let i = index;">
                            {{it.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field class="form-field fullwidth">
                    <mat-label>Pièce</mat-label>
                    <mat-select [(ngModel)]="model.part_id" name="part">
                        <mat-option *ngFor="let part of getParts()" [value]="part._id">
                            {{part.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field class="form-field">
                    <mat-label>Quantité</mat-label>
                    <input matInput placeholder="Quantité" value="quantity" [(ngModel)]="model.quantity" type="number" min="0" step="1" oninput="validity.valid||(value=Math.round(value))" >
                </mat-form-field>
                <button mat-flat-button color="primary" (click)="insertPart($event)" [disabled]="!model || !model.quantity || !model.part_id"
                        style="position: absolute; top: 15px; right: 15px;">
                    <mat-icon>add_circle</mat-icon> Ajouter
                </button>
            </td>
        </tr>
    </table>
    <table mat-table [(dataSource)]="dataSource">
        <ng-container matColumnDef="part">
            <th mat-header-cell *matHeaderCellDef> Pièce </th>
            <td mat-cell *matCellDef="let element">
              <div style="display: flex;align-items:center;">
                <img style="vertical-align: center" *ngFor="let img of getPart(element.part_id).images" src="{{img}}" height="20" />
                <span>{{getPart(element.part_id).name}}</span>
              </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element"> {{getPart(element.part_id).type}} </td>
        </ng-container>
        <!--
        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Prix </th>
            <td mat-cell *matCellDef="let element"> {{getPart(element.part_id).price}} </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> Code </th>
            <td mat-cell *matCellDef="let element"> {{getPart(element.part_id).code}} </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef> Prix </th>
          <td mat-cell *matCellDef="let element"> {{getPart(element.part_id).price}} </td>
      </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantité </th>
            <td mat-cell *matCellDef="let element; let i = index">{{element.quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="images">
            <th mat-header-cell *matHeaderCellDef> Images </th>
            <td mat-cell *matCellDef="let element"> <img *ngFor="let image of getPart(element.part_id).images" src="{{image}}" /> </td>
        </ng-container>
        -->
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantité </th>
            <td mat-cell *matCellDef="let element; let i = index">{{element.quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="controls">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element; let i = index"> <button mat-icon-button color="primary" (click)="removePart(i)">
              <mat-icon>delete</mat-icon>
          </button> </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    </table>


</div>

