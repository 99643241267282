import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { from } from 'rxjs';

@Component({
  selector: 'new-part-modal',
  templateUrl: './new-part-modal.component.html',
  styleUrls: ['./new-part-modal.component.css']
})
export class NewPartModalComponent implements OnInit {

  @Input() part = null;

  model : any = false;

  constructor(private api:APIDatasService, public dialogRef: MatDialogRef<NewPartModalComponent>) { }

  closeDialog(data) {
    this.clear()
    this.dialogRef.close(data);
  }

  upsertPart(event){
    let observable = from([]);
    if(this.part == null){
      observable = this.api.create("/interventions/parts",this.model)
    }else{
      observable = this.api.update(`/interventions/parts/${this.part._id}`,this.model)
    }
    observable.subscribe({
      next: (d) => {
        this.clear()
        this.closeDialog(d)
      }
    });
  }

  setImages(urls){
    this.model.images = urls;
  }

  ngOnInit() {
    if(this.part == null){
      this.model = this.createEmptyModel()
    }else{
      this.model = this.part
    }
  }

  clear(){
    this.model = this.createEmptyModel()
  }

  deleteImage(filename){
    if(this.part != null)
      this.api.delete(`/parts/${this.part}/images`,filename)
  }

  createEmptyModel(){
    return {
      name: '',
      code: '',
      type: '',
      image: '',
      price: ''
    }
  }
}
