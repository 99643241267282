import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.BASE_URL;
export interface PaginatedResponse {
  data: any[];
  links: {
    first: string;
    last: string;
    prev: null|string;
    next: null|string;
  };
  meta: {
    current_page: number;
    to: number;
    total: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class APIDatasService {


  constructor(private httpcli: HttpClient, private authsvc: AuthService) { }

  /**
   * author : R. Fournier
   * goal   : Generic function to recover data from the API
   *
   * parameters : route: the value to pass to the API in the URL
   */
  getUrl(url) : string{
    if(!url.startsWith('/')){
      url = '/'+ url;
    }
    return (BASE_URL + url);
  }
  prepareUrl(...parts){
    const url = parts.join('/');
    return this.getUrl(url);
  }
  getDatas(route, options = {}): Observable<any> {
    const token = this.authsvc.getToken();

    return this.httpcli.get(this.prepareUrl(route), options);
  }
  create<T = any>(route, data): Observable<any>{
    return this.httpcli.post<T>(this.prepareUrl(route), data);

  }
  update(route, data):Observable<any>{
    return this.httpcli.patch(this.prepareUrl(route), data);
  }

  delete(route, id:string):Observable<any>{
    return this.httpcli.delete(this.prepareUrl(route,id));
  }
}
