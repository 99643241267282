import { Component, OnInit } from '@angular/core';
import { Item } from 'src/app/models/Item';
import { ActivatedRoute } from '@angular/router';
import { APIDatasService } from 'src/app/services/apidatas.service';

@Component({
  selector: 'app-item-edit-view',
  templateUrl: './item-edit-view.component.html',
  styleUrls: ['./item-edit-view.component.css']
})
export class ItemEditViewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private api : APIDatasService) { }

  id: string;
  private sub: any;
  item : Item;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.api.getDatas("interventions/item_types/" + this.id).subscribe(
        res => {
          this.item = new Item();
          this.item = Item.build(res);
        },
        err => {
          console.error("Error finding item id : "+ this.id);
        }
      );
   });
  }

}
