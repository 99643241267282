import { _itemType } from './DB';
import { Target } from './Target';
import { Location } from './Location';
import { TargetType } from './TargetType';

export class Item implements _itemType{

    _id?           : string;
    code?          : string;
    name           : string;
    locations      : Location[];
    types          : TargetType[];
    description?   : string;
    updated_at?    : string;
    created_at?    : string;

    constructor(){
        this.name       = '';
        this.locations  = [];
        this.types    = [];
        this.description = '';
    }

    static build(data: any) : _itemType{
        const m = new Item();

        m._id           = data._id;
        m.code          = data.code;
        m.name          = data.name;
        m.locations     = data.locations;
        m.types         = data.types;
        m.description   = data.description;
        return m;
    }

}
