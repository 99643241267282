import { EMPTY } from 'rxjs';
import {expand, finalize, map, scan, shareReplay, takeLast, tap} from 'rxjs/operators';
import { APIDatasService, PaginatedResponse } from 'src/app/services/apidatas.service';

interface HttpParams {
  paginate: string;
  per_page: string;
  page: string;
  include?: string | string[];
}

const DEFAULT_PAGE = 1;

export class PaginatedRequest {
  currentPage = 0;

  constructor(
    private base_route: string,
    private httpOptions: {[key: string]: string} = { },
    private buildRow = (x) => x) { }
  fetch(api: APIDatasService) {
    this.httpOptions.paginate = 'true';

    const _fetch = (nextPage = DEFAULT_PAGE, perPage = 5) => {

      if (this.currentPage++ === nextPage) {
        return EMPTY;
      }
      const params = { ...this.httpOptions };
      params.per_page = perPage.toString();
      params.page = nextPage.toString();
      return api.getDatas(this.base_route, { params }).pipe(
        takeLast(1),
        shareReplay(1),
        finalize(() => this.currentPage = DEFAULT_PAGE )
      );
    };
    return _fetch().pipe(
      expand((res: PaginatedResponse) => {

        if (res.meta.to !== res.meta.total && res.meta.total > 0) {
          return _fetch( res.meta.current_page + 1 );
        }
        return EMPTY;
      }),
      map( (res: PaginatedResponse) => res.data.map(this.buildRow)),
      scan( (acc, v) => acc.concat(v), [] ),
    );
  }
}
