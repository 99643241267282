import { Component, OnInit } from '@angular/core';
import { AbstractListViewComponent } from '../abstract-list-view/abstract-list-view.component';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { Part } from 'src/app/components/interventions-form/parts-form/services/part';

@Component({
  selector: 'app-parts-view',
  templateUrl: './parts-view.component.html',
  styleUrls: ['./parts-view.component.css']
})
export class PartsViewComponent extends AbstractListViewComponent implements OnInit {

  currentPart : Part;

  constructor(protected api : APIDatasService) {
    super(api);
    this.listingOptions  =  {
      "custom_edit_action" : (part) => {this.currentPart = Part.build(part)},
      "type_options"  : {"base_route" : "interventions/parts", "type_name" : "Pièce"},
      "table_options" : [
        {"label" : "Code", "property_name" : "code"},
        {"label" : "Nom", "property_name" : "name"}
      ]
    };
    this.currentPart = null;
   }

   createOneDataRecord(index, array){
    var tmp = array[index];
    tmp["location"] = tmp['zip'] + " " + tmp["city"];
    return tmp;
  }
}
