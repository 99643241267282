import { Component, OnInit, HostBinding } from '@angular/core';
import { MatOptgroup } from '@angular/material/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-mat-optgroup',
  templateUrl: './mat-optgroup.component.html',
  styleUrls: ['./mat-optgroup.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(270deg)'})),
      state('expanded', style({transform: 'rotate(360deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
})
export class MatOptgroupComponent extends MatOptgroup {

  expanded : boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded;

  constructor() {
    super()
   }

  ngOnInit() {
    this.expanded = true;
    this.ariaExpanded = this.expanded;
  }

  onItemSelected() {
      this.expanded = !this.expanded;
      this.ariaExpanded = this.expanded;
  }
}
