import { Component, OnInit, EventEmitter, Output, Input, AfterViewInit } from '@angular/core';
import { TargetType } from 'src/app/models/TargetType';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'target-form',
  templateUrl: './target-form.component.html',
  styleUrls: ['./target-form.component.css'],
  exportAs: 'ngForm'
})
export class TargetFormComponent implements OnInit, AfterViewInit {

  private _types : TargetType[];

  tEditings : boolean[] = [];
  
  @Output() typesChange = new EventEmitter<TargetType[]>();
  isExpanded : boolean;
  expandedID : number;
  controls: FormArray;

  @Input()
    get types(){
      return this._types;
    }

    set types(t : TargetType[]){
      this._types = t;
      this.typesChange.emit(this._types);
      this.updateControls();
    }
  constructor(private api : APIDatasService) {
    this.expandedID = 0;
   }

  ngOnInit() {
    if(!this._types || !this._types.length){
      var tmp = []
      this.api.getDatas('interventions/types').subscribe(
        res => {
          if(res && res.length){
            tmp = res.map(
              (type) => ({
                "_id"     : type._id,
                "name"    : type.name,
                "code"    : type.code,
                "targets" : []
              })
            )
            this.types = tmp;
            this.updateControls();
          }
        }
      )
    }
  }

  ngAfterViewInit(){
    this.updateControls()
  }

  expandAction(index){
    if(this.expandedID != index){
      this.expandedID = index;
      this.isExpanded = true;
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  addType(pseudoTyp){
    var tmp = new TargetType();
    tmp.name = pseudoTyp.name;
    tmp.code = pseudoTyp.code;
    tmp.targets = [];
    this.types.push(tmp);
    this.updateControls();
  }


  removeType(i){
    this.types = new Array<TargetType>().concat(this.types.filter((v, index) => {
      return index != i;
    }));
  }


  getControl(index, fieldName) {
    const a  = this.controls.at(index).get(fieldName) as FormControl;
    return this.controls.at(index).get(fieldName) as FormControl;
  }

  updateControls(){
    const toGroups = this.types.map(entity => {
      return new FormGroup({
        code:  new FormControl(entity.code, Validators.required),
        name: new FormControl(entity.name, Validators.required),
       },{updateOn: "blur"});
    });

    this.controls = new FormArray(toGroups);
  }

  checkControls(index) : boolean {
    const controls = [this.getControl(index, "code"),this.getControl(index, "name")];
    return (controls[0].valid && controls[1].valid)
  }

  updateTyp(index) {
    const controls = [this.getControl(index, "code"),this.getControl(index, "name")];
    if (controls[0].valid && controls[1].valid) {
      this.types[index]['code'] = controls[0].value;
      this.types[index]['name'] = controls[1].value;
      this.types = new Array<TargetType>().concat(this.types);
    }
  }
}
