<div *ngFor="let ch of arr; let j = index;" class="child-line">
    <div class="fields">
        <editable (update)="updateChild(j)" [toggled]="tEditings[j] == true" [disableClickEvent]="true">
            <ng-template viewMode>
                <div class="editable-value">{{ch.code}}</div>
                <div class="editable-value">{{ch.name}}</div>
                <div class="editable-value">{{ch.description}}</div>
            </ng-template>
            <ng-template editMode>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Code" [formControl]="getControl(j, 'code')">
                    <mat-error *ngIf="getControl(j, 'code').hasError('required')">
                        Field is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Nom" [formControl]="getControl(j, 'name')">
                    <mat-error *ngIf="getControl(j, 'name').hasError('required')">
                        Field is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Description" [formControl]="getControl(j, 'description')">
                    <mat-error *ngIf="getControl(j, 'description').hasError('required')">
                        Field is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </ng-template>
        </editable>
    </div>
    <div class="actions"> 
        <button mat-icon-button color="primary" (click)="tEditings[j] = !tEditings[j];">
            <mat-icon *ngIf="tEditings[j] != true" >edit</mat-icon>
            <mat-icon *ngIf="tEditings[j]" >done</mat-icon>
        </button>
        <button *ngIf="tEditings[j] != true" mat-icon-button color="primary" (click)="removeChild(j)">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>
<form ngForm #addChildForm="ngForm">
    <mat-form-field>
        <mat-label>Code</mat-label>
        <input matInput placeholder="Code" name="code" required ngModel>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Nom</mat-label>
        <input matInput placeholder="Nom" name="name" required ngModel>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" name="description" required ngModel>
    </mat-form-field>
    <button mat-raised-button [disabled]="addChildForm.invalid" color="primary" (click)="addChild(addChildForm)">Ajouter</button>
</form>