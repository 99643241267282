import { _location } from './DB';

export class Location implements _location{

    _id?           : string;
    name           : string;
    code?          : string;
    item_type_id?  : string;
    parent_id?     : string;
    children?      : _location[];
    description?   : string;
    updated_at?    : string;
    created_at?    : string;

    constructor(){
        this.name       = '';
        this.code       = '';
        this.description = '';
    }

    static build(data) : _location{
        const m = new Location();

        m._id           = data._id;
        m.code          = (data.code?data.code:'');
        m.name          = data.name;
        m.item_type_id  = data.item_type_id;
        m.parent_id     = data.parent_id;
        m.children      = data.children;
        return m;
    }

}
