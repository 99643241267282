<!--The content below is only a placeholder and can be replaced.-->
<div class="main-container" [class.responsive]="mq.isMobile()">
  <mat-toolbar color="primary" class="main-toolbar" *ngIf="isAuthed">
    <button mat-icon-button (click)="toggleSideNav()">
      <mat-icon>menu</mat-icon>
    </button>

    <h1 class="app-name">Suarato App</h1>
    <mat-menu #accountMenu="matMenu">
        <button mat-menu-item (click)="this.authsvc.logOut(); this.navService.closeNav();" >Déconnexion</button>
    </mat-menu>
    <button style="position : absolute; right : 20px;" mat-icon-button [matMenuTriggerFor]="accountMenu">
        <mat-icon>account_box</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container class="main-sidenav-container"
    [style.marginTop.px]="mq.isMobile() ? 56 : 0">
    <mat-sidenav #sidenav [mode]="mq.isBelowBreakPoint() ? 'over' : 'side'" [(opened)]="opened" (opened)="events.push('open!')"
      [fixedInViewport]="mq.isNotPC()" fixedTopGap="56" (closed)="events.push('close!')">
      <app-side-navbar-menu style="    overflow-x: hidden;"></app-side-navbar-menu>
    </mat-sidenav>
    <mat-sidenav-content>
        <div *ngIf="this.loading" class="loader">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <router-outlet [hidden]="this.loading"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

