import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Event, NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  public sidenav: any;
  public currentUrl = new BehaviorSubject<string>(undefined);
  private changedMenuStatus = new BehaviorSubject<boolean>(false);
  
  get onChangedMenu$(): Observable<Boolean> {
    return this.changedMenuStatus.asObservable();
  }


  constructor(private router: Router) {
    this.changedMenuStatus = new BehaviorSubject<boolean>(true);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public reduceAllItems(){

  }

  public closeNav() {
    this.sidenav.close();
    this.changedMenuStatus.next(false);
  }

  public openNav() {
    this.sidenav.open();
    this.changedMenuStatus.next(true);
  }
}
