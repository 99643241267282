<h2>Bons de Commande</h2>
<p *ngIf="orders.length">Merci de choisir le bon de commande pour créer le rapport d'intervention</p>
<div *ngIf="finishedLoading">
    <div *ngFor="let day of ordered; let j = index;">
        <h3>{{day.label}}</h3>
        <p *ngIf="!(day.orders && day.orders.length)">Il n'y plus de bons de commande pour cette période</p>

        <mat-grid-list class="listOrders" [cols]="responsiveBp" gutterSize="20px" (window:resize)="onResize($event)">
            <mat-grid-tile *ngFor="let order of day.orders; let i = index;" (click)="onClickOrder(order._id)">
                <mat-grid-tile-header [ngClass]="{'attributed': order.attributed}">
                    <span style="font-size: 18px">CC: {{order.code_confirmation}}</span>
                </mat-grid-tile-header>
                <mat-icon class="material-icons-outlined trs-bg-icon" style="color : #DDD; font-size : 40px">assignment
                </mat-icon>
                <div class="card-grid-content">
                    <!--><p>Appartement {{order.intervention_building.floor == 0? "RDC" : order.intervention_building.floor }}<sup>{{ 
                                        (order.intervention_building.floor != 0? (order.intervention_building.floor != 1? "ème": "er") : '') 
                                    }}</sup> {{(order.intervention_building.floor != 0? "étage": "")}}</p>-->
                    <p>
                        <strong>{{order.intervention_building.tenant.name}}</strong><br />
                        <span style="font-size: 15px;">{{order.intervention_building.address}}<br />
                            {{order.intervention_building.zip}} {{order.intervention_building.city}}</span></p>
                </div>
                <mat-grid-tile-footer style="    display: block;">
                    <div class="bottom-card" style="display: flex; padding-top: 3px; margin-right: -15px;">
                        <div style="width : 100%;">
                            <div style="width : 100%;">N° : {{order.code}}</div>
                            <div>Date :{{getFormattedDate(order.planned_date)}}</div>
                        </div>
                        <div *ngIf="order.management_voucher && order.management_voucher.length">
                            <button mat-icon-button (click)="openVoucher($event, order)">
                                <mat-icon>description</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-grid-tile-footer>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>