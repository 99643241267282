import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

import { PartInterface, PartListInterface } from './part';

@Injectable({
  providedIn: 'root'
})
export class PartDataService {

  list: PartListInterface[] = [
    
  ];
  list$: BehaviorSubject<PartListInterface[]> = new BehaviorSubject(this.list);

  constructor() {
  }


  update(index, field, value) {
    this.list = this.list.map((e, i) => {
      if (index === i) {
        return {
          ...e,
          [field]: value
        }
      }
      return e;
    });
    this.list$.next(this.list);
  }
  add(data){
    this.list = this.list.concat([data])
    this.list$.next(this.list)
  }
  delete(index){
    this.list = this.list.filter( (v, index_) => {
      return index != index_
    })
    this.list$.next(this.list)
  }

}