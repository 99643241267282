import { _target, _interventionType } from './DB';

export class TargetType implements _interventionType{

    _id?           : string;
    name           : string;
    code           : string;
    item_type_id?  : string;
    targets?       : _target[];

    constructor(){
        this.name           = '';
        this.code           = '';
    }

    static build(data) : _interventionType{
        const m = new TargetType();

        m._id           = data._id;
        m.code          = (data.code?data.code:'');
        m.name          = data.name;
        m.item_type_id  = data.item_type_id;
        m.targets       = data.targets;
        return m;
    }

}
