<div class="image-uploader">
    <div class="preview-container">
        <div class="preview" *ngFor="let file of this.files ;index as i">
            <div class="icon container">
                <mat-icon>description</mat-icon>
            </div>
            <span class="file-meta">
                {{file.name}} ({{getFileSizeStr(file.size)}})
            </span>
            <button mat-icon-button color="primary" (click)="removeUpload(i)" class="delete">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div *ngIf="this.uploading" class="preview uploading">
            <trs-spinner [elem]="'img-upload'" [isring]="true" style="float: left; margin-bottom: -10px; margin-top: 5px;"></trs-spinner>
        </div>

    </div>
    <input  #file hidden id="image-input" name="image-input" type="file" accept="application/pdf" (change)="onSelectFile($event)" [multiple]="!(this.limit == 1)" />

    <button class="upload" color="primary" mat-raised-button (click)="file.click()" [disabled]="disabled()">
        <span style="margin: 15px;">Ajouter le PDF</span>
        <mat-icon>description</mat-icon>
    </button>
</div>
