export function downloadUrl(url){
  /*var link = document.createElement("a");
        link.download = "Document interne";
        link.target="_blank"
        link.href = this.api.getUrl(`/interventions/${this.inter._id}/invoice`);
        link.click();*/
  window.open(url, '_blank');
}
export function downloadUrlInSameTab(url, name = 'Téléchargement'){
  const link = document.createElement("a");
  link.download = name;
  link.target="_parent"
  link.href = url;
  link.click();
}
