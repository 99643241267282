import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginFormComponent } from 'src/app/components/login-form/login-form.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AuthResponse } from '../../models/authresponse'

@Component({
  selector: 'app-auth-view',
  templateUrl: './auth-view.component.html',
  styleUrls: ['./auth-view.component.css']
})
export class AuthViewComponent implements OnInit {

  @ViewChild(LoginFormComponent) loginForm: LoginFormComponent;

  constructor(private authsvc: AuthService, private router: Router) { }

  ngOnInit() {
  }

  /**
   * author : R. Fournier
   * goal   : Send a error message to the user
   *
   * parameters : msg: message to show to the user
   */
  sendErrorMsg(msg: string) {
    this.loginForm.showMessage('Erreur de connexion : ' + msg);
  }

  /**
   * author : R. Fournier
   * goal   : Call the functions to sign in the user
   *
   * parameters : event: object with the username and the password
   */
  doSignIn(event) {
    this.authsvc.signIn(event, this).catch(e => {
      this.sendErrorMsg(e);
    })
      .then(res => {
          //successful login
        this.authsvc.setAuth(true);
        this.authsvc.setToken(res);
        // We redirect to / if the user is authenticated
        if (this.authsvc.isAuth()) {
          this.router.navigate(['']);
        }
      });
  }

}
