import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { _itemType, _interventionType, _location, _action, _target, _intervention, Intervention, _distance } from 'src/app/models/DB';
import { InterFormComponent } from 'src/app/components/interventions-form/inter-form-items/inter-form-items.component';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { NotificationService, Notification } from 'src/app/services/notification.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { OrderContract, Order } from 'src/app/models/order';
import { InterventionPartInterface } from './parts-form/services/part';
import { STEP_STATE } from '@angular/cdk/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper'
import { MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';



@Component({
  selector: 'app-interventions-form',
  templateUrl: './interventions-form.component.html',
  styleUrls: ['./interventions-form.component.css'],
  providers: [{
    provide: MAT_STEPPER_GLOBAL_OPTIONS, useValue: { showError: true, errorMessage: true }
  }]
})
export class InterventionsFormComponent implements OnInit {


  /* Child components */
  @ViewChild('tasks', { static: true }) tasks;
  @ViewChild('parts', { static: true }) parts;
  @ViewChild('hourLogs', { static: true }) hourLogs;
  @ViewChild('distance') distance;
  @ViewChild('before_images', { static: true }) before_images;
  @ViewChild('after_images', { static: true }) after_images;
  @ViewChild('cliSignPad', { static: true }) cliSignPad;
  @ViewChild('workerSignPad', { static: true }) workerSignPad;
  @ViewChild('remarks', { static: true }) remarks;

  @Input() edit: boolean;
  @Output() orderNo = new EventEmitter<String>();

  tmp = moment();

  intervention: Intervention;
  order: OrderContract;
  cardSelected: boolean;

  sending : boolean;

  /* Added by LFavre 15.02.22 */
  noSignatureNeeded = false;

  private backuptasks : any[];

  constructor(private formBuilder: FormBuilder, private api: APIDatasService, private router: Router,
    private notifs: NotificationService, private cd: ChangeDetectorRef) {
    this.intervention = new Intervention();
    this.sending = false;
    this.backuptasks = [];
  }

  ngOnInit() {
    this.cardSelected = false;
    if (!this.order)
      this.order = new Order();
    else{
      this.orderNo.next(this.order.code);
    }
  }

  resetForm() {
    this.cd.detectChanges();
    var tmp = new Intervention();
    if(tmp.status!="impossible" && this.tasks)
      this.tasks.setData(tmp.tasks);
    this.hourLogs.setData(tmp.hour_log);
    this.before_images.setData(tmp.before_images);
    this.after_images.setData(tmp.after_images);
    this.parts.setData({ spare_parts: tmp.spare_parts, ordered_parts: tmp.ordered_parts });
    this.cliSignPad.setData(tmp.signatures.client);
    this.workerSignPad.setData(tmp.signatures.employee);
    this.cardSelected = false;
    if(this.distance)
      this.distance.setData(tmp.distance);
  }

  ngAfterViewInit() {

  }

  selectOrder(od: OrderContract) {
    this.order = od;
    this.orderNo.next(this.order.code);
    this.cardSelected = true;
    this.intervention.order_id = od._id;
  }

  onCancelClick() {
    if (!this.edit)
      this.resetForm();

    this.router.navigateByUrl('/interventions');
  }

  onTaskListChange(list) {
    this.intervention.tasks = list;
  }

  onHourLogChange(list) {
    this.intervention.hour_log = list.map( (elem)=> {
      elem.date = moment(elem.date).format();
      return elem
    });
  }

  onDistanceChange(dist: _distance) {
    this.intervention.distance = dist;
  }

  onImageListChanges(list, key) {
    this.intervention[key] = list;
  }

  onPartChange(part_lists: { spare_parts: InterventionPartInterface[], ordered_parts: InterventionPartInterface[] }) {
    this.intervention.ordered_parts = part_lists.ordered_parts;
    this.intervention.spare_parts = part_lists.spare_parts;
  }

  onClientSignUpdate(sign) {
    this.intervention.signatures.client = sign;
    this.intervention.date_end = moment();
  }



  onWorkerSignUpdate(sign) {
    this.intervention.signatures.employee = sign;
  }
  onRemarksChange(remarks) {
    this.intervention.remarks = remarks;
    if(this.intervention.remarks.work.selected.length > 0 && this.intervention.remarks.work.selected.includes("Constat uniquement")){
      this.intervention.status = "impossible";
      this.backuptasks = [...this.intervention.tasks];
      this.intervention.tasks = [];
    } else{
      this.intervention.status = "draft"
      this.intervention.auto_eval = "";
      this.tasks = [...this.backuptasks];
    }
  }

  changedStatusInForm(status){
    if(!(this.intervention.status === "impossible")){
      this.intervention.status = status;
    }
  }

  logRemarks() {

  }
  valid(step): boolean {
    const controls = {
      'task-list': () => { return (this.intervention.status=="impossible" ? true : this.intervention.tasks.length)},
      'remarks': () => { return this.intervention.remarks && this.intervention.remarks.cause && this.intervention.remarks.cause.selected && this.intervention.remarks.cause.selected.length },
      'parts': () => { return true },
      'hourlog-km': () => { return this.intervention.hour_log.length },
      'before-pics': () => { return true },
      'after-pics': () => { return true },
      'last-step': () => {
        if (this.intervention.status == "waiting_validation")
          return (
            this.intervention.signatures && this.intervention.signatures.client
            && this.intervention.signatures.employee &&
            this.intervention.signatures.client != "" && this.intervention.signatures.employee != ""
            && (this.intervention.auto_eval && this.intervention.auto_eval != '')
          );
        else if(this.intervention.status == "finished"){
          return (this.intervention.auto_eval && this.intervention.auto_eval != '');
        } else if (this.intervention.status == "impossible"){
          return this.intervention.auto_eval != "";
        }
        else {
          return true;
        }
      }
    }
    return controls[step]()
  }
  errorMessage(step) {
    const messages = {
      'task-list': 'Vous devez avoir mis au moins une '
    }
    return messages[step] || this.valid(step) ? "" : "Il y a une erreur";
  }
  getStepState(step) {
    if (this.valid(step)) {

      return STEP_STATE.DONE
    }
    return STEP_STATE.ERROR

  }
  saveIntervention() {
    if(!this.sending){
      this.sending = true;
      let observable = from([]);
      if (this.intervention._id != "") {
        observable = this.api.update(`interventions/${this.intervention._id}`, this.intervention.toData())
      } else {
        observable = this.api.create("interventions", this.intervention.toData())
      }
      observable.subscribe(
        res => {

        },
        error => {
          this.notifs.error(new Notification(error.error.message));
        },
        () => {
          this.sending = false;
          var message = "Intervention " + ((this.intervention._id != "") ? "mise à jour" : "enregistrée") + " avec succès"
          this.notifs.notifyandredirect(new Notification(message), 'interventions')
        });
    }
  }
  deleteImageFromIntervention(filename) {
    this.api.delete(`interventions/${this.intervention._id}/images`, filename).subscribe()
  }

  downloadReport() {
    var link = document.createElement("a");
    link.download = "Document interne";
    link.target = "_blank"
    link.href = this.api.getUrl(`/interventions/${this.intervention._id}/all_pdfs`);
    link.click();
  }

  openVoucher() {
    window.open(this.api.getUrl(`/orders/${this.order._id}/management_voucher`), '_blank');
  }

  loadIntervention(inter: Intervention) {
    //this.resetForm()
    this.order = inter.order;
    this.intervention = inter;
    this.orderNo.next(this.order.code);

    if (this.intervention.isValid()) {
      this.cd.detectChanges();

      this.cardSelected = true;
      //send datas to child components because we're editing
      if(this.intervention.status!="impossible")
        this.tasks.setData(this.intervention.tasks);
      this.hourLogs.setData(this.intervention.hour_log);
      this.before_images.setData(this.intervention.before_images);
      this.after_images.setData(this.intervention.after_images);
      this.parts.setData({ spare_parts: this.intervention.spare_parts.map(part => ({ part_id: part.part_id, quantity: part.quantity })), ordered_parts: this.intervention.ordered_parts.map(part => ({ part_id: part.part_id, quantity: part.quantity })) });
      this.noSignatureNeeded = (this.intervention.signatures.client == "absent" || this.intervention.signatures.client == "vacant");
      if(!this.noSignatureNeeded){
        this.cliSignPad.setData(this.intervention.signatures.client);
      }
      this.workerSignPad.setData(this.intervention.signatures.employee);
      this.remarks.setData(this.intervention.remarks);
      this.distance.setData(this.intervention.distance);
    } else {
      console.error("Intervention not valid", this.intervention);
    }
  }

  selectedStep = 0;
  stepperselected($event) {
    this.selectedStep = $event.selectedIndex;
  }
}
