<!--<h2 mat-dialog-title>Nouvelle pièce</h2>-->
<mat-dialog-content>
    <part-form-addedit [part]="part" (dataSent)="this.closeDialog($event)" (close)="closeDialog(null)"></part-form-addedit>
    <form #form>
        <!--<mat-form-field>
            <mat-label>Pièce</mat-label>
            <input required matInput type="text" name="name" [(ngModel)]="model.name">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Code</mat-label>
            <input required matInput type="text" name="code" [(ngModel)]="model.code">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Prix</mat-label>
            <input required matInput type="text" name="price" [(ngModel)]="model.price">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Type de pièce</mat-label>
            <input required matInput type="text" name="type" [(ngModel)]="model.type">
        </mat-form-field>
        <div>
            <mat-label>Image</mat-label>
            <image-uploader (imageDeleted)="deleteImage($event)" (imageListChange)="setImages($event)"></image-uploader>
        </div>-->
    </form>
</mat-dialog-content>
<!--<mat-dialog-actions>-->
<!--  &lt;!&ndash;<button mat-button [mat-dialog-close]="false">Annuler</button>&ndash;&gt;-->
<!--  &lt;!&ndash; The mat-dialog-close directive optionally accepts a value as a result for the dialog. &ndash;&gt;-->
<!--  &lt;!&ndash;<button mat-button (click)="upsertPart($event)">{{part? "Editer" : "Créer"}}</button>&ndash;&gt;-->
<!--</mat-dialog-actions>-->
