import {  } from '@agm/core';

export interface mapMarkerInterface {
	lat: number;
	lng: number;
	label?: string;
	draggable?: boolean;
}

export class MapMarker implements mapMarkerInterface{
	lat;
	lng;
	label?;
	draggable?;

	static build(data) : mapMarkerInterface{
		const m = new MapMarker();
		m.lat = data.lat;
		m.lng = data.lng;
		m.label = data.label;
		m.draggable = data.draggable;

		return m;
	}
	static buildEmptyModel() : mapMarkerInterface {
		return MapMarker.build({
			lat: 0,
			lng: 0
        });
	}
}
