import { Injectable, ApplicationRef  } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class MediaqueriesService {
  mobileQuery: MediaQueryList;
  tabQuery : MediaQueryList;
  breakpointQuery : MediaQueryList;

  private _mobileQueryListener: () => void;
  private _tabQueryListener: () => void;
  private _breakpointQueryListener: () => void;

  constructor(private ar: ApplicationRef , private media: MediaMatcher ) { 
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.tabQuery = media.matchMedia('(max-width: 980px)');
    this.breakpointQuery = media.matchMedia('(max-width: 1400px)');

    this._mobileQueryListener = () => this.ar.tick();

    this._tabQueryListener = () => this.ar.tick();

    this._breakpointQueryListener = () => this.ar.tick();
  }

  isMobile():boolean{
    return this.mobileQuery.matches;
  }

  isTablet():boolean{
    return (this.tabQuery.matches && !this.isMobile());
  }

  isNotPC():boolean{
    return this.tabQuery.matches;
  }

  isBelowBreakPoint():boolean{
    return this.breakpointQuery.matches;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.tabQuery.removeListener(this._tabQueryListener);
    this.breakpointQuery.removeListener(this._breakpointQueryListener);
  }
}
 