import { InterventionContract } from './Intervention';
import {HourLogInterface} from './HourLog';
import { RemarkContract, Remark } from './remarks';

export interface ReportContract {
    intervention_id: string;
    header: string;
    address: string;
    operations: string[];
    costs: ReportLine[];
    remarks: RemarkContract;
    hourly_cost: HourlyRate[];
    distance_cost: DistanceRate;
    administrative_cost: number;
    administrative_cost_text: string;

}
interface ReportLine {
    description: string;
    quantity: number;
    price: number;
    total: number;
}
class ReportPart implements ReportLine {
  price: number;
  quantity: number;
  description: string;
  get total() {
    return this.quantity * this.price;
  }
  static build(d) {
    const m = new ReportPart();
    m.price = d.price || 0;
    m.quantity = d.quantity || 0;
    m.description = d.description || '';
    return m;
  }
  toJSON() {
    return {
      ...this,
      total: this.total
    };
  }
}
class HourlyRate {
  hours: number;
  price: number;
  text: string;

  get quantity(){
    return this.hours;
  }

  get total() {
    return this.hours * this.price;
  }
  static build(d) {
    const m = new HourlyRate();
    m.text  = d.text || '';
    m.price = d.price || 0;
    m.hours = d.hours || 0;
    return m;
  }
  toJSON() {
    return {
      ...this,
      total: this.total
    };
  }
}
class DistanceRate {
  distance: number;
  price: number;
  time: {
    value?: number;
    price?: number;
  };

  get total() {
    const { time, distance, price } = this;
    let total = distance * price ; // ternaire utile si problème geocode api
    if(typeof time.value !== 'undefined' && time.value >= 0 && typeof time.price !== 'undefined' && time.price > 0) {
      total += (time.value * time.price);
    }
    return total;
  }
  static build(d) {
    const m = new DistanceRate();
    m.price = d.price;
    m.distance = d.distance;
    m.time = d.time || {
      value : 0,
      price : 0,
      unite : 'min.'
    };
    return m;
  }
  toJSON() {
    return {
      ...this,
      total: this.total
    };
  }
}
export class Report implements ReportContract {

    intervention_id: string;
    header: string;
    address: string;
    remarks: RemarkContract;
    operations: string[];
    costs: ReportLine[];
    hourly_cost: HourlyRate[];
    distance_cost: DistanceRate;
    administrative_cost: number;
    administrative_cost_text: string;

    static buildEmpty() {
        return Report.build({
            intervention_id : '',
            header          : '',
            address         : '',
            operations      : [],
            costs           : [],
            remarks         : Remark.buildEmpty(),
            hourly_cost: [HourlyRate.build({
              text: ``,
              price: 0,
              hours: 0
            })],
            distance_cost: DistanceRate.build({
              distance: 0,
              hours: 0,
              time : {
                value : 0,
                unit  : 'min.',
                price : 0
              }
            }),
            administrative_cost: 0
        });
    }

    static build(d) {
        const m = new Report();
        m.intervention_id     = d.intervention_id || '';
        m.header              = d.header || '';
        m.address             = d.address || '';
        m.remarks             = d.remarks || Remark.buildEmpty();
        m.operations          = d.operations || [];
        m.costs               = (d.costs || []).map( (c) => ReportPart.build(c));
        m.hourly_cost         = d.hourly_cost.map(h => HourlyRate.build(h)) || [];
        m.distance_cost       = DistanceRate.build(d.distance_cost);
        m.administrative_cost = d.administrative_cost;
        m.administrative_cost_text = d.administrative_cost_text || 'Forfait administratif';
        return m;
    }

    static createFromIntervention(inter: InterventionContract) {
        if (inter.invoice.intervention_id !== '') {
          return Report.build(inter.invoice);
        }
        const r = Report.buildEmpty();

        r.remarks = inter.remarks;

        r.intervention_id = inter._id;
        const d = new Date();
        r.header          =  ' \
                                <h3>Confirmation de commande N° ' + inter.order.code + '</h3> \
                                <div> \
                                    <p>Date : ' + d.getDay() + '/' + d.getMonth() + '/' + d.getFullYear() + '</p> \
                                    <p>            \
                                        N / Référence: ' + inter.order.code_confirmation + '<br/> \
                                        Objet : ' + inter.order.intervention_building.address + ' à ' +
                                        inter.order.intervention_building.zip + ' ' + inter.order.intervention_building.city + '<br/> \
                                        Concerne : \
                                    </p> \
                                </div>';
        r.address       =    ' \
        <p> ' +
            inter.order.client.name     + '<br/>' +
            inter.order.client.address  + '<br/>' +
            inter.order.client.zip + ' ' + inter.order.client.city +
        '</p>';

        r.operations = inter.tasks.map(x => x.description);
        r.costs = inter.spare_parts.map( p => ReportPart.build({
          description: p.part_name,
          quantity: p.quantity,
          price: p.price,
        })
        ).concat(
          inter.ordered_parts.map( p => ReportPart.build({
            description: p.part_name,
            quantity: p.quantity,
            price: p.price,
          }))
        ).concat([
          ReportPart.build({
            description: 'Petite fourniture',
            quantity: 1,
            price: 5.00,
          })
        ]);
        r.hourly_cost = inter.hour_log.map( (l: HourLogInterface) => {
          const date = new Date(l.date);
          return HourlyRate.build({text: `"Heures effectués de ${l.hour_start} à ${l.hour_end} le
                  ${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`, quantity: l.duration, price: 100});
        });
        r.distance_cost = DistanceRate.build({
          distance: (inter.distance.nb_trips * inter.distance.single_dist) / 1000,
          price: 100,
          time: {
            value : 1,
            'unit ' : 'min.',
            price : 1
          },
        });

        r.administrative_cost = 70.00;
        r.administrative_cost_text = 'Forfait administratif';

        return r;
    }

}
