import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor() { }
  //function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = request.headers;

    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    headers = headers.set('Accept', '*/*');

    const req = request.clone({
      headers: headers
    });

    return next.handle(req);
  }
}