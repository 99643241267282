import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import {MapsAPILoader } from '@agm/core'
import { find } from 'rxjs/operators';
import { _distance } from 'src/app/models/DB';
import {MapMarker} from 'src/app/models/mapMarker'
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-googlemaps-km',
  templateUrl: './googlemaps-km.component.html',
  styleUrls: ['./googlemaps-km.component.css']
})
export class GooglemapsKmComponent implements OnInit {

  @ViewChild('direction') direction;
  _destination : MapMarker;
  depart: MapMarker;

  @Input() set destinationCoords(val : MapMarker){

    if(val)
    this._destination = val
    this.updateDistance(this.depart, this.destinationCoords);
  }

  get destinationCoords(){return this._destination;}


  @Output() changeDist = new EventEmitter<_distance>();

  distance      : number;
  travelTime : number;
  nbTrajets : number;

  constructor( private maps : MapsAPILoader, public cd: ChangeDetectorRef, private global : SettingsService) {
    this.nbTrajets=1
    this.travelTime = 0;
  }

  ngOnInit() {
    this.depart = this.global.getSetting("coords").value
    this.updateDistance(this.depart, this.destinationCoords);
   }

  updatenbTrajets(){
    this.emitValues();
  }

  private emitValues(){
    this.changeDist.emit({
      "nb_trips"      : (this.nbTrajets ? this.nbTrajets : 0),
      "single_dist"   : this.distance,
      "single_traveltime" : this.travelTime
    })
  }

  setData( dist : _distance){
    console.log("Setting data", dist, this)
    //this.distance = dist.single_dist;
    this.nbTrajets = dist.nb_trips;
  }

  updateDistance(origin: MapMarker, dest: MapMarker) {
    this.maps.load().then(() => {
      new google.maps.DistanceMatrixService().getDistanceMatrix({'origins': [origin], 'destinations': [dest], travelMode: google.maps.TravelMode.DRIVING}, (results: any) => {
        console.log("Found Distance", results.rows[0].elements[0].distance.value)
        console.log("Found Travel time [m]", results.rows[0].elements[0].duration.value / 60)
        this.distance       = results.rows[0].elements[0].distance.value;
        this.travelTime  = results.rows[0].elements[0].duration.value / 60;
        this.emitValues();
      });
    })
  }

}


