import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { _itemType, _interventionType, _location, _action, _target, _interventionTask } from 'src/app/models/DB';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { from, forkJoin, of } from 'rxjs';
import { flatMap, single } from 'rxjs/operators';

@Component({
  selector: 'app-inter-form-items',
  templateUrl: './inter-form-items.component.html',
  styleUrls: ['./inter-form-items.component.css'],
  exportAs: 'ngForm'
})
export class InterFormComponent implements OnInit {
  @Output() tasksChange = new EventEmitter<_interventionTask[]>();

  items :  {
      values : {
          "cat" : string[],
          "loc" : string[],
          "type": string,
          "description": string
      },
      fields : {
        "cats" : _interventionType[],
        "locs" : _location[],
        "types": _itemType[]
      }
    }[];

  itemTypes     : _itemType[];
  categories    : _interventionType[];
  itemLocations : _location[];

  typeID          : string;
  selectedCatsIDs : string[];
  locationID      : string[];

  //added by L.Favre 15.02.22
  description   : string;

  constructor(private api : APIDatasService, private cd : ChangeDetectorRef) { }

  ngOnInit() {
    this.updItemTypes();
    this.items = [];
    this.itemLocations = [];
    this.categories = [];
    this.description = "";
  }

  onItemTypeSelection(){
    this.updPlaces(this.typeID);
    this.categories = [];
    if(this.typeID == "620ba2b91a6cba66a64aa3b5"){
      this.locationID = ["620ba2b91a6cba66a64aa3b6"];
      this.onItemLocationSelect();
      this.selectedCatsIDs = ["5d272ca8abbfd5591c1be202"];
    }
  }
  onItemLocationSelect(){
    this.updInterventionTypes(this.typeID);
  }

  private updItemTypes(){
    let tmpData = [];
    this.api.getDatas('interventions/item_types').subscribe(
      it => tmpData = it,
      e  => console.error(e),
      () => {
        this.itemTypes = tmpData;
      }
    );
  }

  test(index){
    this.cd.detectChanges()
    this.sendItems();
  }

  private updPlaces(id_itemtype : string){
    let tmpData = [];
    this.api.getDatas('interventions/item_types/'+id_itemtype+'/locations').subscribe(
      it =>{ this.itemLocations = it;},
      e  => console.error(e),
      () => {

      }
    );
  }
  private getPlaces(id_itemtype : string){
    return this.api.getDatas('interventions/item_types/'+id_itemtype+'/locations').toPromise();
  }
  private getTargets(id_itemtype : string){
    return this.api.getDatas('interventions/item_types/'+id_itemtype+'/types').toPromise();
  }

  private updInterventionTypes(id_itemtype : string){
    let tmpData = [];
    this.api.getDatas('interventions/item_types/'+id_itemtype+'/types').subscribe(
      it => tmpData = it,
      e  => console.error(e),
      () => {
        this.categories = tmpData;
      }
    );
  }
  private getInterventionTypes(id_itemtype : string){
    return this.api.getDatas('interventions/item_types/'+id_itemtype+'/types').toPromise();
  }

  setData(tasks : _interventionTask[]){
      from(tasks)
      .pipe(flatMap( t => forkJoin(this.getPlaces(t.type_id), this.getTargets(t.type_id), of(t)) ))
      .subscribe({
        next: responses => {
          let tmp = {
            values : {
              "cat" : responses[2].target_id,
              "loc" : responses[2].location_id,
              "type": responses[2].type_id,
              "description": responses[2].description? responses[2].description : ""
            },
            fields :{
              "cats" : responses[1],
              "locs" : responses[0],
              "types": this.itemTypes
            }
          }
          this.items.push(
            tmp
          )
      }
    });
  }

  addItem(form): void {

    if (this.items === null) {
      this.items = [
        {
          values : {
            "cat" : this.selectedCatsIDs,
            "loc" : this.locationID,
            "type": this.typeID,
            "description" : this.description
          },
          fields :{
            "cats" : this.categories,
            "locs" : this.itemLocations,
            "types": this.itemTypes
          }
        }
      ];
    } else {
      this.items.push(
        {
          values : {
            "cat" : this.selectedCatsIDs,
            "loc" : this.locationID,
            "type": this.typeID,
            "description" : this.description
          },
          fields :{
            "cats" : this.categories,
            "locs" : this.itemLocations,
            "types": this.itemTypes
          }
        });
      }


    this.sendItems();
    this.itemLocations = this.categories = [];
    this.description = "";
    form.reset();
    //form.resetForm();

  }

  removeItem(index: number){
    this.items = this.items.filter( (v, index_) => {
      return index != index_
    })
    this.sendItems();
  }

  sendItems(){
    this.tasksChange.emit(
      this.items.map(it =>
        ({
          "type_id"    : it.values.type,
          "location_id": it.values.loc,
          "target_id"  : it.values.cat,
          "description": it.values.description
        })
      )
    );
  }
}
