<div class="filter-box-container">
    <div class="filter-container">
        <ng-container *ngFor="let filter of filters">
            <div *ngIf="isFilterActive(filter); else filterNotAcitvated" class="filter filter-active">
                <button type="button"  mat-stroked-button [className]="getClassNameForFilter(filter)" (click)="toggleFilter(filter)">
                    {{ filter.name }}
                </button>
            </div>

            <ng-template #filterNotAcitvated>
                <div class="filter filter-inactive">
                    <button type="button" mat-raised-button [className]="getClassNameForFilter(filter)" (click)="toggleFilter(filter)">
                        {{ filter.name }}
                    </button>
                </div>
            </ng-template>


        </ng-container>
    </div>
</div>
