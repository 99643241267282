<div class="listing-actions">
    <div style="display:inline-block" >
        <button mat-icon-button color="primary" (click)="fullReload()" matTooltip="Rafraîchir la liste">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <mat-form-field *ngIf="!noSearch">
        <input matInput (keyup)="updateSearchTerms($event.target.value)" placeholder="Rechercher">
    </mat-form-field>
    <button mat-icon-button color="primary" (click)="startSearch()"  *ngIf="!noSearch">
        <mat-icon>search</mat-icon>
    </button>
</div>

<table #table mat-table [(dataSource)]="dataSource" matSort class="mat-elevation-z8" [matSortActive]="defSortCol"
    [matSortDirection]="defSortOrder">
    <ng-container *ngIf="options.showCheckbox" matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef> Attribué </th>
        <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [(ngModel)]="element.attributed" (change)="checkboxChange($event, element)"></mat-checkbox> 
        </td>
    </ng-container>
      
    <ng-container *ngFor="let opt of this.options.table_options; let i = index;" matColumnDef="{{opt.property_name}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{opt.label}} </th>
        <td mat-cell *matCellDef="let element" [ngClass]='[element.status ? "status-"+element.status:""]'>
            {{(opt.property_name && (["date_start","created_at","end_date"].indexOf(opt.property_name) != -1)) ?
                getFormattedDate(element[opt.property_name]) : element[opt.property_name]}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" [ngClass]='[element.status ? "status-"+element.status:""]'>

            <button *ngFor="let action of options.custom_actions || []" mat-icon-button color="primary"
                (click)="action.callback(element)">
                <mat-icon>{{action.icon}}</mat-icon>
            </button>
            <button mat-icon-button *ngIf="!(options.custom_edit_action)" color="primary"
                [routerLink]="[getElementEditLink(element), element._id]">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" *ngIf="options.custom_edit_action"
                (click)="options.custom_edit_action(element)">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="openDeleteDialog(element._id)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator  [pageSizeOptions]="[10, 20, 50]" [pageIndex]="(this.options.page_options && !this.searching)?this.options.page_options.current_page : 0"
                [pageSize]="(this.options.page_options && !this.searching)?this.options.page_options.per_page:null"
                [length]="(this.options.page_options && !this.searching)?this.options.page_options.item_count:null" (page)="onPaginatorChange($event)" showFirstLastButtons></mat-paginator>
