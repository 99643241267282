<div class="hour-log-form">
    <mat-card style="margin-top : 20px">
        <h4>Ajouter un horaire</h4>
        <form class="form-add-hour-log" #hourLogInserter="ngForm" name="hourlog" style="padding-top : 30px;">

            <div fxLayout="row">
                <div fxFlex="20">
                    <mat-form-field class="form-field fullwidth">
                        <input matInput [matDatepicker]="picker" placeholder="Date" [(ngModel)]="model.date" name="date"
                            required (dateChange)="dateChanged($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="20">
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Heure de départ" [(ngModel)]="model.hour_start" type="time"
                            name="start_hour" required>
                    </mat-form-field>
                </div>
                <div fxFlex="20">
                    <mat-form-field class="form-field fullwidth">
                        <input matInput placeholder="Heure de fin" [(ngModel)]="model.hour_end" type="time"
                            name="hour_end" required>
                    </mat-form-field>
                </div>
                <div fxFlex="20">
                    <mat-form-field class="form-field fullwidth">
                        <mat-label>Employé</mat-label>
                        <mat-select [(ngModel)]="model.employee_id" name="employee" required>
                            <mat-option *ngFor="let employee of employees" [value]="employee._id">
                                {{(employee.full_name)?employee.full_name : employee.username}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="20">
                    <button mat-raised-button (click)="addHourLog();" color="primary"
                        [disabled]="hourLogInserter.invalid">Ajouter</button>
                </div>
            </div>
        </form>
    </mat-card>

    <table mat-table [(dataSource)]="dataSource" class="" *ngIf="loaded" style="width:100%">

        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element;let index = index">
                <editable [toggled]="element.editing" [disableClickEvent]="true">
                    <ng-template viewMode>
                        {{element.date | date : "dd.MM.y"}}
                    </ng-template>
                    <ng-template editMode>
                        <mat-form-field class="fullwidth">
                            <input matInput [matDatepicker]="picker_index" [formControl]="getControl(index, 'date')">
                            <mat-datepicker-toggle matSuffix [for]="picker_index"></mat-datepicker-toggle>
                            <mat-datepicker #picker_index startView="month"></mat-datepicker>
                            <mat-error *ngIf="getControl(index, 'date').hasError('required')">
                                Field is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <!-- <input  [formControl]="getControl(index, 'name')" focusable editableOnEnter> -->
                    </ng-template>
                </editable>
            </td>


        </ng-container>

        <!-- hour_start Column -->
        <ng-container matColumnDef="hour_start">
            <th mat-header-cell *matHeaderCellDef> Heure de début </th>
            <td mat-cell *matCellDef="let element;let index = index">
                <editable [toggled]="element.editing" [disableClickEvent]="true">
                    <ng-template viewMode>
                        {{element.hour_start}}
                    </ng-template>
                    <ng-template editMode>
                        <mat-form-field class="fullwidth">
                            <input matInput [formControl]="getControl(index, 'hour_start')" type="time">
                            <mat-error *ngIf="getControl(index, 'hour_start').hasError('required')">
                                Field is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </ng-template>
                </editable>
            </td>


        </ng-container>

        <!-- hour_end Column -->
        <ng-container matColumnDef="hour_end">
            <th mat-header-cell *matHeaderCellDef> Heure de fin </th>
            <td mat-cell *matCellDef="let element;let index = index;">
                <editable [toggled]="element.editing" [disableClickEvent]="true">
                    <ng-template viewMode>
                        {{element.hour_end}}
                    </ng-template>
                    <ng-template editMode>
                        <mat-form-field class="fullwidth">
                            <input matInput [formControl]="getControl(index, 'hour_end')" type="time">
                            <mat-error *ngIf="getControl(index, 'hour_end').hasError('required')">
                                Field is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </ng-template>
                </editable>
            </td>
        </ng-container>

        <!-- employee Column -->
        <ng-container matColumnDef="employee">
            <th mat-header-cell *matHeaderCellDef> Employé </th>
            <td mat-cell *matCellDef="let element;let index = index">
                <editable [toggled]="element.editing" [disableClickEvent]="true">
                    <ng-template viewMode>
                        {{getEmployee(element.employee_id).full_name}}
                    </ng-template>
                    <ng-template editMode>
                        <mat-select class="fullwidth" [formControl]="getControl(index, 'employee_id')" focusable
                            editableOnEnter>
                            <mat-option *ngFor="let employee of employees" [value]="employee._id">
                                {{employee.full_name}}
                            </mat-option>
                        </mat-select>
                    </ng-template>
                </editable>
            </td>
        </ng-container>
        <!-- delete Column -->
        <ng-container matColumnDef="controls">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element;let index = index">
                <div class="actions">
                    <button *ngIf="!element.editing" mat-icon-button color="primary" (click)="element.editing = true">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="element.editing" mat-icon-button color="primary" (click)="sendUpdate(index,element)">
                        <mat-icon>done</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="deleteRow(element, index)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


</div>
