import { Component, OnInit, Input, Inject, ViewChild, SimpleChanges, OnChanges, SimpleChange } from '@angular/core';
import { Location } from '@angular/common';
import { _intervention, Intervention } from 'src/app/models/DB';
import { Report, ReportContract } from 'src/app/models/Report';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as BalloonBlockEditor from '@ckeditor/ckeditor5-build-balloon-block';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, from, of } from 'rxjs';
import { flatMap, mergeMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/services/settings.service';
import {NotificationService, Notification} from '../../../services/notification.service';

const BASE_URL = environment.BASE_URL
export interface DialogData {
  intervention: _intervention
}

@Component({
  template: `
  <h1 mat-dialog-title>Générer un document détaillé pour l'intervention n°{{(data.intervention)?data.intervention.order.code:""}}</h1>
<div mat-dialog-content>

  <app-report #report [intervention]='data.intervention' [report]='data.intervention.invoice' [showControls]='false'></app-report>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annuler</button>
  <button mat-button (click)="close()" cdkFocusInitial>Générer document</button>
</div>
  `
})
export class ReportDialog {
  @ViewChild("report", { static: true}) report : ReportComponent = null;
  generating = false;
  constructor(
    public dialogRef: MatDialogRef<ReportDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  close(){
    this.generating = true;
    this.report.sendReport().subscribe({
      next: (d) => {
        this.report.getLink()
        this.dialogRef.close(this.data.intervention);
      },
      error: (err) => console.error(err),
      complete: () => this.generating = false
    })

  }
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, OnChanges {
  @Input() showControls = true

  @ViewChild('remarks') remarks;

  public Editor = BalloonBlockEditor;
  public editorConfig = {
    toolbar: ['bold', 'italic', 'link', 'underline', 'colors' ],
  }

  @Input() report : ReportContract = null;
  model : ReportContract = Report.buildEmpty();
  inter : Intervention = null;
  operations  : string[] = [];
  @Input() set intervention(val : Intervention){
    this.inter = val;
    this.model = (this.inter.invoice.intervention_id!='')?this.inter.invoice :Report.createFromIntervention(this.inter);

  }
  get intervention(){return this.inter}

  id: string;
  saving = false;

  constructor(private route: ActivatedRoute, private router: Router, private api : APIDatasService, private settings : SettingsService, private location:Location, private notifs: NotificationService) { }

  ngOnInit() {

    this.operations = new Array<string>().concat(this.model.operations) || [];
    //if(this.model.hourly_cost.price <= 0) this.model.hourly_cost.price = parseFloat(this.settings.getSetting("global-hourly-rate").value || "100")
    /*if(this.model.distance_cost.price <=0) this.model.distance_cost.price = parseFloat(this.settings.getSetting("global-distance-rate").value || "100")
    if(this.model.administrative_cost <= 0)this.model.administrative_cost = parseFloat(this.settings.getSetting("global-administrative-rate").value || "70")*/
this.route.params.subscribe(params => {
      if(this.inter != null || this.report != null){
        return;
      }
      this.id = params['id'];

      this.api.getDatas("/interventions/" + this.id+"?include[]=tasks&include[]=order&include[]=order.client&include[]=invoice").pipe(flatMap( (res) => {
        if(res.invoice == null){
          return this.api.update("/interventions/" + this.id+"?include[]=tasks&include[]=order&include[]=order.client&include[]=invoice", res)
        }
        return of(res)
      })).subscribe(
        res => {
          var interv = new Intervention();
          interv.loadFromObject(res);
          this.intervention = interv;
         // if(this.model.hourly_cost.price <= 0) this.model.hourly_cost.price = parseFloat(this.settings.getSetting("global-hourly-rate").value || "100")
          /*if(this.model.distance_cost.price <=0) this.model.distance_cost.price = parseFloat(this.settings.getSetting("global-distance-rate").value || "100")
          if(this.model.administrative_cost <= 0)this.model.administrative_cost = parseFloat(this.settings.getSetting("global-administrative-rate").value || "70")*/

        },
        err => {
          console.error("Error finding intervention id : "+ this.id, err);
        },
        () => {
          this.operations = new Array<string>().concat(this.model.operations);
          this.remarks.setData(this.intervention.invoice.remarks);
        }
      );
   });

  }
  ngOnChanges(changes : SimpleChanges){



  }

  onRemarksChange(event){
    this.model.remarks = event;
  }

  getTotal(part){
    return part.quantity * part.price + 0.00;
  }
  getTotalList(list){
    return list.reduce( (acc, cur) => acc + this.getTotal(cur),0.0)

  }
  getHourlyTotal(){


    return this.model.hourly_cost.reduce( (acc, h) => acc + h.total, 0.00)
  }
  getReportTotal(){
    return this.getHourlyTotal() + this.getTotalList(this.model.costs) + this.model.distance_cost.total + this.model.administrative_cost
  }
  upsertReport(){

  }

  sendReport(){
    return this.api.create(`interventions/${this.inter._id}/report`,{...this.model, operations: this.operations}).pipe(map((res) => {
      if(res.error)
        throw new Error("Unable to create report " +JSON.stringify(res) );
    }))
    //.pipe( mergeMap((r_) => this.api.getDatas(`interventions/${this.inter._id}/invoice`)))

  }
  getLink(){
    /*var link = document.createElement("a");
        link.download = "Document interne";
        link.target="_blank"
        link.href = this.api.getUrl(`/interventions/${this.inter._id}/invoice`);
        link.click();*/
      window.open(this.api.getUrl(`/interventions/${this.inter._id}/invoice`), '_blank');
  }
  dropOp(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.model.operations, event.previousIndex, event.currentIndex);
  }

  cancel(){
    this.location.back();
  }
  submit(){
    this.saving = true;
    this.sendReport().subscribe({
      next: (d) => {

      },
      error: (err) => console.error(err),
      complete: () => {this.notifs.notify(new Notification("Enregistrement du rapport", "Rapport sauvegardé avec succès"));this.saving = false}
    })

  }
  download(){
    this.saving = true;
    this.sendReport().subscribe({
      next: (d) => {

      },
      error: (err) => console.error(err),
      complete: () => {this.saving = false; this.getLink()}
    })

  }
  closeAndDl(){
    this.submit()
    this.api.update(`interventions/${this.inter._id}`,{...this.intervention.toData(), status: "finished"}).pipe(map((res) => {}))
    .subscribe( (res)=>{},
      (err)=>{
        this.notifs.error(new Notification("Impossible de clôturer", err));
        throw new Error("Unable to close intervention" +JSON.stringify(err) );},
      () => {
        this.download();
        this.router.navigate(['interventions/'])
      });

  }

  onOpChange($event, i){
    this.operations[i]=$event.editor.getData();
  }
}
