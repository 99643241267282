import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-add-view',
  templateUrl: './order-add-view.component.html',
  styleUrls: ['./order-add-view.component.css']
})
export class OrderAddViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
