import { Component, OnInit } from '@angular/core';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { AbstractListViewComponent } from '../../abstract-list-view/abstract-list-view.component';

@Component({
  selector: 'app-item-list-view',
  templateUrl: './item-list-view.component.html',
  styleUrls: ['./item-list-view.component.css']
})
export class ItemListViewComponent extends AbstractListViewComponent implements OnInit {

  constructor(protected api : APIDatasService) { 
    super(api);
    this.listingOptions  =  {
      "type_options"  : {"base_route" : "interventions/item_types", "type_name" : "Objet"},
      "table_options" : [
        {"label" : "Nom", "property_name" : "name"},
        {"label" : "Nombre d'emplacements", "property_name" : "nb_locs"},
        {"label" : "Nombre d'Actions", "property_name" : "nb_acts"}
      ]
    };
  }

  createOneDataRecord(index, array){
    this.finishedLoading = false;
    var tmp = array[index];
    var loc_count = 0;
    for(var l of tmp.locations){
      loc_count+=l.children.length;
      loc_count++;
    }
    tmp['nb_locs'] = loc_count
    tmp['nb_acts'] = tmp.targets.length

    return tmp
  }

}
