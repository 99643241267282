<div class="page-header" style="background: linear-gradient(90deg, #D35400, #dc7e60);">
        <h1>
            Modification de client
        </h1>
    </div>
    <div class="main clients-theme" fxLayout="row">
        <div fxFlex="40" style="padding: 0px 10px" *ngIf="this.client && this.client!=undefined">
            <mat-card class="presentation-block" style="background: #D35400;">
                <div class="icon" >
                    <mat-icon>perm_identity</mat-icon>
                </div>
                <div class="content">
                    <h4>Modifier {{client.name}}</h4>
                    <p>Cette vue sert à modifier le client désigné</p>
                </div>
            </mat-card>
        </div>
        <div fxFlex="60" *ngIf="!this.client || this.client==undefined" style="text-align: center">
            <trs-spinner [elem]="'client'"></trs-spinner>
        </div>
        <div fxFlex="60" style="padding: 0px 10px" *ngIf="this.client && this.client!=undefined">
            <mat-card class="client-address">
                <client-form [(client)]="this.client"></client-form>
            </mat-card>
        </div>
        <div fxFlex="60" *ngIf="!this.client || this.client==undefined" style="text-align: center">
            <trs-spinner [elem]="'client'"></trs-spinner>
        </div>
    </div>
    