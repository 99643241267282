import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';


export class CheckboxItem {
  value: string;
  label: string;
  checked: boolean;
  constructor(value: any, label: any, checked?: boolean) {
    this.value = value;
    this.label = label;
    this.checked = checked ? checked : false;
  }
}
 @Component({
  selector: 'checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.css']
})
export class CheckboxGroupComponent implements OnInit, OnChanges {
  @Input() options = Array<CheckboxItem>();
  @Input() selectedValues: string[];
  @Input() required: boolean = false;

  @Output() toggle = new EventEmitter<any[]>();
  constructor() { }
  checkedOptions = [];

  @Input() touched : boolean = false;
  ngOnInit() {
    this.checkedOptions = this.options.filter(x => x.checked);
  }
  
  onToggle($e, item) {
    this.touched = true;
    item.checked = $e.checked;
    this.checkedOptions = this.options.filter(x => x.checked) ;
    this.selectedValues = this.checkedOptions.map(x => x.value);
    this.toggle.emit(this.checkedOptions.map(x => x.value));
   }

  updateChecked(){
    this.checkedOptions = this.options.filter(x => x.checked);
  }

   ngOnChanges() {
    this.selectedValues.forEach(value => {
     const element = this.options.find(x => x.value === value);
     if (element) {
       element.checked = true;
     }
    });
    this.updateChecked();
   }

}
