import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";

import { AppLoadService } from './app-load.service';
import { SettingsService } from '../services/settings.service';
import { APIDatasService } from '../services/apidatas.service';
import { AuthService } from '../services/auth.service';

export function init_app(appLoadService: AppLoadService) {
    return () => appLoadService.initializeApp();
}

export function get_settings(appLoadService: AppLoadService) {
    return () => appLoadService.loadSettings();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    SettingsService,
    AppLoadService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },
    { provide: APP_INITIALIZER, useFactory: get_settings, deps: [AppLoadService], multi: true }
  ]
})
export class AppLoadModule { }
