<form #form>
    <h3>{{part? "Modifier" : "Nouvelle"}} pièce</h3>
    <mat-form-field class="fullwidth">
        <mat-label>Pièce</mat-label>
        <input required matInput type="text" name="name" [(ngModel)]="model.name">
    </mat-form-field>
    <mat-form-field class="fullwidth">
        <mat-label>Code</mat-label>
        <input required matInput type="text" name="code" [(ngModel)]="model.code">
    </mat-form-field>
    <mat-form-field class="fullwidth">
        <mat-label>Prix</mat-label>
        <input required matInput type="text" name="price" type="number" min="0.00" step="0.05" oninput="validity.valid||(value=(Math.ceil(value*20)/20).toFixed(2))" [(ngModel)]="model.price">
    </mat-form-field>
    <mat-form-field class="fullwidth">
        <mat-label>Type de pièce</mat-label>
        <input required matInput type="text" name="type" [(ngModel)]="model.type">
    </mat-form-field>

    <mat-form-field class="fullwidth">
        <mat-label>Catégories</mat-label>
        <mat-select multiple name="categories" [(ngModel)]="model.categories" required>
            <mat-option value="{{it._id}}" *ngFor="let it of this.itemTypes; let i = index;" style="float: left;">
                {{it.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div style="padding : 10px">
        <mat-label>Image</mat-label>
        <image-uploader [limit]="1" [urls]="this.model.images" (imageDeleted)="deleteImage($event)" (imageListChange)="setImages($event)"></image-uploader>
    </div>
    <div style="text-align : right">
            <button mat-raised-button color = "primary" (click)="upsertPart($event)">{{part? "Editer" : "Créer"}}</button>
            <button mat-button (click)="this.close.emit()">Annuler</button>
    </div>

</form>
