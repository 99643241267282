<h2>{{label}}</h2>
<table mat-table [dataSource]="dataSource" style="width:100%" *ngIf="shouldDisplayTable()">
  <ng-container *ngFor="let field of getFields()" [matColumnDef]="field.name">
    <th mat-header-cell *matHeaderCellDef> {{ field.label }} </th>
    <td mat-cell *matCellDef="let element;let index = index" [ngClass]='[element.status ? "status-"+element.status:""]'>
      <span [innerHtml]="element[field.name]"></span>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element;let index = index" class="actions-cell" [ngClass]='[element.status ? "status-"+element.status:""]'>
      <div class="actions" [style.width.px]="config.controls.length * 50">
        <ng-container *ngFor="let control of config.controls">
          <ng-container *ngIf="control.children && control.children.length > 0 else singleButton">
            <stacked-buttons [actions]="control.children" [icon]="control.icon" [actionParameter]="element"></stacked-buttons>
          </ng-container>
          <ng-template #singleButton>
            <button mat-icon-button  (click)="control.action(element)"><mat-icon>{{control.icon}}</mat-icon></button>
          </ng-template>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="getColumnNames()"></tr>
  <tr mat-row *matRowDef="let row; columns: getColumnNames();"></tr>
</table>
<div class="empty" *ngIf="tableIsEmpty()">
  <p style="text-align:center">Aucune données diponible</p>
</div>
<div class="error" *ngIf="shouldDisplayErrorMessage()">
  <span>Une erreur est survenu lors du chargement...</span>
  <span>Veuillez rafraichir la page et re-essayer ultérieurement</span>
  <span>Si le problème persiste, veuillez contacter le support TrisInformatique</span>
</div>
<div *ngIf="shouldDisplayLoader()">
  <trs-spinner></trs-spinner>
</div>
<mat-paginator
  [ngStyle]="{display: shouldDisplayPaginator() ? 'block' : 'none'}"
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="(page$ | async)?.current_page"
  [length]="totalAmountOfData"
  [pageSize]="(page$ | async)?.per_page"
  (page)="onPageChange($event)"
  showFirstLastButtons>
</mat-paginator>
