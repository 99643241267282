<div class="page-header" style="background: linear-gradient(90deg, #2C3E50, #34495E);">
    <h1>
        Tous les utilisateurs
    </h1>
</div>
<div class="main users-theme" fxLayout="row">
    <app-listing-form [(datas)]="this.datas" [(options)]="listingOptions"
        [hidden]="!this.finishedLoading || !this.datas.length" (deletedObject)="this.fetchDatas()" style="width : 100%" (needDataReload)="this.fetchDatas()">
    </app-listing-form>
    <div *ngIf="this.finishedLoading && !this.datas.length">
        <h1>Il n'y a pas d'utilisateurs à afficher</h1>
    </div>
    <div *ngIf="!this.finishedLoading">
        <trs-spinner [elem]="'users'"></trs-spinner>
    </div>
</div>