<div class="remarks">
    <div *ngFor="let section of sections; let i = index;" class="singular">
        <label>{{section.label}}</label>
        <div *ngIf="this.options[section.key].length" style="margin-top:10px">
            <checkbox-group [touched]="settedData" [required]="i==0" [options]="options[section.key]" [selectedValues]="model[section.key].selected" (toggle)="onSelectionChange($event, section.key)"></checkbox-group>
        </div>
        <mat-form-field style="width:100%">
            <mat-label>Complément</mat-label>
            <textarea matInput placeholder="Complément" type="text" name="{{section.key}}_additional" [(ngModel)]="model[section.key].additional" (keyup)="change($event)"></textarea>
        </mat-form-field>
    </div>
</div>
