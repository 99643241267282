export interface _user{
    _id?        : string;
    username    : string;
    password?   : string;
    email       : string;
    role        : string;
    last_name   : string;
    first_name  : string;
    full_name   : string;
    hourly_rate? : number;

    isValid(): boolean;
}

export class User implements _user{

    _id?    : string;
    username: string;
    email   : string;
    password?: string;
    hourly_rate? : number;
    role    : string;
    last_name   : string;
    first_name  : string;
    full_name : string;

    static build(data) : _user{
        const m     = new User();
        m._id       = data._id;
        m.username  = data.username;
        m.password  = data.password;
        m.email     = data.email;
        m.role      = data.role;
        m.last_name = data.last_name || '';
        m.first_name= data.first_name || '';
        m.full_name = data.full_name || '';
        m.hourly_rate = data.hourly_rate;
        return m;
    }

    static buildEmpty(){
        return User.build({
            username    : '',
            last_name   : '',
            first_name  : '',
            full_name   : '',
            password    : '',
            email       : '',
            role        : ''
        });
    }
    isValid(): boolean {
      if(this.username.trim() === ''){
        return false;
      }
      if(this.first_name.trim() === ''){
        return false;
      }

      if(this.last_name.trim() === ''){
        return false;
      }

      if(this.password && this.password.trim() === ''){
        return false;
      }

      if(this.role.trim() === ''){
        return false;
      }

      return true;
    }

}
