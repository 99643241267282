import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { APIDatasService } from 'src/app/services/apidatas.service';

export interface ItemChild {
  _id? : string,
  code : string,
  name : string,
  description? : string
}

@Component({
  selector: 'item-child',
  templateUrl: './item-child.component.html',
  styleUrls: ['./item-child.component.css'],
  exportAs: 'ngForm'
})
export class ItemChildComponent implements OnInit, AfterViewInit {

  private _arr : ItemChild[];
  tEditings : boolean[] = [];
  @Input() apiroute;
  @Input()
    get arr(){
      return this._arr;
    }

    set arr(t : ItemChild[]){
      this._arr = t;
      this.arrChange.emit(this._arr);
      this.updateControls();
    }

  @Output() arrChange = new EventEmitter<ItemChild[]>();

  controls: FormArray;

  constructor(private api : APIDatasService, private cd : ChangeDetectorRef) {}


  ngOnInit() {

  }

  ngAfterViewInit(){
    this.updateControls()
  }

  addChild(form){

    var tmp : ItemChild;
    var val = form.form.value
    tmp = {
      name        : val.name,
      code        : val.code,
      description : val.description
    }

    this.arr.push(tmp);
    this.arr = new Array<ItemChild>().concat(this.arr);
    this.updateControls()
    form.reset();
  }

  updateOrder(){
    var tmp = new Array<ItemChild>().concat(this.arr);
    tmp.sort((x1, x2) =>{
      if(!x1.code && !x2.code) {return 0 ;}
      if(!x1.code && x2.code) {return 1 ;}
      if(x1.code && !x2.code) {return -1 ;}

      if(x1.code < x2.code) { return -1; }
      if(x1.code > x2.code) { return 1; }
      return 0;
    });
    this.arr = new Array<ItemChild>().concat(tmp);
    this.cd.detectChanges()
  }

  removeChild(i){
    if(this.apiroute && this.apiroute !=""){
      if(this.arr[i] && this.arr[i]._id && this.arr[i]._id!=""){
        this.api.delete(this.apiroute, this.arr[i]._id).subscribe(
          res => {
            this.arr = new Array<ItemChild>().concat(this.arr.filter((v, index) => {
              return index != i;
            }));
          },
          error =>{
            console.error("Error deleting "+this.arr[i].code, error);
          }
        );
      } else {
        this.arr = new Array<ItemChild>().concat(this.arr.filter((v, index) => {
          return index != i;
        }));
      }

    }

  }

  getControl(index, fieldName) {
    const a  = this.controls.at(index).get(fieldName) as FormControl;
    return this.controls.at(index).get(fieldName) as FormControl;
  }

  updateControls(){
    const toGroups = this.arr.map(entity => {
      return new FormGroup({
        code:  new FormControl(entity.code, Validators.required),
        name: new FormControl(entity.name, Validators.required),
        description: new FormControl(entity.description, Validators.required)
      },{updateOn: "blur"});
    });

    this.controls = new FormArray(toGroups);
  }

  updateChild(index) {
    const controls = [this.getControl(index, "code"),this.getControl(index, "name"),this.getControl(index, "description")];
    if (controls[0].valid && controls[1].valid && controls[2].valid) {
      this.arr[index]['code'] = controls[0].value;
      this.arr[index]['name'] = controls[1].value;
      this.arr[index]['description'] = controls[2].value;
      this.arr = new Array<ItemChild>().concat(this.arr);
      this.updateOrder();
    }

  }

}
