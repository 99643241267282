<div>
    <form #clientForm="ngForm">
        <table style="width : 100%">
            <tr>

                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Société" [(ngModel)]="model.name" name="name">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Personne de contact" [(ngModel)]="model.person" name="person">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Adresse" [(ngModel)]="model.address" name="address"
                            class="fullwidth" #ClientAddr="ngModel">
                    </mat-form-field>
                </td>

            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Npa" [(ngModel)]="model.zip" name="zip" mask="0*" minlength="4"
                            maxlength="6" #NPAClient="ngModel">
                        <mat-error *ngIf="NPAClient.touched && NPAClient.invalid">
                            <span>
                                NPA invalide
                            </span>
                        </mat-error>

                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Ville" [(ngModel)]="model.city" name="city">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Téléphone" [(ngModel)]="model.phone" name="phone"
                            #clientPhone="ngModel" mask="000 000 00 00" [validation]="true">
                        <mat-error *ngIf="clientPhone.touched && clientPhone.invalid">
                            <span>
                                Numéro de téléphone invalide
                            </span>
                        </mat-error>
                    </mat-form-field>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Mobile" [(ngModel)]="model.mobile" name="mobile"
                            #clientMobile="ngModel" mask="000 000 00 00" [validation]="true">
                        <mat-error *ngIf="clientMobile.touched && clientMobile.invalid">
                            <span>
                                Numéro de téléphone invalide
                            </span>
                        </mat-error>
                    </mat-form-field>
                </td>
            </tr>
        </table>
        <div style="text-align : right; width: 95%;">
            <button (click)="createClient()" mat-raised-button color="accent"
                class="trs-button">{{client != null ? 'Mettre à jour' : 'Enregistrer'}}</button>
            <button [routerLink]="['/clients']" mat-button color="accent" class="trs-button">Annuler</button>
        </div>

    </form>
</div>