<div class="container">
    <canvas height="100" class="signature" #signature></canvas>

    <button mat-raised-button color="primary" class="clear" type="button" (click)="clear()"><span>Recommencer</span></button>
    <input  #file hidden id="image-input" name="image-input" type="file" accept="image/*" (change)="onSelectFile($event)" [multiple]="false" />

    <button class="upload" style="margin-top: 5px;" color="primary" mat-raised-button (click)="file.click()">
        <span style="margin: 15px;">Ajouter une image</span>
        <mat-icon>camera_alt</mat-icon>
    </button>
</div>
