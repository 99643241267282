import { Component, OnInit } from '@angular/core';
import { ListingOptions } from 'src/app/components/listing-form/listing-form.component';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { AbstractListViewComponent } from '../../abstract-list-view/abstract-list-view.component';

@Component({
  selector: 'app-user-list-view',
  templateUrl: './user-list-view.component.html',
  styleUrls: ['./user-list-view.component.css']
})
export class UserListViewComponent extends AbstractListViewComponent implements OnInit  {

  constructor(protected api : APIDatasService) { 
    super(api);
    this.listingOptions  =  {
      "type_options"  : {"base_route" : "users", "type_name" : "Utilisateur"},
      "table_options" : [
        {"label" : "Login", "property_name" : "username"},
        {"label" : "Email", "property_name" : "email"},
        {"label" : "Rôle", "property_name" : "role"},
      ]
    };
  }
}
