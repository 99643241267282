<div class="page-header" style="background: linear-gradient(90deg, #16A085, #1ABC9C);">
    <div class="main" style="padding-top: 0px;">
        <h1>
            Tous les objets
        </h1>
    </div>
</div>
<div class="main items-theme" fxLayout="row">
    <app-listing-form [(datas)]="this.datas" [(options)]="listingOptions" (needDataReload)="this.fetchDatas()"
        [hidden]="!this.finishedLoading || !this.datas.length" (deletedObject)="this.fetchDatas()" style="width : 100%">
    </app-listing-form>
    <div *ngIf="this.finishedLoading && !this.datas.length">
        <h1>Il n'y a pas d'objets à afficher</h1>
    </div>
    <div *ngIf="!this.finishedLoading">
        <trs-spinner [elem]="'item'"></trs-spinner>
    </div>
</div>