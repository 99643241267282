<div class="page-header" style="background: linear-gradient(90deg, #8E44AD, #9B59B6);">
    <h1>
        Tous les Bons de commande
    </h1>
</div>
<div class="main orders-theme" fxLayout="row">
    <app-listing-form [(datas)]="this.datas" [(options)]="listingOptions" (needDataReload)="this.fetchDatas($event)"
        [hidden]="!this.finishedLoading || !this.datas.length" (deletedObject)="this.fetchDatas()" style="width : 100%"
        [defSortCol]="'created_at'">
    </app-listing-form>
    <div *ngIf="this.finishedLoading && !this.datas.length">
        <h1>Il n'y a pas de commandes à afficher</h1>
    </div>
    <div *ngIf="!this.finishedLoading">
        <trs-spinner [elem]="'order'"></trs-spinner>
    </div>
</div>
