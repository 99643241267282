<div class="login-form-card" [hidden]="(!this.edit && !this.cardSelected)">
  <mat-vertical-stepper [linear]="false" class="data-stepper" #stepper [(selectedIndex)]="selectedStep"
    (selectionChange)="stepperselected($event)">
    <mat-step [errorMessage]="this.errorMessage('task-list')" [hasError]="!this.valid('task-list')">
      <ng-template matStepLabel>Opérations effectuées</ng-template>
      <app-inter-form-items #tasks (tasksChange)="onTaskListChange($event)" [hidden]="intervention.status=='impossible'"></app-inter-form-items>
      <div *ngIf="intervention.status=='impossible'">
        <h3>Il n'y a pas d'opérations dans une intervention non-réalisable</h3>
      </div>
      <div class="stepper-navBtns">
        <button *ngIf="!this.edit" mat-button color="primary" (click)="this.resetForm()">Annuler</button>
        <button *ngIf="this.edit" mat-button color="primary" (click)="this.onCancelClick()">Annuler</button>
        <button mat-raised-button color="primary" matStepperNext (click)="this.valid('task-list')">Étape Suivante</button>
      </div>
    </mat-step>
    <mat-step [errorMessage]="this.errorMessage('remarks')" [hasError]="!this.valid('remarks')">
      <ng-template matStepLabel >Détails et Informations complémentaires</ng-template>
      <div style="text-align: none; padding: 20px 5%; width : 90%">
        <remarks-form #remarks (remarksChanged)="onRemarksChange($event)" (click)="logRemarks()"></remarks-form>

        <div class="stepper-navBtns">
          <button mat-button color="primary" matStepperPrevious>Étape Précédente</button>
          <button mat-raised-button color="primary" matStepperNext>Étape Suivante</button>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Pièces additionnelles</ng-template>
      <div style="text-align: right;">
        <parts-form #parts (partsChange)="onPartChange($event)"></parts-form>
        <div class="stepper-navBtns">
          <button mat-button color="primary" matStepperPrevious>Étape Précédente</button>
          <button mat-raised-button color="primary" matStepperNext>Étape Suivante</button>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Temps de travail et Kilométrage</ng-template>
      <hour-log-form #hourLogs (hourlogsChange)="onHourLogChange($event)"></hour-log-form>
      <div>
        <app-googlemaps-km #distance *ngIf="this.order && this.order.coords"
          [destinationCoords]="(this.order && this.order._id) ? this.order.coords : (null)"
          (changeDist)="onDistanceChange($event)">
        </app-googlemaps-km>
      </div>
      <div class="stepper-navBtns">
        <button mat-button color="primary" matStepperPrevious>Étape Précédente</button>
        <button mat-raised-button color="primary" matStepperNext>Étape Suivante</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Images Avant</ng-template>
      <!--<h1>Avant:</h1>
          <image-uploader></image-uploader>
          <h1>Après:</h1>
          <image-uploader></image-uploader>-->
      <image-uploader #before_images (imageDeleted)="deleteImageFromIntervention($event)"
        (imageListChange)="onImageListChanges($event, 'before_images')"></image-uploader>
      <div style="text-align: right; padding: 20px 5%; width : 90%">
        <button mat-button color="primary" matStepperPrevious>Étape Précédente</button>
        <button mat-raised-button color="primary" matStepperNext>Étape Suivante</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Images Après</ng-template>
      <!--<h1>Avant:</h1>
          <image-uploader></image-uploader>
          <h1>Après:</h1>
          <image-uploader></image-uploader>-->
      <image-uploader #after_images (imageDeleted)="deleteImageFromIntervention($event)"
        (imageListChange)="onImageListChanges($event, 'after_images')"></image-uploader>
      <div style="text-align: right; padding: 20px 5%; width : 90%">
        <button mat-button color="primary" matStepperPrevious>Étape Précédente</button>
        <button mat-raised-button color="primary" matStepperNext>Étape Suivante</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Finalité et signatures</ng-template>

      <div fxLayout="row" fxLayout.lt-md="column" style="margin-bottom: 10%;">
        <div fxFlex="66" fxLayout="row">
            <div fxFlex="50" *ngIf="intervention.status!='impossible'">
                <h3>Avancement des travaux</h3>
                <mat-radio-group [ngModel]="intervention.status" (ngModelChange)="changedStatusInForm($event)" aria-label="Choisir une option">
                  <mat-radio-button style="display: block;" value="waiting_validation" [checked]="intervention.status == 'waiting_validation'">Travaux définitivement terminés</mat-radio-button>
                  <mat-radio-button style="display: block;" value="draft" [checked]="intervention.status == 'draft'">Compléments nécessaires</mat-radio-button>
                  <!--<user-guard is="admin">
                    <mat-radio-button style="display: block; color: #ec644b" value="finished" [checked]="intervention.status == 'finished'">Cloturée</mat-radio-button>
                  </user-guard>-->

                </mat-radio-group>
              </div>
              <div fxFlex="50" *ngIf="intervention.status!='impossible'">
                  <h3>Auto évaluation</h3>
                  <mat-form-field style="display:block;" *ngIf="this.intervention && this.intervention.auto_eval!=undefined"
                     [ngClass]="{'mat-form-field-invalid' : intervention.status=='waiting_validation' || intervention.status=='finished'}">
                      <mat-label>Auto évaluation</mat-label>
                      <mat-select [(ngModel)]="intervention.auto_eval" [required]="intervention.status=='waiting_validation' || intervention.status=='finished'" >
                        <mat-option value="NE">Non effectué</mat-option>
                        <mat-option value="S">Suffisant</mat-option>
                        <mat-option value="B">Bon</mat-option>
                        <mat-option value="P">Parfait</mat-option>
                      </mat-select>
                    </mat-form-field>
              </div>
              <div fxFlex="50" *ngIf="intervention.status=='impossible'">
                <h3>Cause "Pas réalisable"</h3>
                <mat-form-field style="display:block;" *ngIf="this.intervention && this.intervention.auto_eval!=undefined"
                   [ngClass]="{'mat-form-field-invalid' : intervention.status=='impossible'}">
                    <mat-label>Pourquoi pas réalisable ?</mat-label>
                    <mat-select [(ngModel)]="intervention.auto_eval" [required]="intervention.status=='impossible'" >
                      <mat-option value="needs-quote">Devis à faire</mat-option>
                      <mat-option value="needs-someone">À traiter pas un autre corps de métier</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </div>
        <div fxFlex="33" fxFlex.lt-md="100">
          <h3>Date</h3>
          <mat-form-field *ngIf="this.intervention && this.intervention.date_end">
            <input matInput [matDatepicker]="picker" placeholder="Choisir une date" [(ngModel)]="intervention.date_end">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="year" [startAt]="intervention.date_end"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="50" style="padding: 0px 10px;" class="trs-not-selectable">
          <h3>Signature du Client</h3>
          <!-- added by lfavre 15.02.22-->
          <mat-checkbox [(ngModel)]="noSignatureNeeded">Pas de signature client</mat-checkbox>
          <h4 *ngIf="noSignatureNeeded">Raison : </h4>
          <mat-radio-group *ngIf="noSignatureNeeded" [(ngModel)]="intervention.signatures.client" aria-label="Choisir une option">
            <mat-radio-button style="display: block; margin: 10px 25px;" value="absent" [checked]="intervention.signatures.client == 'absent'">Locataire absent</mat-radio-button>
            <mat-radio-button style="display: block; margin: 10px 25px;" value="vacant" [checked]="intervention.signatures.client == 'vacant'">Appartement vacant</mat-radio-button>
          </mat-radio-group>
          <!--End input-->
          <signature-pad #cliSignPad [ngStyle]="(noSignatureNeeded) ? {'visibility': 'hidden'} : {'visibility' : 'visible'}" (onUpdate)="onClientSignUpdate($event)"></signature-pad>
          <p *ngIf="!noSignatureNeeded" style="font-size:13px; text-align: justify">Par sa signature, le client confirme que la réparation / remise
            en état de l'objet susmentionné a été exécutée
            correctement par le monteur. Il se déclare d'accord avec les inscriptions sur ce rapport.</p>
        </div>
        <div fxFlex="50" style="padding: 0px 10px;" class="trs-not-selectable">
          <h3>Signature de l'ouvrier</h3>
          <signature-pad #workerSignPad (onUpdate)="onWorkerSignUpdate($event)"></signature-pad>
        </div>
      </div>
      <div style="text-align: right; padding: 20px 5%; width : 90%">
        <button mat-button color="primary" matStepperPrevious>Étape Précédente</button>
        <button mat-button color="primary" (click)="this.onCancelClick()">Annuler</button>
        <button mat-raised-button color="primary" (click)="saveIntervention()"
          [disabled]="!this.intervention.isValid()">
          <span *ngIf="this.sending">
            <trs-spinner [elem]="'inter'" [isring]="true" [size]="'xs'" style="float: left; margin-bottom: -10px; margin-top: 5px;"></trs-spinner>
          </span>
          <span *ngIf="!this.sending">
            Envoyer
          </span>
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>


<button class="dl-report" *ngIf="intervention._id != ''" mat-button color="primary" (click)="downloadReport()">Télécharger
  les documents</button>

<button class="dl-report" *ngIf="intervention._id == '' && this.order.management_voucher && this.order.management_voucher.length > 0"
        mat-button color="primary" (click)="openVoucher()">Ouvrir le bon de gérance</button>

<mat-vertical-stepper [hidden]="(!this.edit && !this.cardSelected)" class="menu-stepper" [linear]="false" #stepper2
  (selectionChange)="stepperselected($event)" [(selectedIndex)]="selectedStep">
  <mat-step [hasError]="!this.valid('task-list')">
    <ng-template matStepLabel>Opérations</ng-template>
  </mat-step>
  <mat-step [hasError]="!this.valid('remarks')">
    <ng-template matStepLabel>Remarques</ng-template>
  </mat-step>
  <mat-step [hasError]="!this.valid('parts')">
    <ng-template matStepLabel>Pièces</ng-template>
  </mat-step>
  <mat-step [hasError]="!this.valid('hourlog-km')">
    <ng-template matStepLabel>Temps et KM</ng-template>
  </mat-step>
  <mat-step [hasError]="!this.valid('before-pics')">
    <ng-template matStepLabel>Images Avant</ng-template>
  </mat-step>
  <mat-step [hasError]="!this.valid('after-pics')">
      <ng-template matStepLabel>Images Après</ng-template>
  </mat-step>
  <mat-step [hasError]="!this.valid('last-step')">
    <ng-template matStepLabel>Terminer</ng-template>
  </mat-step>
</mat-vertical-stepper>


<app-order-picker *ngIf="!this.edit && !this.cardSelected" (selectedOrder)="selectOrder($event)">

</app-order-picker>
