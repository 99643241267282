import { Component, OnInit } from '@angular/core';
import { NavItem } from 'src/app/models/nav-item';
import { version } from '../../../../../package.json';

@Component({
  selector: 'app-side-navbar-menu',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit {

  navItems: NavItem[] = [
    {
      displayName: 'Tableau de bord',
      iconName: 'dashboard',
      route: '',
    },
    {
      displayName: 'Clients',
      iconName: 'assignment_ind',
      access : 'admin',
      children: [
        {
          displayName: 'Ajouter un client',
          iconName: 'add_box',
          route: 'clients/new',
        },
        {
          displayName: 'Tous les clients',
          iconName: 'group',
          route: 'clients',
        }
      ]
    },
    {
      displayName: 'Bons de Commande',
      iconName: 'chrome_reader_mode',
      access : 'admin',
      children: [
        {
          displayName: 'Saisir un bon de commande',
          iconName: 'create',
          route: 'orders/new',
        },
        {
          displayName: 'Tous les bons de commande',
          iconName: 'chrome_reader_mode',
          route: 'orders',
        }
      ]
    },
    {
      displayName: 'Interventions',
      iconName: 'assignment',
      children: [
        {
          displayName: 'Saisir une intervention',
          iconName: 'create',
          route: 'interventions/new',
        },
        {
          displayName: 'Toutes les interventions',
          iconName: 'assignment',
          route: 'interventions',
        }
      ]
    },
    {
      displayName: 'Pièces',
      access : 'admin',
      iconName: 'build',
      route: 'interventions/parts'
    },
    {
      displayName: 'Objets',
      iconName: 'inbox',
      access : 'admin',
      children: [
        {
          displayName: 'Nouvel objet',
          access: 'super-admin',
          iconName: 'save_alt',
          route: 'interventions/item_types/new',
        },
        {
          displayName: 'Tous les objets',
          iconName: 'style',
          route: 'interventions/item_types',
        }
      ]
    },
    {
      displayName: 'Utilisateurs',
      iconName: 'perm_identity',
      access : 'admin',
      children: [
        {
          displayName: 'Ajouter un utilisateur',
          iconName: 'person_add',
          route: 'users/new',
        },
        {
          displayName: 'Tous les utilisateurs',
          iconName: 'supervised_user_circle',
          route: 'users',
        }
      ]
    },
    {
      displayName: 'Paramètres',
      access : 'admin',
      iconName: 'settings',
      route: 'settings',
    },
    /*{
      displayName: 'Quitter',
      iconName: 'exit_to_app',
      route: 'exit',
    }*/
  ];

  constructor() { }

  ngOnInit() {
  }

  getVersion(){
    return ""+version;
  }

}
