import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, Subscribable, Subscription } from 'rxjs';
import { map, tap, startWith, debounceTime, scan, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.css']
})
export class SearchContainerComponent implements OnInit, OnDestroy {
  constructor() { }
  @Input() searchColumns: Array<string> = [];
  @Input() dataStream: Observable<any> = null;
  @Output() searchableDataChanged = new EventEmitter();

  formControl = new FormControl('');
  filters$: Observable<string> = this.formControl.valueChanges.pipe(startWith(''), debounceTime(300));
  data$: Observable<any> = null;
  dataRequest: Subscription = null;
  /**
   * Filter function can be changed, but there is no actual need
   * Unless you want to do some really custom stuff
   */
  @Input() filterFn = (row, data, searchString) => {
    if (searchString.length <= 0) {
      return true;
    }

    return this.searchColumns.map( c => {
      let columnData = row[c];
      if (typeof columnData !== 'string') {
        if(typeof columnData === 'undefined' || columnData === null){
          columnData = '';
        } else {
          columnData = columnData.hasOwnProperty('toString') ? columnData.toString() : `${columnData}`;
        }
      }
      return (columnData as string).toLowerCase().search(searchString.toLowerCase()) > -1;
    }).some( v => !!v);
  }

  ngOnInit() {
    this.data$ = this.getFilteredData();
    this.dataRequest = this.data$.subscribe();
  }
  ngOnDestroy() {
    if (this.dataRequest !== null) {
      this.dataRequest.unsubscribe();
    }
  }

  getFilteredData() {
    return combineLatest(this.dataStream.pipe(scan( (acc, v) => acc.concat(v), [] ),distinctUntilChanged()), this.filters$).pipe(
      map( ([ data, searchString]) => {
        return data.filter( row => this.filterFn(row, data, searchString))
      }),
      tap( filteredData => this.searchableDataChanged.emit(filteredData) )
    );
  }

}
