import { Component, OnInit, Input } from '@angular/core';
import { _itemType } from 'src/app/models/DB';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { Item } from 'src/app/models/Item';
import { from } from 'rxjs';
import { NotificationService, Notification } from 'src/app/services/notification.service';

@Component({
  selector: 'item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.css']
})
export class ItemFormComponent implements OnInit {
  @Input() item = null;
  @Input() editItem = false;

  model:_itemType = null;
  constructor(private api: APIDatasService, private notifs: NotificationService) { }

  ngOnInit() {
    if(this.item != null){
      this.buildModel(this.item)
      }else{
      this.buildModel(this.buildEmptyModel());
    }
  }

  buildModel(item){
    this.model = item;
  }

  buildEmptyModel(){
    return Item.build({
      "code"           : "",
      "name"           : "",
      "locations"      : [],
      "types"        : []
    })
  }

  get form(){
    return {
      ...this.model,
    }
  }

  createItem(){
    let observable  = from([]);

    if(this.editItem){
      observable = this.api.update(`interventions/item_types/${this.item._id}`,this.item)
    }else{
      observable = this.api.create("interventions/item_types",this.form)
    }

    observable.subscribe(
      res => {
      },
      error => {
        this.notifs.error(new Notification(error.error.message));
      },
      () => {
        var message = "Objet " + ((this.model._id && this.model._id != "") ?"mis à jour" : "enregistré") + " avec succès"
        if(this.model._id && this.model._id != ""){
          this.notifs.notifyandredirect(new Notification(message), 'interventions/item_types/')
        } else {
          this.notifs.notifyandredirect(new Notification(message), 'interventions/item_types/edit/'+ this.model._id)
        }

    });
  }
  clear(){
    if(this.item != null) {
      this.buildModel(this.item)
    } else {
      this.buildModel(this.buildEmptyModel())
    }
  }

}
