import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { _intervention } from 'src/app/models/DB';
import { Observable, from } from 'rxjs';
import { PDFFile } from 'src/app/models/order';
import { size } from 'lodash';

export interface deletedFileSignal {
  file : PDFFile
  key : string
}

@Component({
  selector: 'pdf-uploader',
  templateUrl: './pdf-uploader.component.html',
  styleUrls: ['./pdf-uploader.component.scss']
})
export class PDFUploaderComponent implements OnInit, OnChanges {
  @Output() changedFileList = new EventEmitter<PDFFile[]>();
  @Output() deletedFile = new EventEmitter<deletedFileSignal>();
  @Input() files = [];
  @Input() limit = -1;

  uploading = false

  constructor(private api: APIDatasService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.files){
      const newModel: SimpleChange = changes.files;
      this.files = [...newModel.currentValue ?? []];
    }

    if(changes.limit){
      const newLimit: SimpleChange = changes.limit;
      this.limit = newLimit.currentValue;
    }
  }

  ngOnInit() {
  }

  disabled() {
    return this.limit < 0 ? false : this.files.length >= this.limit
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      let limit = (this.limit == -1 ? filesAmount : this.limit - this.files.length);
      let promises = [];
      for (let i = 0; i < limit; i++) {
        var reader = new FileReader();
        this.uploading = true;
        promises.push(new Promise(resolve => {
          reader.onload = (readerEvent: any) => {
            this.handleFile(event.target.files[i], readerEvent.target).then(file => {
              this.files.push(file);
              this.outputList();
            }).then(() => resolve())
          }
        }))
        Promise.all(promises).then(() => {
          this.uploading = false;
        })
        reader.readAsDataURL(event.target.files[i]);
      }

    }
  }
  removeUpload(index) {
    let observable = from([true]);//create empty observable and replace him if needed
    if (this.files[index].url.startsWith("http")) {
      const filename = this.files[index].url.split('/').pop()
      this.deletedFile.emit({
        file : this.files[index],
        key  : filename,
      })
    }

    observable.subscribe({
      next: res => {
        this.files = this.files.filter((u, i) => i != index)
        this.outputList();
      }
    });
  }

  setData(list) {
    this.files = list;
  }

  private outputList() {
    this.changedFileList.emit(this.files);
  }

  handleFile(event, target) {
    return new Promise((res, rej) => {
      let file : PDFFile;
      file = {
        name  : event.name,
        url   : target.result,
        size  : event.size
      }

      res(file)
    })
  }

  getFileSizeStr(size : number, unit : string = "o") : string{
    let nextUnit = unit;

    if(size > 1024){
      switch(unit) {
        case "o" :  nextUnit = "Ko";
                    break;
        case "Ko" : nextUnit = "Mo";
                    break;
        case "Mo" : nextUnit = "Go";
                    break;
        case "Go" : nextUnit = "To";
                    break;
        default   : nextUnit = "";
                    break;
      }

      return this.getFileSizeStr(size / 1024, nextUnit);
    } else {
      return size.toFixed(1) + " " + nextUnit;
    }
  }
}
