import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { Order } from 'src/app/models/order';
import * as moment from 'moment';

@Component({
  selector: 'app-order-edit-view',
  templateUrl: './order-edit-view.component.html',
  styleUrls: ['./order-edit-view.component.css']
})
export class OrderEditViewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private api : APIDatasService) { }
  id: string;
  private sub: any;
  order : Order;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.api.getDatas("orders/" + this.id).subscribe(
        res => {
          this.order = Order.build(res);
        },
        err => {
          console.error("Error finding order id : "+ this.id);
        }
      );
   });
  }

}
