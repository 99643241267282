import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgModule } from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AgmCoreModule } from '@agm/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {NgxMaskModule, IConfig} from 'ngx-mask'


import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatDateFormats, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionPanel, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNavList, MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';

import { MainViewComponent } from './views/main-view/main-view.component';
import { AuthViewComponent } from './views/auth-view/auth-view.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClientAddViewComponent } from './views/clients/client-add-view/client-add-view.component';
import { ClientEditViewComponent } from './views/clients/client-edit-view/client-edit-view.component';
import { ClientListViewComponent } from './views/clients/client-list-view/client-list-view.component';
import { InterventionListViewComponent } from './views/interventions/intervention-list-view/intervention-list-view.component';
import { InterventionAddViewComponent } from './views/interventions/intervention-add-view/intervention-add-view.component';
import { InterventionEditViewComponent } from './views/interventions/intervention-edit-view/intervention-edit-view.component';
import { OrderAddViewComponent } from './views/orders/order-add-view/order-add-view.component';
import { OrderListViewComponent } from './views/orders/order-list-view/order-list-view.component';
import { OrderEditViewComponent } from './views/orders/order-edit-view/order-edit-view.component';
import { UserAddViewComponent } from './views/users/user-add-view/user-add-view.component';
import { UserListViewComponent } from './views/users/user-list-view/user-list-view.component';
import { UserEditViewComponent } from './views/users/user-edit-view/user-edit-view.component';

import { SidebarListItemComponent } from './components/main-sidebar/sidebar-list-item/sidebar-list-item.component';
import { SideNavbarComponent } from './components/main-sidebar/side-navbar/side-navbar.component';
import { InterFormComponent } from './components/interventions-form/inter-form-items/inter-form-items.component';
import { InterFormItemComponent } from './components/interventions-form/inter-form-items/inter-form-item/inter-form-item.component';
import { MatOptgroupComponent } from './components/mat-optgroup/mat-optgroup.component';
import { SignaturePadComponent } from './components/interventions-form/signature-pad/signature-pad.component';
import { HourLogFormComponent } from './components/interventions-form/hour-log-form/hour-log-form.component';
import { FocusableDirective } from './components/interventions-form/hour-log-form/focusable.directive';
import { EditableComponent } from './components/interventions-form/hour-log-form/editable/editable.component';
import { EditableOnEnterDirective } from './components/interventions-form/hour-log-form/editable/edit-on-enter.directive';
import { ViewModeDirective } from './components/interventions-form/hour-log-form/editable/view-mode.directive';
import { EditModeDirective } from './components/interventions-form/hour-log-form/editable/edit-mode.directive';
import { OrderFormComponent, PhoneDirective } from './components/order-form/order-form.component';
import { GooglemapsKmComponent } from './components/interventions-form/googlemaps-km/googlemaps-km.component';
import { AgmDirectionModule } from 'agm-direction';
import { PartsFormComponent } from './components/interventions-form/parts-form/parts-form.component';
import { NewPartModalComponent } from './components/interventions-form/parts-form/new-part-modal/new-part-modal.component'
import { HeaderInterceptor } from './interceptors/HeaderInterceptor';
import { AuthTokenInterceptor } from './interceptors/AuthTokenInterceptor';
import { PartsTableComponent } from './components/interventions-form/parts-form/parts-table/parts-table.component';
import { ImageUploaderComponent } from './components/interventions-form/image-uploader/image-uploader.component';
import { OrderPickerComponent } from './components/interventions-form/order-picker/order-picker.component';
import { InterventionsFormComponent } from './components/interventions-form/interventions-form.component';
import { ListingFormComponent, ConfirmDeleteDialog } from './components/listing-form/listing-form.component';
import { AbstractListViewComponent } from './views/abstract-list-view/abstract-list-view.component';
import { ItemAddViewComponent } from './views/items/item-add-view/item-add-view.component';
import { ItemListViewComponent } from './views/items/item-list-view/item-list-view.component';
import { ItemEditViewComponent } from './views/items/item-edit-view/item-edit-view.component';
import { ClientFormComponent } from './components/client-form/client-form.component';
import { ItemFormComponent } from './components/item-form/item-form.component';
import { UserFormComponent } from './components/user-form/user-form.component';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { RouterModule } from '@angular/router';

import { LocationFormComponent } from './components/item-form/location-form/location-form.component';
import { TargetFormComponent } from './components/item-form/target-form/target-form.component';
import { ItemChildComponent } from './components/item-form/item-child/item-child.component';
import { RemarksFormComponent } from './components/interventions-form/remarks-form/remarks-form.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { ReportComponent, ReportDialog } from './views/interventions/report/report.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SettingsListViewComponent } from './views/settings/settings-list-view/settings-list-view.component';
import { PartsViewComponent } from './views/parts-view/parts-view.component';
import { PartFormAddeditComponent } from './components/part-form-addedit/part-form-addedit.component';
import { AppLoadModule } from './app-load-module/app-load.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { UserGuardComponent } from './components/user-guard/user-guard.component';
import { TrsPathmapComponent } from './components/interventions-form/googlemaps-km/trs-pathmap/trs-pathmap.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { ExitComponent } from './views/exit/exit.component';
import { BaseTableComponent } from './views/interventions/intervention-list-view/components/base-table/base-table.component';
import { SearchableTableComponent } from './views/interventions/intervention-list-view/components/searchable-table/searchable-table.component';
import { FilteredTableComponent } from './views/interventions/intervention-list-view/components/filtered-table/filtered-table.component';
import { FilterContainerComponent } from './views/interventions/intervention-list-view/components/filtered-table/filter-container/filter-container.component';
import { SearchContainerComponent } from './views/interventions/intervention-list-view/components/searchable-table/search-container/search-container.component';
import { FilteredSearchableTableComponent } from './views/interventions/intervention-list-view/components/filtered-searchable-table/filtered-searchable-table.component';
import { StackedButtonsComponent } from './components/stacked-buttons/stacked-buttons.component';
import { PDFUploaderComponent } from './components/pdf-uploader/pdf-uploader.component';


export const MY_FORMAT: MatDateFormats = {
  parse: {
  dateInput: 'DD/MM/YYYY',
  },
  display: {
  dateInput: 'DD/MM/YYYY',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'DD/MM/YYYY',
  monthYearA11yLabel: 'MMMM YYYY',
  },
  };


@NgModule({
  declarations: [
    AppComponent,
    MainViewComponent,
    AuthViewComponent,
    LoginFormComponent,
    ClientAddViewComponent,
    ClientEditViewComponent,
    ClientListViewComponent,
    InterventionListViewComponent,
    InterventionAddViewComponent,
    InterventionEditViewComponent,
    OrderAddViewComponent,
    OrderListViewComponent,
    OrderEditViewComponent,
    UserAddViewComponent,
    UserListViewComponent,
    UserEditViewComponent,
    SidebarListItemComponent,
    SideNavbarComponent,
    InterFormComponent,
    InterFormItemComponent,
    MatOptgroupComponent,
    SignaturePadComponent,
    HourLogFormComponent,
    FocusableDirective,
    EditableOnEnterDirective,
    PhoneDirective,
    EditableComponent,
    HourLogFormComponent,
    ViewModeDirective,
    EditModeDirective,
    GooglemapsKmComponent, 
    PartsFormComponent,
    NewPartModalComponent,
    PartsTableComponent,
    ImageUploaderComponent,
    PDFUploaderComponent,
    OrderPickerComponent,
    OrderFormComponent,
    InterventionsFormComponent,
    ListingFormComponent,
    ConfirmDeleteDialog,
    AbstractListViewComponent,
    ItemAddViewComponent,
    ItemListViewComponent,
    ItemEditViewComponent,
    RemarksFormComponent,
    CheckboxGroupComponent,
    ClientFormComponent,
    ItemFormComponent,
    UserFormComponent,
    LocationFormComponent,
    TargetFormComponent,
    ItemChildComponent,
    ReportComponent,
    SpinnerComponent,
    ItemChildComponent,
    SettingsListViewComponent,
    ReportDialog,
    PartsViewComponent,
    PartFormAddeditComponent,
    UserGuardComponent,
    TrsPathmapComponent,
    ExitComponent,

    BaseTableComponent,
    SearchableTableComponent,
    FilteredTableComponent,
    FilteredSearchableTableComponent,
    FilterContainerComponent,
    SearchContainerComponent,
    StackedButtonsComponent,
  ],
  imports: [
    AppLoadModule,
    CKEditorModule,
    FlexLayoutModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatMenuModule,
    HttpClientModule,
    MatListModule,
    MatStepperModule,
    MatSelectModule,
    MatExpansionModule,
    MatTableModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    DragDropModule,
    AgmCoreModule.forRoot({
      apiKey  : environment.GAPS_KEY
    }),
    AgmDirectionModule,
    MatDialogModule,
    MatSortModule,
    RouterModule,
    MatPasswordStrengthModule,
    CdkStepperModule,
    MatPaginatorModule,
    NgxMaskModule.forRoot({}),
    MatTooltipModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-right',
        progressBar: true,
        timeOut : 3000,
        preventDuplicates : true
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-CH' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NewPartModalComponent,
    ConfirmDeleteDialog,
    ReportDialog
  ]
})
export class AppModule { }
