import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/Users';
import { ActivatedRoute } from '@angular/router';
import { APIDatasService } from 'src/app/services/apidatas.service';

@Component({
  selector: 'app-user-edit-view',
  templateUrl: './user-edit-view.component.html',
  styleUrls: ['./user-edit-view.component.css']
})
export class UserEditViewComponent implements OnInit {
  id: string;
  private sub: any;
  user : User;

  constructor(private route: ActivatedRoute, private api : APIDatasService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.api.getDatas("users/" + this.id).subscribe(
        res => {
          this.user = new User();
          this.user = User.build(res);
        },
        err => { 
          console.error("Error finding client id : "+ this.id);
        }
      );
   });
  }
}
