import { Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges, Directive } from '@angular/core';
import { Order, OrderContract, PDFFile } from 'src/app/models/order';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { NotificationService, Notification } from 'src/app/services/notification.service';
import { FormControl, Validators, NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { MapMarker } from 'src/app/models/mapMarker';
import { Subject } from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import { Moment } from 'moment';
import { deletedFileSignal } from '../pdf-uploader/pdf-uploader.component';

const EMAIL_REGEX = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"

@Component({
  selector: 'order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.css']
})
export class OrderFormComponent implements OnInit, OnChanges {
  @Input() order : OrderContract = null;
  clients = []
  additionalFields = []
  hasValidAddress = true;
  typedAddress : Subject<string> = new Subject<string>();

  phoneValidation = new FormControl( '', Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'));

  model:OrderContract = null;
  constructor(private api: APIDatasService,  private notifs: NotificationService, private maps : MapsAPILoader) {
    this.typedAddress.pipe(
          debounceTime(300))
          .subscribe(() => this.checkAddress());
   }

   coordinates : MapMarker = MapMarker.buildEmptyModel()

  ngOnInit() {
    if(this.order != null){
      this.buildModel(this.order)
      this.coordinates = this.model.coords
      }else{
      this.buildModel(Order.buildEmptyModel());
    }


    this.fetchClients()
  }
  ngOnChanges(changes: SimpleChanges){
    const newModel: SimpleChange = changes.order;
    if(newModel.currentValue){
      this.buildModel(newModel.currentValue)
    }else{
      this.buildModel(Order.buildEmptyModel())
    }
  }

  buildModel(order){
    this.model = order;
    this.additionalFields = [];
    Object.keys(order.additional).map(k => this.addAdditionalField(k, order.additional[k]))

  }
  fetchClients(){
    this.api.getDatas("/clients").subscribe({
      next: (d) => this.clients = d
    })
  }

  addAdditionalField(key, value){

    this.additionalFields.push({
      fieldName: key,
      value: value
    })
  }

  getAdditionalFields(){
    return this.additionalFields
    .reduce( (acc, f) => {
      if(f.fieldName.length > 0 && f.value.length > 0){
        acc[f.fieldName] = f.value
      }
      return acc
    }, {})
  }
  removeAdditionalField(index){
    this.additionalFields = this.additionalFields.filter( (_,i) => index != i)
  }

  get form(){
    return {
      ...this.model,
      additional: this.getAdditionalFields(),
      coords : (this.coordinates)?this.coordinates : MapMarker.buildEmptyModel()
    }
  }

  typingAddress(){
    this.typedAddress.next("");
  }

  checkAddress() {

    if((!this.model.intervention_building.city || !this.model.intervention_building.address) ||
        this.model.intervention_building.city=="" || this.model.intervention_building.address==""){
      this.hasValidAddress = false;
      return;
    }

    this.maps.load().then(() => {
      this.findLocation(this.model.intervention_building.address + ", " + this.model.intervention_building.zip + " " +this.model.intervention_building.city).then((marker) =>{
        this.hasValidAddress = (marker!=null);
      })

    })
  }

  findLocation(address) : Promise<MapMarker> {
    return this.maps.load().then(() => {
      var geocoder = new google.maps.Geocoder();

      return new Promise<MapMarker> ((resolve) =>
        geocoder.geocode({
        'address': address
      }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
          this.coordinates = MapMarker.build({lat : results[0].geometry.location.lat(), lng :  results[0].geometry.location.lng(), draggable : false});
          resolve({lat : results[0].geometry.location.lat(), lng :  results[0].geometry.location.lng(), draggable : false});
        } else {
          this.coordinates = MapMarker.buildEmptyModel();
          resolve(null);
        }
      })
      );
   });
  }

  dateChanged($event, key){
    var date : Moment = $event.target._value;
    this.model[key] = date.format('YYYY-MM-DD');
  }

  createOrder(){
    let observable = null;

    //return;
    if(this.order!=null){
      observable =this.api.update(`/orders/${this.order._id}`, this.form);
    }
    else{
      observable =this.api.create("/orders", this.form);
    }

    observable.subscribe(
      res => {
        this.model = res;
      },
      error => {
        this.notifs.error(new Notification(error.error.message));
      },
      () => {
        var message = "Bon de commande " + ((this.order && this.order._id != "") ?"mis à jour" : "enregistré") + " avec succès"

        if(this.order && this.order._id != ""){
          this.notifs.notifyandredirect(new Notification(message), 'orders')
        } else {
          this.notifs.notifyandredirect(new Notification(message), 'orders/edit/'+ this.model._id)
        }
    });
  }

  clear(){
    this.additionalFields = [];
    if(this.order != null) {
      this.buildModel(this.order)
    } else {
      this.buildModel(Order.buildEmptyModel())
    }
  }

  deletePDFFromOrder(deletedFile : deletedFileSignal) {
    //this.api.delete(`orders/${this.order._id}/management_voucher`, deletedFile.key).subscribe()
    this.model.management_voucher = this.model.management_voucher.filter((elem : PDFFile) => {
      return elem.url != deletedFile.file.url;
    })


  }

  onPDFChange(files : PDFFile[]) {
    if(files.length > 0){
      this.model.management_voucher = files;

    }
  }
}

@Directive({
  selector: '[phone]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PhoneDirective, multi: true }
  ]
})
export class PhoneDirective implements Validator {
  constructor() {}

  validate(c: AbstractControl): { [key: string]: any } {

    if (c.value && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/gm.test(c.value)) {
      return {
        phone: 'Invalid phone number'
      };
    }

    return null;
  }
}

