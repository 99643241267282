import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/models/order';
import { APIDatasService } from 'src/app/services/apidatas.service';
import { ListingOptions } from 'src/app/components/listing-form/listing-form.component';
import { AbstractListViewComponent } from '../../abstract-list-view/abstract-list-view.component';

@Component({
  selector: 'app-order-list-view',
  templateUrl: './order-list-view.component.html',
  styleUrls: ['./order-list-view.component.css']
})
export class OrderListViewComponent extends AbstractListViewComponent implements OnInit {

  constructor(protected api : APIDatasService) {
    super(api);
    this.listingOptions  =  {
      "type_options"  : {
        "base_route" : "orders",
        "type_name" : "Commandes",
       
        "param_routes":{
          "paginate" : 1,
          "include[]": [
            "client"
          ]
        }
      },
      "table_options" : [
        //{"label" : "Attribué", "property_name" : "attributed"},
        {"label" : "Créé le", "property_name" : "created_at"},
        {"label" : "N° CC", "property_name" : "code_confirmation"},
        {"label" : "Adresse", "property_name" : "address"},
        {"label" : "Ville", "property_name" : "location"},
        {"label" : "Client", "property_name" : "client_name"},
        {"label" : "Personne", "property_name" : "tenant_name"}
      ],
      "page_options" : {
        "current_page" : 0,
        "item_count"   : 0,
        "per_page"     : 10
      },
      "showCheckbox":true
    };
   }

  createOneDataRecord(index, array){
    this.finishedLoading = false;
    var tmp = array[index];
    /*return this.api.getDatas("clients/"+tmp.client_id).toPromise().then(
      client => {
        tmp["client_name"] = client.name;
      }).then( ()=>{
        tmp["tenant_name"]  = tmp.intervention_building.tenant.name;
        tmp["address"]      = tmp.intervention_building.address;
        tmp["location"]     = tmp.intervention_building.zip + " " + tmp.intervention_building.city;
        return tmp
    } );*/
        tmp["attributed"] = tmp.attributed ?? false;
        tmp["client_name"]  = tmp.client ? tmp.client.name ? tmp.client.name : "" : "";
        tmp["tenant_name"]  = tmp.intervention_building.tenant.name;
        tmp["address"]      = tmp.intervention_building.address;
        tmp["location"]     = tmp.intervention_building.zip + " " + tmp.intervention_building.city;
        return tmp

  }
  handleCheckboxChange(event) {
    console.log(event); // handle checkbox state change
  }

}
