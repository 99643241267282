import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { BehaviorSubject, Subject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'searchable-table',
  templateUrl: './searchable-table.component.html',
  styleUrls: ['./searchable-table.component.css']
})
export class SearchableTableComponent extends BaseTableComponent implements OnInit, OnDestroy {

  @Input() searchColumns: Array<string> = [];
  public shouldPushData = true;

  data$ = new BehaviorSubject(null);
  formControl = new FormControl('');
  filters$: Observable<string> = this.formControl.valueChanges;
  /**
   * Filter function can be changed, but there is no actual need
   * Unless you want to do some really custom stuff
   */
  @Input() filterFn = (row, data, searchString) => {
    this.searchColumns.forEach( c => {
      let columnData = row[c]
      if(typeof columnData !== 'string'){
        columnData = columnData.toString(); //TODO might fail, so check dummy cases
      }
      return columnData.search(searchString)
    })
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.data$.unsubscribe();
    super.ngOnDestroy();
  }

  getDataStream() {
    return this.dataStream.pipe( finalize( () => {
      this.shouldPushData = false;
    }));
  }

  onSearchableDataChange($event) {
    this.data$.next($event);
  }

}
