import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
  exportAs: 'ngForm'
})
export class LoginFormComponent implements OnInit {

  constructor() { }
  msgToShow : string;
  showSpinner : boolean = false;

  @Output()
  formResponse = new EventEmitter<any>();

  ngOnInit() {
    this.msgToShow = "";
  }
  /**
   * author : R. Fournier
   * goal   : Submit the login form to the API for login
   *
   * parameters : form: the form containing the username and the password
   */
  submitLogin(form){

    this.submitComplete(form)
    //form.reset();
  }
  /**
   * author : R. Fournier
   * goal   : Show a message to the user (error)
   *
   * parameters : signal: the message to print
   */
  showMessage(signal : any){
    this.msgToShow = signal;
  }

  submitComplete(form) {
    this.formResponse.emit(form.value);
  }

  login(form){
    form.submit();
  }
}
