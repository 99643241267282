
export interface HourLogInterface {
    date: string;
    hour_start: string;
    hour_end: string;
    employee_id: string;
    duration: string;
}

export class HourLog implements HourLogInterface{
    date;
    hour_start;
    hour_end;
    employee_id;
    duration;
    static build(d){
        let m = new HourLog;
        m.date = d.date;
        m.hour_start = d.hour_start;
        m.hour_end = d.hour_end;
        m.employee_id = d.employee_id;
        m.duration = d.duration || 0;
        return m;
    }
}
