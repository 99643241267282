<div class="page-header" style="background: linear-gradient(90deg, #c0392b, #e74c3c);">
  <h1>
      Paramètres
  </h1>
</div>
<div class="main settings-theme" fxLayout="row">
  <div fxFlex="40" style="padding: 0px 10px" *ngIf="finishedLoading">
      <mat-card class="presentation-block" style="background: #c0392b;">
          <div class="icon" >
              <mat-icon>settings</mat-icon>
          </div>
          <div class="content">
              <h4>Paramètres</h4>
              <p>Cette vue sert à modifier les paramètres de l'application</p>
          </div>
      </mat-card>
  </div>
  <div fxFlex="60" *ngIf="!finishedLoading" style="text-align: center">
      <trs-spinner [elem]="'client'"></trs-spinner>
  </div>
  <div fxFlex="60" style="padding: 0px 10px" *ngIf="finishedLoading">
      <mat-card class="Settings settings-theme">
          <div *ngFor="let item of settings; let i = index" class="example-box">
              <div class="setting-list-container" *ngIf="shouldDisplaySetting(item)">
                <span>
                {{item.name}}
                </span>
                <mat-form-field *ngIf="item.type == 'text'">
                  <input matInput type="text" [(ngModel)]="settings[i].value" name="item.key" />
                </mat-form-field>
                <mat-form-field *ngIf="item.type == 'numeric'">
                  <input matInput type="number" [(ngModel)]="settings[i].value" name="item.key" min=0 />
                </mat-form-field>
                <ckeditor *ngIf="item.type == 'rich-text'" class="content" [editor]="Editor" [(ngModel)]="settings[i].value" name="item.key"></ckeditor>
                <mat-checkbox *ngIf="item.type == 'boolean'" [(ngModel)]="settings[i].value" name="item.key"></mat-checkbox>
              </div>
              <div class="setting-list-container" *ngIf="!shouldDisplaySetting(item)">
                <user-guard is="super-admin">
                  <span>
                  {{item.name}}
                  </span>
                  <mat-form-field *ngIf="item.type == 'text'">
                    <input matInput type="text" [(ngModel)]="settings[i].value" name="item.key" />
                  </mat-form-field>
                  <mat-form-field *ngIf="item.type == 'numeric'">
                    <input matInput type="number" [(ngModel)]="settings[i].value" name="item.key" min=0 />
                  </mat-form-field>
                  <mat-form-field *ngIf="item.type == 'other'">
                    <textarea matInput [(ngModel)]="settings[i].value" name="item.key" ></textarea>
                  </mat-form-field>
                  <ckeditor *ngIf="item.type == 'rich-text'" class="content" [editor]="Editor" [(ngModel)]="settings[i].value" name="item.key"></ckeditor>
                  <mat-checkbox *ngIf="item.type == 'boolean'" [(ngModel)]="settings[i].value" name="item.key"></mat-checkbox>
                </user-guard>

              </div>
            </div>


            <button class="save" color="primary" (click)="save()" mat-raised-button>Sauvegarder</button>
      </mat-card>
  </div>
  <div fxFlex="60" *ngIf="!finishedLoading" style="text-align: center">
      <trs-spinner [elem]="'client'"></trs-spinner>
  </div>
</div>
