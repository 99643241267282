<mat-card style="margin-top : 20px">
    <h4>Ajouter une opération</h4>
    <form #addItemForm="ngForm">
        <div fxLayout="row">
            <div fxFlex="100">
                <mat-form-field class="fullwidth">
                    <mat-label>Type objet</mat-label>
                    <mat-select name="itemtype" [(ngModel)]="typeID" (selectionChange)="this.onItemTypeSelection()"
                        required>
                        <mat-option value="{{it._id}}" *ngFor="let it of this.itemTypes; let i = index;" style="float: left;">
                            {{it.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row">
            <div fxFlex="100" *ngIf="this.itemLocations.length && typeID != '620ba2b91a6cba66a64aa3b5'">
                <mat-form-field *ngIf="this.itemLocations.length" class="fullwidth">
                    <mat-label>Lieu</mat-label>
                    <mat-select multiple name="location" panelClass="trs-place-select" [(ngModel)]="locationID"
                        (selectionChange)="this.onItemLocationSelect()" required>
                        <ng-container *ngFor="let loc of this.itemLocations; let i = index;">
                            <app-mat-optgroup label="{{loc.name}}" class="mat-optgroup"
                                *ngIf="loc.children && loc.children.length">
                                <mat-option value="{{childLoc._id}}" *ngFor="let childLoc of loc.children; let ci = index;">
                                    {{childLoc.name}}
                                </mat-option>
                            </app-mat-optgroup>
                            <mat-option value="{{loc._id}}" *ngIf="!(loc.children && loc.children.length)">
                                {{loc.name}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row">
            <div fxFlex="0" *ngIf="this.categories.length && typeID != '620ba2b91a6cba66a64aa3b5'">
                <mat-form-field *ngIf="this.categories.length" class="fullwidth">
                    <mat-label>Type d'intervention</mat-label>
                    <mat-select multiple name="category" panelClass="trs-place-select" [(ngModel)]="selectedCatsIDs" required>
                        <ng-container *ngFor="let cat of this.categories; let i = index;">
                            <app-mat-optgroup label="{{cat.name}}" class="mat-optgroup"
                                *ngIf="cat.targets && cat.targets.length">
                                <mat-option value="{{childCat._id}}" *ngFor="let childCat of cat.targets; let ci = index;">
                                    {{childCat.name}}
                                </mat-option>
                            </app-mat-optgroup>
                            <mat-option value="{{cat._id}}" *ngIf="!(cat.targets && cat.targets.length)">
                                {{cat.name}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" *ngIf="typeID == '620ba2b91a6cba66a64aa3b5'">
            <div fxFlex="0" *ngIf="typeID == '620ba2b91a6cba66a64aa3b5'">
                <mat-form-field *ngIf="typeID == '620ba2b91a6cba66a64aa3b5'" class="fullwidth" appearance="fill">
                    <mat-label>Explication</mat-label>
                    <textarea matInput [(ngModel)]="description" name="description" placeholder="Description de l'intervention...">{{description}}</textarea>
                </mat-form-field>
            </div>
        </div>
        <button style="height : 36px;" mat-raised-button color="primary" (click)="this.addItem(addItemForm.form)"
            *ngIf="this.categories.length" [disabled]="addItemForm.invalid">ajouter</button>

    </form>
</mat-card>

<mat-accordion>
    <div *ngFor="let item of this.items; let i = index;">
        <app-inter-form-item  [(item)]="this.items[i]" (itemDeletion)="removeItem(i)"
            (itemChange)="test(i)"></app-inter-form-item>
    </div>
</mat-accordion>
