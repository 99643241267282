import { Injectable, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthResponse } from '../models/authresponse';
import { environment } from 'src/environments/environment';

interface UserInterface{
  username: string;
  _id: string;
}

class User implements UserInterface{
  username;
  _id;
  static build(data){
    const user = new User();
    user.username = data.username;
    user._id = data._id;
    return user;
  }
}

const BASE_URL = environment.BASE_URL;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  get isLoggedIn$(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  private isLoggedIn = new BehaviorSubject(false);

  constructor(private httpcli: HttpClient, private router: Router) {
    //set values by default
    if (!this.isAuth()) {
      this.logOut();
    } else {
      this.isLoggedIn.next(true);
      this.router.navigate(['']);
    }
  }

  /**
   * author : R. Fournier
   * goal   : Verifiy if the user is authetificated by recovering the token inside the local storage and that his
   *          username is valid
   *
   */
  isAuth() {
    const bAuth = this.getAuth() === 'true';
    const bToken = this.getToken() !== null && this.getToken() !== '' && this.getToken() !== 'undefined';
    const bUname = this.getUsername() !== null && this.getUsername() !== '' && this.getUsername() !== 'undefined';

    return (bAuth && bToken && bUname);
  }

  /**
   * author : R. Fournier
   * goal   : Resets value to de-auth
   *
   */
  logOut() {
    this.signOut();
    this.setAuth(false);
    this.setToken('');
    this.storeUsername('');
    this.router.navigate(['login']);
    this.isLoggedIn.next(false);
  }

  checkConnectionWithAPI() {
    const bToken = this.getToken() !== null && this.getToken() !== '' && this.getToken() !== 'undefined';

    if(!bToken){
      return;
    }

    // HTTP Request headers
    let reqheaders = new HttpHeaders();
    reqheaders = reqheaders.append('Content-Type', 'application/json');
    reqheaders = reqheaders.append('Authorization', 'Bearer ' + this.getToken());
    reqheaders = reqheaders.append('Accept', '*/*');
    reqheaders = reqheaders.append('Cache-Control', 'no-cache');

    this.httpcli.get(BASE_URL+'/me', {headers: reqheaders}).subscribe(
        (res) => {},
        (error) => {
          console.error('Error verifying token', error);
          this.logOut();
          return false;
        },
        () => {
          console.info('Token checked : ok');
        });
  }

  signOut() {
    const token =  this.getToken();
    if (!this.isAuth()) {return;}
    return new Promise(
      (resolve, reject) => {
            //HTTP Request headers
            let reqheaders = new HttpHeaders();
            reqheaders = reqheaders.append('Content-Type', 'application/json');
            reqheaders = reqheaders.append('Authorization', 'Bearer ' + token);
            reqheaders = reqheaders.append('Accept', '*/*');
            reqheaders = reqheaders.append('Cache-Control', 'no-cache');

            this.httpcli
              .post<AuthResponse>(BASE_URL+'/logout', null,  {headers: reqheaders})
              .toPromise()
              .then(
                (res) => {
                  //request worked (in an HTTP way)
                  return resolve('Vous vous êtes bien déconnecté');
                },
                (msg) => {
                  // HTTP Request error
                  console.error(msg);
                  switch(msg.status){
                    default :
                        reject('HTTP ERROR (' + msg.statusText + ') : ' + msg.message);
                      break;
                  }

                }
              );
      }
    );
  }

  /**
   * author : R. Fournier
   * goal   : Send POST request to API and returns the results (via exceptions)
   *
   * parameters : userAccesses : object with {username : ..., password : ...}
   */
  signIn(userAccesses: {username:string; password:string}, caller) {
    return new Promise(
      (resolve, reject) => {
        setTimeout(
          () => {

            //HTTP Request headers
            const headers = new Headers();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Cache-Control', 'no-cache');
            headers.append('Content-Type', 'application/json');

            headers.append('Accept', '*/*');
            headers.append('Cache-Control', 'no-cache');
            //POST Request parameters
            const body = new HttpParams()
              .set('username', userAccesses.username)
              .set('password', userAccesses.password);

            this.httpcli
              .post<AuthResponse>(BASE_URL+'/login', body)
              .toPromise()
              .then(
                res => {
                  //request worked (in an HTTP way)
                    this.storeUsername(userAccesses.username);
                    this.storeUser(res);
                    this.setToken(res.access_token);
                    this.isLoggedIn.next(true);
                    return resolve(res.access_token);
                },
                msg => {
                  // HTTP Request error
                  console.error(msg);
                  switch(msg.status){
                    case 401:
                        reject('Utilisateur / Mot de passe incorrect');
                      break;
                    default :
                        reject('HTTP ERROR (' + msg.statusText + ') : ' + msg.message);
                      break;
                  }

                }
              );
          }, 100
        );
      }
    );
  }

  /**
   * author : R. Fournier
   * goal   : Store a boolean value to know if the user is authentificated
   *
   * parameters : a: boolean who say if the user is authentificated
   */
  setAuth(a: boolean) {
    localStorage.setItem('auth', a.toString());
  }
  /**
   * author : R. Fournier
   * goal   : Return the boolean value 'auth'
   *
   */
  getAuth() {
    return localStorage.getItem('auth');
  }
  /**
   * author : R. Fournier
   * goal   : Store the token returned by the API
   *
   * parameters : token: the token sent by the API
   */
  setToken(token) {
    localStorage.setItem('token', token);
  }
  /**
   * author : R. Fournier
   * goal   : Return the token stored in the localstorage
   *
   */
  getToken(): string {
    return localStorage.getItem('token');
  }
  /**
   * author : R. Fournier
   * goal   : Store the username of the user
   *
   * parameters : un: string who represent the username
   */
  storeUsername(un) {
    localStorage.setItem('uname', un);
  }
  /**
   * author : R. Fournier
   * goal   : Return the username stored in the localstorage
   *
   */
  getUsername() {
    return localStorage.getItem('uname');
  }
  storeUser(user){
    localStorage.setItem('user', user);
  }
  getUser() : UserInterface{
    return User.build(localStorage.getItem('user'));
  }
}
