<div class="page-header" style="background: linear-gradient(90deg, #2980B9, #3498DB);">
    <h1>
        Toutes les interventions
    </h1>
</div>
<div class="main interventions-theme" fxLayout="row">
    <filtered-searchable-table
        style="width: 100%;"
        [config]="tblConfig"
        [dataStream]="data$"
        [filters]="filters"
        [label]="'Toute les interventions'"
        [searchColumns]='["address", "client", "code", "created_at", "order_code"]'
    ></filtered-searchable-table>
</div>