<div>
    <form>
        <div fxLayout="row">
            <div fxFlex="40" style="padding: 0px 10px">
                <mat-card class="presentation-block" style="background: #16A085;">
                    <div class="icon">
                        <mat-icon>perm_identity</mat-icon>
                    </div>
                    <div class="content" *ngIf="item == null">
                        <h4>Ajout d'un Objet</h4>
                        <p>Cette vue sert à ajouter un nouvel objet, tout comme les emplacements <br /> dans
                            lesquels on
                            le trouve et les actions possibles dessus</p>
                    </div>
                    <div class="content" *ngIf="item != null">
                        <h4>Modifier objet : {{item.name}}</h4>
                        <p>Cette vue sert à modifier un objet, tout comme les emplacements <br /> dans
                            lesquels on le trouve et les actions possibles dessus</p>
                    </div>
                </mat-card>
            </div>
            <div fxFlex="60" style="padding: 0px 10px">
                <mat-card class="order-infos" style="margin-bottom: 20px;">
                    <h3 style="width: fit-content;display: inline-block; padding: 16px">Objet</h3>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Code de l'objet" [(ngModel)]="model.code" name="code">
                    </mat-form-field>
                    <mat-form-field class="fullwidth">
                        <input matInput placeholder="Nom de l'objet" [(ngModel)]="model.name" name="name">
                    </mat-form-field>
                </mat-card>
            </div>
        </div>
        <div>
            <div>
                <div>
                    <mat-card class="order-infos" style="margin-bottom: 20px;padding : 0px;">
                        <h3 style="width: fit-content;display: inline-block; padding: 16px">Catégories / Emplacements
                        </h3>
                        <location-form [(locations)]="model.locations"></location-form>
                    </mat-card>
                </div>
                <div>
                    <mat-card class="order-infos" style="margin-bottom: 20px;padding : 0px;">
                        <h3 style="width: fit-content;display: inline-block; padding: 16px">Types de travaux</h3>
                        <target-form [(types)]="model.types"></target-form>
                    </mat-card>

                </div>

            </div>
        </div>
        <div style="text-align : right; width: 95%;padding-top: 10px 0px;margin-bottom: 20px;">
            <button (click)="createItem()" mat-raised-button class="trs-button"
                color="accent">{{item != null ? 'Mettre a jour l\'objet' : 'Créer objet'}}</button>
            <button [routerLink]="['/interventions/item_types']" class="trs-button" mat-button color="accent">Annuler</button>
        </div>
    </form>
</div>