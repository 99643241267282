export interface ClientContract {
    _id?: string;
    name: string;
    person?: string;
    phone ?: string;
    mobile ?: string;
    address: string;
    zip: string;
    city: string;
    images: string[];
}

export class Client implements ClientContract {

    _id?: string;
    name: string;
    person?: string;
    phone ?: string;
    mobile ?: string;
    address: string;
    zip: string;
    city: string;
    images: string[];

    static build(data): ClientContract {
        const m = new Client();
        m._id = data._id;
        m.name = data.name;
        m.person = data.person || '';
        m.phone  = data.phone || '';
        m.mobile = data.mobile || '';
        m.images = data.images;
        m.address = data.address;
        m.zip = data.zip;
        m.city = data.city;
        m.images = data.images || [];
        return m;
    }

}
