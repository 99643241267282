<div class="parts-container">
    <div class="section-header" style="margin-bottom: 20px;">
        <button mat-flat-button color="primary" (click)="openDialog()">
            <mat-icon>playlist_add</mat-icon> créer nouvelle pièce
        </button>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div matCard fxFlex="100" style="margin: 5px;">
            <mat-card class="parts trs-card">
                <mat-card-header>
                    <div mat-card-avatar class="tp-header-image"></div>
                    <mat-card-title>Pièces de rechange</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <parts-table (partsChange)="onPartChange()" [service]="serviceSpare" [parts]="partMap">
                    </parts-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div matCard fxFlex="100"  style="margin: 5px;">
            <mat-card class="parts trs-card">
                <mat-card-header>
                    <div mat-card-avatar class="tp-header-image"></div>
                    <mat-card-title>Pièces à commander</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <parts-table (partsChange)="onPartChange()" [service]="serviceOrdered" [parts]="partMap">
                    </parts-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>