import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import {  MapMarker } from 'src/app/models/mapMarker';
import { SettingsService } from 'src/app/services/settings.service';


@Component({
  selector: 'trs-pathmap',
  templateUrl: './trs-pathmap.component.html',
  styleUrls: ['./trs-pathmap.component.css']
})
export class TrsPathmapComponent implements OnInit {

  _destination : MapMarker;
  dir: {
    origin: any,
    destination: any
  };
  
  @Input() imageMode : boolean = false;
  @Input() set destinationCoords(val : MapMarker){
    this._destination = val

    if(val){
      this.initDest();
      this.dir= Object.assign({
        origin      : this.depart,
        destination : this.destinationCoords
      }, this.dir)
      this.cd.detectChanges();
    }
  }

  get destinationCoords(){return this._destination;}



  depart: MapMarker;
  
  constructor( private maps : MapsAPILoader, public cd: ChangeDetectorRef, private global : SettingsService) { 
    this.depart = this.global.getSetting("coords").value
  }

  ngOnInit() {}

  initDest(){
      this.cd.detectChanges();
  }
}
